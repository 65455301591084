import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {actions as contentUpsellsActions} from "../../../../redux/reducers/contentUpsell.resource";
import {actions as contentActions} from "../../../../redux/reducers/content.resource";
import notification from "../notification";

let timeout = false;

function ContentSearch({
                           elementId,
                           items,
                           isItemsFetching,
                           fetchContentUpsells,
                           dispatchSelected,
                           isItemFetching,
                           custom_target
                       }) {
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [search, setSearch] = useState("");
    const [inpValue, setInpValue] = useState(null);

    useEffect(() => {
        if (timeout) clearTimeout(timeout);
        if (searchString && searchString.trim().length > 2) {
            timeout = setTimeout(() => setSearch(searchString), 500);
        }
    }, [searchString]);

    useEffect(() => {
        const query = {};
        query.upsell_mode = true;
        if (search && search.trim !== "") {
            query.search = search;
            fetchContentUpsells({}, {query: {...query}})
                .catch(() => {
                    notification({
                        isOpen: true, type: "error", messageText: "An error has occured. Please try again.",
                    });
                });
        }
    }, [fetchContentUpsells, search],);

    const handleSelect = (e, value) => {
        if (value) {
            dispatchSelected(value);
            setInpValue(null);
            return false;
        }
    };

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
    };

    const ListItemContent = (props) => {
        return <span style={{fontWeight: props.selected ? 'bold' : null}}><span>{props.option.name}</span><span
            style={{display: 'block', fontSize: 11, color: '#333'}}>ID: {props.option.id}</span></span>
    }

    return (<>
        <Autocomplete
            fullWidth
            freeSolo
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            id={elementId}
            open={open}
            value={inpValue}
            clearOnBlur
            onInputChange={handleSearchChange}
            onChange={handleSelect}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            renderOption={option => <ListItemContent option={option} selected={custom_target && custom_target.id === option.id}/>}
            options={Object.keys(items).map((key) => ({name: items[key], id: key}))}
            loading={focused && (isItemsFetching || isItemFetching)}
            renderInput={params => (<TextField
                {...params}
                label="Search by name or ID"
                fullWidth
                // helperText="Search by brand name or id."
                variant="outlined"
                InputProps={{
                    ...params.InputProps, endAdornment: (<>
                        {(focused && (isItemsFetching || isItemFetching)) ?
                            <CircularProgress color="inherit" size={20}
                                              style={{position: "absolute", right: "11px"}}/> : null}
                        {params.InputProps.endAdornment}
                    </>),
                }}
            />)}
        />
    </>);
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
    items: state.reducerContentUpsells.items || false,
    isItemsFetching: state.reducerContentUpsells.isFetching,
    isItemFetching: state.reducerContent.isFetchingItem,
});

export default connect(mapStateToProps, {
    ...contentUpsellsActions, ...contentActions,
},)(ContentSearch);
