import {Tooltip, withStyles} from "@material-ui/core";

const MenuTooltip = withStyles((theme) => ({
  arrow: {
    color: '#ebebeb',
  },
  tooltip: {
    backgroundColor: '#ebebeb',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: "unset",
    fontSize: 17,
  },
}))(Tooltip);

export default MenuTooltip;
