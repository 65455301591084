import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const {
               types,
               actions,
               rootReducer
             } = createResource({
  name: "recommendations",
  url: `${apiUrl}/recommendations`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: res => ({
        body: res.body ? res.body.recommendations : [],
        message: res.body.message,
        resourceName: "recommendations",
        pagination: {
          currentPageNumber: res.body.currentPage,
          totalPages: res.body.totalPages || false,
          numItemsPerPage: res.body.itemsPerPage || false,
          totalItems: res.body.totalItems || false,
        },
      }),
    },
    get: {
      transformResponse: res => ({ body: res.body.recommendations }),
    },
    convert: {
      method: "POST",
      url: `${apiUrl}/recommendations/convert/:sku`,
    },
  },
});
