import React from "react";
import image from "../../../assets/images/image_placeholder.jpg"

export default function ImagePlaceholder({ imgUrl, styles = {}, clsName = "" }) {
  return (
    <img
      className={clsName}
      src={imgUrl && imgUrl !== "" ? imgUrl : image}
      alt="product"
      style={{ width: "50px", ...styles }}
    />
  );
}
