import React, { useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ErrorPage from "../MainApp/Pages/errors/ErrorPage";

import {
	UserDashboard,
	Subscriptions,
	Upsells,
	UpsellQuest,
} from "../MainApp/Pages";
import UpsellsSettings from "../MainApp/Pages/UpsellsSettings";
import UpsellsRecommendations from "../MainApp/Pages/UpsellsRecommendations";
import SocialLogin from "../MainApp/Pages/SocialLogin";
import SocialLoginNetworks from "../MainApp/Pages/SocialLoginNetworks";
import SocialLoginSettings from "../MainApp/Pages/SocialLoginSettings";
import SocialLoginAnalytics from "../MainApp/Pages/SocialLoginAnalytics";
import PrettyEmails from "../MainApp/Pages/PrettyEmails";
import SmartAddress from "../MainApp/Pages/SmartAddress";

const Page = ( props ) => {
	const originalTitle = useRef( document.title );

	useEffect( () => {
		document.title = props.title ? originalTitle.current + " | " + props.title : document.title;
	}, [ props.title ] );
	return props.children;
};

const userRoutes = () => (
	<Switch>
		<Route exact path="/subscriptions" render={( props ) => (
			<Page title="Subscriptions">
				<Subscriptions {...props}/>
			</Page>
		)}/>
		<Route exact path="/upsells/settings" render={( props ) => (
			<Page title="OCU | Settings">
				<UpsellsSettings {...props}/>
			</Page>
		)}/>
		<Route exact path="/upsells/recommendations" render={( props ) => (
			<Page title="OCU | Recommendations">
				<UpsellsRecommendations {...props}/>
			</Page>
		)}/>
		<Route exact path="/upsells" render={( props ) => (
			<Page title="OCU">
				<Upsells {...props}/>
			</Page>
		)}/>
		<Route exact path="/upsells/:id" render={( props ) => (
			<Page title="OCU | Edit">
				<UpsellQuest {...props}/>
			</Page>
		)}/>
		<Route exact path="/" render={( props ) => (
			<Page title="Apps for growth">
				<UserDashboard {...props}/>
			</Page>
		)}/>
		<Route exact path="/social-login" render={( props ) => (
			<Page title="Social Login">
				<SocialLogin {...props}/>
			</Page>
		)}/>
		<Route exact path="/social-login/analytics" render={( props ) => (
			<Page title="Social Login | Analytics">
				<SocialLoginAnalytics {...props}/>
			</Page>
		)}/>
		<Route exact path="/social-login/networks" render={( props ) => (
			<Page title="Social Login | Networks">
				<SocialLoginNetworks {...props}/>
			</Page>
		)}/>
		<Route exact path="/social-login/settings" render={( props ) => (
			<Page title="Social Login | Settings">
				<SocialLoginSettings {...props}/>
			</Page>
		)}/>
		<Route exact path="/pretty-emails" render={( props ) => (
			<Page title="PrettyEmails">
				<PrettyEmails {...props}/>
			</Page>
		)}/>
		<Route exact path="/smart-address" render={( props ) => (
			<Page title="SmartAddress">
				<SmartAddress {...props}/>
			</Page>
		)}/>
		<Redirect from="*" to="/404"/>
	</Switch>
);

export default function DashboardRouter() {
	return (
		<Switch>
			<Route exact path="/404" component={ErrorPage}/>
			<Route exact path="*" component={userRoutes}/>
		</Switch>
	);
}
