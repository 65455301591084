import React from "react";
import {
  Button, Grid,
  Paper,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import "./index.scss";
import logo from "../../../../../../assets/images/os-logo-cropped.png";
import { makeStyles } from "@material-ui/core/styles";
import notification from "../../../notification";
import { HashLink } from "react-router-hash-link";
import { apiUrl } from "../../../../../../config/config";
import { defaultHeaders } from "redux-rest-resource";

const useStyles = makeStyles(() => ({
  itemTitle: {
    width: "100%",
    fontWeight: 400,
    color: "#002937",
    lineHeight: "1",
    fontSize: "20px",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  activateButton: {
    marginRight: 10,
  },
  itemGrid: {
    margin: "5px 0",
  },
  heading: {
    marginBottom: "20px",
  },
}));

function OtherApplicationsBlock({
  availableSubscriptions,
  subscriptions,
  internalPlugins,
  createSubscriptions,
  isCreating,
  getSubscriptions,
}) {
  const classes = useStyles();

  const create = (pid) => {
    createSubscriptions({ pid: pid })
      .then((res) => {
        if ( res.body ) {
          if ( res.body.status === "success" ) {
            notification({
              isOpen: true,
              type: "success",
              messageText: "Successfully activated",
            });
          } else {
            notification({
              isOpen: true,
              type: "error",
              messageText: "Error: " + res.body.message,
            });
          }
        } else {
          notification({
            isOpen: true,
            type: "error",
            messageText: "Unknown error.",
          });
        }

        // Update the subscriptions data.
        getSubscriptions({ nocache: true });
      })
      .catch(() => {
        notification({
          isOpen: true,
          type: "error",
          messageText: "Server error.",
        });

        // Update the subscriptions data.
        getSubscriptions({ nocache: true });
      });
  };

  const createInternal = (pid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", defaultHeaders.Authorization);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ id: pid, setupDone: false, }),
      redirect: "follow",
    };

    fetch(`${apiUrl}/internal_plugins`, requestOptions).then(res => res.json()).then(res => {
      if ( typeof res.status !== 'undefined' ) {
        if ( res.status === "success" ) {
          // Don't do the logic below if the product is not Social Login.
          if ( pid !== 'prod_KpVoGKwxadTIxl' ) {
            return;
          }

          notification({
            isOpen: true,
            type: "success",
            messageText: "Successfully activated! We'll redirect you to the app setup screen in a moment.",
          });

          window.setTimeout(() => {
            // Redirect to plugin.
            window.location.href = "/social-login"; // TODO - make dynamic.
          }, 4000);
        } else {
          notification({
            isOpen: true,
            type: "error",
            messageText: "Error: " + res.message,
          });
        }
      } else {
        notification({
          isOpen: true,
          type: "error",
          messageText: "Unknown error.",
        });
      }
    }).catch(e => {
      notification({
        isOpen: true,
        type: "error",
        messageText: "Server error: " + e,
      });
    })
  };

  const showItem = (app = {}) => {
    let canBeActivated       = true,
        activeSubscriptionId = 0,
        setupDone            = null;

    Object.keys(subscriptions)
      .forEach((key) => {
        let subscription = subscriptions[ key ];

        // Loop until we find a subscription that is active or in trial.
        if ( subscription.plan.product === app.pid && canBeActivated ) {
          switch ( subscription.status ) {
            case "active":
            case "trialing":
            case "incomplete":
            case "past_due":
            case "unpaid":
              activeSubscriptionId = subscription.id;
              canBeActivated       = false;
              break;
            default:
              break;
          }
        }
      });

    // Check if app is already activated in internal plugins.
    if ( app.is_internal ) {
      Object.keys(internalPlugins).forEach(key => {
        if ( key === app.pid ) {
          canBeActivated = false;
          setupDone      = internalPlugins[ key ].setupDone;
        }
      })
    }

    return (
      <Grid item xs={12} sm={6} md={4} xl={3} className={classes.itemGrid} key={app.pid}>
        <Paper className="application-item application-item-first-activation">
          <div className="application-item-content">
            <div style={{ display: "inline-block" }}>
              <img alt={app.name} className="app-image" src={app.icon ? app.icon : logo}/>
            </div>
            <div style={{ display: "inline-block" }}>
              <Typography component="h1" variant="h5" className={classes.itemTitle}>
                {app.name}
              </Typography>
              <p>{app.description}</p>
            </div>
          </div>
          <div className="button-block">
            {canBeActivated ? <Button
                className={classes.activateButton}
                color="primary"
                variant="contained"
                aria-label="add-application"
                disabled={isCreating}
                onClick={() => {
                  createInternal(app.pid);
                  if ( ! app.is_internal ) {
                    create(app.pid);
                  }
                }}>
                Activate
              </Button> :
              (app.is_internal && setupDone === false && ! activeSubscriptionId ? <Button
                  className={classes.activateButton}
                  color="primary"
                  variant="contained"
                  aria-label="continue-setup"
                  onClick={() => {
                    // Redirect to plugin.
                    window.location.href = "/social-login"; // TODO - make dynamic.
                  }}
                >
                  Continue Setup
                </Button> :
                <HashLink smooth to={"/subscriptions#subscription-" + activeSubscriptionId}
                  style={{ textDecoration: "none" }}>
                  <Button
                    className={classes.activateButton}
                    color="primary"
                    variant="contained"
                    aria-label="view-subscription"
                  >
                    View Subscription
                  </Button>
                </HashLink>)
            }
            <a href={app.url} target={"_blank"}
              rel={"noopener noreferrer"} style={{ textDecoration: "none" }}>
              <Button color="secondary" variant="contained"
                aria-label="add-application">
                Learn More
              </Button>
            </a>
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <div className="all-application-block-wrapper" id="otherApps">
      <Typography component="h1" variant="h4" color="secondary" className={classes.heading}>Explore
        other
        applications</Typography>
      <Grid container className="application-block">
        {Object.keys(availableSubscriptions)
          .map((key) => {
            return showItem(availableSubscriptions[ key ]);
          })}
      </Grid>
    </div>
  );
}

export default OtherApplicationsBlock;
