import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import { Input } from "@material-ui/core";
import invert from "invert-color";

export default function ColorPicker({
                                      hex,
                                      id,
                                      updateSetting
                                    }) {
  const [currentHex, setCurrentHex]     = useState(hex || "#ffffff");
  const [openChooser, setOpenChooser]   = useState(false);
  const [chooserAtTop, setChooserAtTop] = useState(false);

  useEffect(() => {
    setCurrentHex(hex);
  }, [hex]);

  const handleClick = (e) => {
    setOpenChooser(!openChooser);
    setChooserAtTop(window.innerHeight - e.target.getBoundingClientRect().top > 224);
  };

  const handleClose = () => {
    setOpenChooser(!openChooser);
  };

  const handleChange = (color) => {
    setCurrentHex(color.hex);
    updateSetting({
      id: id,
      value: color.hex
    });
  };

  return (
      <div className="color-picker-container" style={{
        position: "relative"
      }}>
        <div className="picker-input-container">
          <Input name={id} id={id}
                 value={currentHex}
                 onChange={e => updateSetting(e.target)} onClick={handleClick} readOnly={true}
                 style={{
                   background: currentHex,
                   color: invert(currentHex),
                   padding: "3px 12px",
                   borderRadius: 4,
                   border: "1px solid rgba(0, 0, 0, 0.23)",
                   width: "100%",
                   textAlign: "center",
                 }}
                 disableUnderline={true}/>
        </div>

        {openChooser && <div style={{
          position: "absolute",
          zIndex: 4,
          right: "100%",
          [ chooserAtTop ? "top" : "bottom" ]: 0,
        }}>
          <div style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }} onClick={handleClose}
               className="picker-container"/>
          <ChromePicker color={{
            hex: currentHex
          }} onChange={handleChange} disableAlpha={true}/>
        </div>}

      </div>
  );
}
