import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Paper, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { actions as recommendationsPopupActions } from "../../../../redux/reducers/recommendationsPopup.resource";
import { actions as upsellsActions } from "../../../../redux/reducers/upsell.resource";
import { actions as recommendationsActions } from "../../../../redux/reducers/recommendations.resource";
import RecommendationsTable from "./RecommendationsTable";
import { ArrowForward } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import RecommendationsCards from "./RecommendationsCards";

function RecommendationsPopup({
  recommendations,
  isRecommendationsFetching,
  fetchRecommendationsPopups: fetchRecommendations,
  open,
  sku,
  name,
  popupClose,
  convertRecommendations,
  isMobile,
  dateFrom,
  dateTo,
}) {
  const history                             = useHistory();
  const [selectedOffers, setSelectedOffers] = useState({});
  const [sortData, setSortData]             = useState({
    order: "desc",
    orderby: "revenue",
  });

  useEffect(() => {
    if ( sku ) {
      const query = {
        mainSku: sku,
        unique: true,
        limit: 99999,
      };

      if ( dateFrom ) {
        query.from = Math.round((+dateFrom) / 1000);
      }

      if ( dateTo ) {
        query.to = Math.round((+dateTo) / 1000);
      }

      if ( sortData.orderby ) {
        query.order   = sortData.order;
        query.orderby = sortData.orderby;
      }

      fetchRecommendations({}, {
        query: {
          ...query,
        }
      });
    }
  }, [fetchRecommendations, sku, dateFrom, dateTo, sortData]);

  const handleSortChange = (name) => {
    let newOrder = {
      order: "desc",
      orderby: name,
    };
    if ( sortData.orderby === name ) {
      if ( sortData.order === "asc" ) {
        newOrder.order = "desc";
      } else {
        newOrder.order   = "asc";
        newOrder.orderby = name;
      }
    }
    setSortData({
      ...newOrder
    });
  };

  /**
   * Gets the offer SKU based on the supplied ID of the main product selection.
   *
   * @param {int} id The main row ID in the list.
   *
   * @return {string|null} The offer SKU.
   */
  const getOfferSkuById = (id) => {
    let offer_sku = null;

    Object.values(recommendations.recommendations)
      .forEach((recommendation) => {
        if ( recommendation.id === id ) {
          offer_sku = recommendation.offer1;
        }
      });

    return offer_sku;
  };

  const createUpsellAction = () => {
    let offers = [];
    Object.keys(selectedOffers)
      .forEach((id) => {
        if ( selectedOffers[ id ] === true ) {
          offers.push(getOfferSkuById(parseInt(id)));
        }
      });

    convertRecommendations({ sku: sku }, {
      body: {
        offers: offers.join(","),
        title: name
      }
    })
      .then((res) => history.push(res.body.upsell ? "/upsells/" + res.body.upsell : "/upsells"));
  };

  return (<>{open &&
  <>
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0,0,0,0.5)",
      zIndex: 10999,
      width: isMobile ? "100%" : "",
    }}
      onClick={popupClose}
    />
    <Paper style={{
      position: "fixed",
      top: isMobile ? 20 : 70,
      left: isMobile ? "0" : "20%",
      bottom: isMobile ? 20 : 70,
      right: isMobile ? "0" : "20%",
      zIndex: 11000,
      padding: isMobile ? 10 : 20,
      margin: isMobile ? "0 5px 0" : "",
    }}>
      <div style={{
        position: "relative",
        width: isMobile ? "100%" : ""
      }}>
        <h1 style={{ marginTop: 0 }}>Let's create the upsell</h1>
        <p>Add the upsell title, choose the offer products, and click on Continue. You will be able
          to
          customize the upsell further later.</p>
        <TextField label="Upsell Title" variant="outlined" value={name} style={{
          width: isMobile ? "100%" : 500,
        }}/>
        <p>Choose products that you would like to offer:</p>
        <div style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "calc(100vh - 377px)",
          position: "relative",
        }}>
          {isRecommendationsFetching && <div style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}><CircularProgress/></div>}
          {isMobile ? <RecommendationsCards
            recommendations={recommendations}
            isFetching={isRecommendationsFetching}
            sortOrder={sortData}
            sortHandler={handleSortChange}
            showCheckbox={true}
            showMainProduct={false}
            scrollable={true}
            checkedProductsForwarder={setSelectedOffers}
            popupIsOpen={open}
          /> : <RecommendationsTable
            recommendations={recommendations}
            isFetching={isRecommendationsFetching}
            sortOrder={sortData}
            sortHandler={handleSortChange}
            showCheckbox={true}
            showMainProduct={false}
            scrollable={true}
            checkedProductsForwarder={setSelectedOffers}
          />}
        </div>
        {isMobile ?
          <div style={{
            display: "flex",
            marginTop: 20,
            position: "fixed",
            bottom: 40,
          }}>
            <Button
              variant={"contained"}
              color={"primary"}
              onClick={createUpsellAction}
              style={{
                marginRight: 10,
              }}>
              Continue
              <ArrowForward style={{
                marginLeft: 10,
              }}/>
            </Button>
            <Button
              variant={"contained"}
              onClick={popupClose}
            >
              Close
            </Button>
          </div> :
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={createUpsellAction}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}>
            Continue
            <ArrowForward style={{
              marginLeft: 10,
            }}/>
          </Button>
        }
      </div>
    </Paper>
  </>
  }</>);
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
  return {
    isRecommendationsFetching: state.reducerRecommendationsPopup.isFetching,
    recommendations: state.reducerRecommendationsPopup.items || false,
  };
};

export default connect(
mapStateToProps,
{
...recommendationsPopupActions,
...upsellsActions,
...recommendationsActions,
},
)(RecommendationsPopup);
