import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container, Grid, Typography,
} from "@material-ui/core";
import "whatwg-fetch";
import { makeStyles } from "@material-ui/core/styles";
import { isLocal, isStaging } from "../../../config/config";
import notification from "../Components/notification";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  activateButton: {
    marginRight: 10,
  },
}));

function SocialLogin({
  me,
}) {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 1024);
  const classes                   = useStyles();

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  const CodeBlock = () => {
    return (<xmp style={{
      background: '#797979',
      fontFamily: 'monospace',
      padding: 20,
      color: '#fff',
      width: 1200,
      borderRadius: 10,
      whiteSpace: 'pre-wrap',
      cursor: 'pointer'
    }} onClick={(e) => {
      navigator.clipboard.writeText(e.target.innerText);
      notification({
        isOpen: true,
        type: 'success',
        messageText: 'Copied to clipboard.',
      })
    }}>{`<div style="display:none" id="pds_uid">[@user:user_id@]</div>
<script>
function pd_J(m,J){const K=pd_m();return pd_J=function(R,e){R=R-0x12c;let t=K[R];return t;},pd_J(m,J);}function pd_m(){const s=['src','clearInterval','script','11840KFuBXL','csrfToken','appendChild','310XmCGWb','pd_socialLoginLoaderIntervalAttempts','querySelector','createElement','92JDPavq','59857ZCFTLz','[@user:user_id@]','423969BCCWNH','undefined','input[name=csrf_token]','3720xqRVXD','pd_socialLoginLoaderInterval','setInterval','24039fgFLYE','3841756gdAENK','9337845YNKnjZ','https://`+(isLocal||isStaging ? 'dev.' : '')+`app.outsmart.digital/social-login.js','value','body','32nForzQ','56307WBlDTH','[@config:current_page_type@]'];pd_m=function(){return s;};return pd_m();}const pd_j=pd_J;(function(m,J){const Q=pd_J,K=m();while(!![]){try{const R=parseInt(Q(0x13e))/0x1+parseInt(Q(0x13b))/0x2*(-parseInt(Q(0x12f))/0x3)+-parseInt(Q(0x145))/0x4+-parseInt(Q(0x134))/0x5*(-parseInt(Q(0x141))/0x6)+-parseInt(Q(0x13c))/0x7*(parseInt(Q(0x12e))/0x8)+-parseInt(Q(0x144))/0x9*(-parseInt(Q(0x137))/0xa)+parseInt(Q(0x146))/0xb;if(R===J)break;else K['push'](K['shift']());}catch(e){K['push'](K['shift']());}}}(pd_m,0xebcb0),window[pd_j(0x138)]=0x0,window[pd_j(0x142)]=window[pd_j(0x143)](()=>{const B=pd_j;window['pd_socialLoginLoaderIntervalAttempts']++;window[B(0x138)]>0x14&&window[B(0x132)](window['pd_socialLoginLoaderInterval']);if(typeof NETO['csrfToken']!==B(0x13f)||document[B(0x139)]('input[name=csrf_token]')&&document[B(0x139)](B(0x140))[B(0x12c)]){window[B(0x132)](window[B(0x142)]),window['pd_socialLoginParams']={'page':B(0x130),'loggedInUserId':B(0x13d),'csrfToken':document[B(0x139)](B(0x140))?document[B(0x139)](B(0x140))[B(0x12c)]:NETO[B(0x135)]};let m=document[B(0x13a)](B(0x133));m[B(0x131)]=B(0x147),document[B(0x12d)][B(0x136)](m);}},0x1f4));
</script>
`}
    </xmp>);
  }

  return (
    <Container maxWidth="xl">
      <Grid className={classes.root} item xs={12} sm={12}>
        <Typography component="h2" variant="h6" color="secondary" gutterBottom
          className={classes.title} style={{
          display: isMobile ? "inherit" : "flex",
          textAlign: "center",
        }}>
          Social Login | Get Started
        </Typography>
        <p>Please create a new Custom Script in your store's cPanel -> All Settings -> Custom Scripts with the following
          content in the "Page Footer" section.</p>
        <CodeBlock/>
        <p>Once the code is in place and the Custom Script is set to "Active", move onto Networks tab to activate and
          configure desired social networks.</p>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  me: state.reducerMe.item || false,
});

export default connect(
  mapStateToProps
)(SocialLogin);
