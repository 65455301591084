import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPage from "../Components/UserDashboardCompponents/MainPage";
import { actions as settingsActions } from "../../../redux/reducers/setting.resource";
import notification from "../Components/notification";
import { loadCachedRequestData, saveCachedRequestData } from "../helpers/cache";

function UserDashboard({
  tmpSettings,
  getSetting
}) {
  const [settings, setSettings] = useState(false);

  useEffect(() => {
    if ( !settings ) {
      getSetting()
        .then(res => {
          if ( !res.body ) {
            notification({
              isOpen: true,
              type: "error",
              messageText: res.message || "Error.",
            });
          } else {
            saveCachedRequestData(res.body, "settings");
            setSettings(res.body);
          }
        });
      setSettings(loadCachedRequestData("settings", {}));
    }
  }, [getSetting, settings]);

  if ( !settings ) return null;

  return (
    <MainPage/>
  );
}

const mapStateToProps = (state) => ({
  tmpSettings: state.reducerSettings.item,
});

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
  },
)(UserDashboard);
