import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "productUpsell",
  url: `${apiUrl}/products`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: (res) => ({
        body: res.body ? res.body.products : [],
      }),
    },
  },
});
