import { defaultHeaders } from "redux-rest-resource";

export const getAuthData = () => {
  const urlParams = new URLSearchParams( window.location.search );
  if ( urlParams.get( 's' ) ) {
    localStorage.setItem( 'authData', JSON.stringify( { 'sid': urlParams.get( 's' ) } ) );
    sessionStorage.setItem( 'authData', JSON.stringify( { 'sid': urlParams.get( 's' ) } ) );
  }

  const localS = JSON.parse(localStorage.getItem("authData") || "{}");
  const sessS = JSON.parse(sessionStorage.getItem("authData") || "{}");
  if (localS.sid) return localS;
  if (sessS.sid) return sessS;
  return {};
};

export const saveAuthData = (sid, session = false) => {
  Object.assign(defaultHeaders, { Authorization: `Bearer ${sid}` });
  if (session) {
    return sessionStorage.setItem(
      "authData",
      JSON.stringify({ sid })
    );
  } else {
    return localStorage.setItem(
      "authData",
      JSON.stringify({ sid })
    );
  }
};

export const clearAuthData = () => {
  localStorage.removeItem("authData");
  sessionStorage.removeItem("authData");
  delete defaultHeaders.Authorization;
  document.location.href = "/signin";
};

export const hasAuthData = () =>
  !!getAuthData().sid;

export const reSetAuthToken = () => {
  Object.assign(defaultHeaders, { Authorization: `Bearer ${getAuthData().sid}` });
};
