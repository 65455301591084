import React from "react";
import OfferItem from "./OfferItem";

export default function OffersList({ items, removeHander, handleChangeOffer, store_url, sortHandler, product_target, settings, setCalendarOpen }) {
  const getKey = (key, items) => {
    if (items.length === 1) return "one";
    if (key === 0) return "first";
    if (key === items.length - 1) return "last";
    return false;
  };
  const dispItems = items.sort((a, b) => a.priority > b.priority ? 1 : -1);
  return (
    <div className="offers-list">
      {dispItems.map((item, key) => {
        const k = getKey(key, dispItems);
        return (
          <div className="offer-wrapper" key={`${item.sku}_${item.name}`}>
            <OfferItem
              product_target_sku={product_target ? product_target.sku : false}
              item={item}
              removeHander={removeHander}
              handleChangeOffer={handleChangeOffer}
              store_url={store_url}
              sortHandler={sortHandler}
              k={k}
              settings={settings}
              setCalendarOpen={setCalendarOpen}
            />
          </div>
        )})}
    </div>
  )
}
