import React from "react";
import { Switch, Route } from "react-router-dom";
import ErrorPage from "../MainApp/Pages/errors/ErrorPage";
import {
  SignIn,
} from "../MainApp/Pages";

export default function SiteRouter() {
  return (
    <Switch>
      <Route exact path="/signin"><SignIn /></Route>
      <Route path="*"><ErrorPage /></Route>
    </Switch>
  );
}
