/**
 * Loads cached network request data if it exists and is not stale.
 *
 * @param {string} name The cache key name.
 * @param {*} defaultValue The value to return if the cache does not exist or is stale. Defaults to
 *   null.
 *
 * @returns {null|*} The cached data or default return value.
 */
export function loadCachedRequestData(name, defaultValue = null) {
  let data       = localStorage.getItem("ocu_cached_" + name);
  let time       = +new Date();
  let expiration = localStorage.getItem("ocu_cached_" + name + "_expiration");

  // Check if expired.
  if ( expiration && time > parseInt(expiration) ) {
    localStorage.removeItem("ocu_cached_" + name);
    return defaultValue;
  }

  return data ? JSON.parse(data) : defaultValue;
}

/**
 * Saves the data to the cache with the defined key.
 *
 * @param {*} data The data to save.
 * @param {string} name The name to use. Used for retrieval.
 * @param {number} maxAge The relative maximum age from the creation moment, in seconds. Defaults
 *   to 24h.
 */
export function saveCachedRequestData(data, name, maxAge = 86400) {
  localStorage.setItem("ocu_cached_" + name, JSON.stringify(data));

  let time = +new Date();
  localStorage.setItem("ocu_cached_" + name + "_expiration", (time + maxAge).toString());
}
