import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Truncate from "react-truncate";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import clsx from "clsx";
import { ArrowForwardRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0",
  },
  upsellPaper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
    cursor: "pointer",
  },
  upsellContainer: {
    margin: "0",
    display: "flex",
    position: "relative",
    width: "100%",
  },
  upsellMainStats: {
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #f1f1f1",
    padding: 10,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "25%",
    minWidth: "85px",
  },
  upsellSecondaryStatsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 11,
  },
  upsellSecondaryStat: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "33.333%",
  },
  upsellMainContent: {
    padding: 15,
    width: "75%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  statsLabel: {
    fontSize: 10,
    color: "#8e8e8e",
  },
  statsValue: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#737373",
  },
  statsValueMain: {
    fontSize: 15,
    color: "#535353",
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  darkerColor: {
    color: "#535353",
  },
  ID: {
    fontSize: 9,
    color: "#ccc",
    marginTop: -5,
  },
  title: {
    fontSize: 14,
    marginRight: 15,
  },
  arrow: {
    position: "absolute",
    right: 5,
    top: 21,
    color: theme.palette.primary.main,
  }
}));
export default function UpsellsListMobile({
  upsells,
  isFetching,
  pagination,
  paginationHandler,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (<div className={classes.root}>
    {isFetching ? <CircularProgress/> :
      upsells && upsells.map((upsell) => {
        return (<Paper key={upsell.id}
          onClick={() => history.push(`/upsells/${upsell.id}`)}
          className={classes.upsellPaper}>
          <div className={classes.upsellContainer}>
            <div className={classes.upsellMainStats}>
              <div className={classes.statsLabel}>Conversion</div>
              <div
                className={clsx(classes.statsValue, classes.statsValueMain, upsell.conversion_rate > 0 ? classes.colorPrimary : null)}>{upsell.conversion_rate}%
              </div>
            </div>
            <div className={classes.upsellMainContent}>
              <div>
                <div className={classes.ID}>[ID:
                  <span style={{ color: "#9d9b9b" }}> {upsell.id}</span>]
                </div>
                <div className={classes.title}><Truncate>{upsell.name}</Truncate></div>
                <div className={classes.arrow}><ArrowForwardRounded/></div>
              </div>
              <div className={classes.upsellSecondaryStatsContainer}>
                <div className={classes.upsellSecondaryStat}>
                  <div className={classes.statsLabel}>Revenue</div>
                  <div
                    className={clsx(classes.statsValue, classes.darkerColor)}>${upsell.revenue}</div>
                </div>
                <div className={classes.upsellSecondaryStat}>
                  <div className={classes.statsLabel}>Add To Carts</div>
                  <div className={classes.statsValue}>{upsell.add_to_carts}</div>
                </div>
                <div className={classes.upsellSecondaryStat}>
                  <div className={classes.statsLabel}>Impressions</div>
                  <div className={classes.statsValue}>{upsell.impressions}</div>
                </div>
              </div>
            </div>
          </div>
        </Paper>);
      })}
    <div className={classes.pg}>
      {pagination && pagination.totalPages > 1
      && <Pagination
        count={parseInt(pagination.totalPages)}
        page={parseInt(pagination.currentPageNumber)}
        onChange={paginationHandler}
      />}
    </div>
  </div>);
}
