import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
	title: {
		// marginBottom: theme.spacing(2),
		display: "flex",
		flexFlow: "row",
		justifyContent: "space-between",
		alignItems: "center",
		fontWeight: "600",
		padding: theme.spacing(2, 4, 0),
		paddingLeft: 0,
	},
	heading1: {
		fontSize: 20,
		marginBottom: "10px",
		marginTop: "10px",
	},
	modal: {
		border: "none !important",
		boxShadow: "unset !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: "30px 0",
	},
	paper: {
		position: "relative",
		width: "100%",
		maxWidth: "600px",
		backgroundColor: theme.palette.background.paper,
		border: "none !important",
		outline: "none !important",
		padding: "0 24px 0 24px",
		marginTop: "auto",
		marginBottom: "auto",
		height: "100%",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[ 500 ],
	},
	text: {
		fontSize: 15,
	},
	formControl: {
		marginBottom: theme.spacing(3),
		width: "100%",
	},
}));

function DisplayForPopup({
	isOpened,
	isMobile,
	setIsOpened,
}) {
	const [option, setOption] = useState(false);
	const [option2, setOption2] = useState(false);
	const classes = useStyles();

	const handleOption = (event, newOption) => {
		setOption(newOption);
	};

	const handleOption2 = (event, newOption) => {
		setOption2(newOption);
	};

	return (
		<Modal
			className={clsx(classes.modal, "products-modal")}
			disableEnforceFocus
			open={isOpened}
			onClose={setIsOpened}
		>
			<Paper className={clsx(classes.paper, "products-popup")}>
				<Typography component="h2" variant="h6" color="primary" gutterBottom
					className={classes.title}>
					Display For
					<IconButton aria-label="close" className={classes.closeButton} onClick={setIsOpened}>
						<CloseIcon/>
					</IconButton>
				</Typography>
				<Typography className={classes.text}>Choose one or more rules for your upsell</Typography>

				<Typography className={classes.heading1}>Display options</Typography>
				<Typography className={classes.text}/>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel id="select-label">Display For</InputLabel>
					<Select label="Display For" id="select" labelId="select-label" name="display_for">
						<MenuItem value="all">All Products</MenuItem>
						<MenuItem value="product_target">Specific Product</MenuItem>
						<MenuItem value="category_target">Specific Category</MenuItem>
						<MenuItem value="brand_target">Specific Brand</MenuItem>
						<MenuItem value="custom_target">Specific Custom Content</MenuItem>
					</Select>
				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<ToggleButtonGroup
						value={option}
						exclusive
						onChange={handleOption}
						aria-label="text alignment"
					>
						<ToggleButton value="and" aria-label="AND">
							AND
						</ToggleButton>
						<ToggleButton value="or" aria-label="OR">
							OR
						</ToggleButton>
					</ToggleButtonGroup>
				</FormControl>

				{option &&
				<>
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="select-label">Display For</InputLabel>
						<Select label="Display For" id="select" labelId="select-label" name="display_for">
							<MenuItem value="all">All Products</MenuItem>
							<MenuItem value="product_target">Specific Product</MenuItem>
							<MenuItem value="category_target">Specific Category</MenuItem>
							<MenuItem value="brand_target">Specific Brand</MenuItem>
							<MenuItem value="custom_target">Specific Custom Content</MenuItem>
						</Select>
					</FormControl>

					<FormControl variant="outlined" className={classes.formControl}>
						<ToggleButtonGroup
							value={option2}
							exclusive
							onChange={handleOption2}
							aria-label="text alignment"
						>
							<ToggleButton value="and" aria-label="AND">
								AND
							</ToggleButton>
							<ToggleButton value="or" aria-label="OR">
								OR
							</ToggleButton>
						</ToggleButtonGroup>
					</FormControl>
				</>
				}
			</Paper>
		</Modal>
	);
}

export default DisplayForPopup;
