import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";

// Array.reduce replication
function reduce(val, callback /*, initialValue*/) {
  if ( val === null ) {
    throw new TypeError("Array.prototype.reduce called on null or undefined");
  }
  if ( typeof callback !== "function" ) {
    throw new TypeError(callback + " is not a function");
  }
  var o   = Object(val);
  var len = o.length >>> 0;
  var k   = 0;
  var value;
  if ( arguments.length >= 2 ) {
    value = arguments[ 1 ];
  } else {
    while ( k < len && !(k in o) ) {
      k++;
    }
    if ( k >= len ) {
      throw new TypeError("Reduce of empty array " +
        "with no initial value");
    }
    value = o[ k++ ];
  }
  while ( k < len ) {
    if ( k in o ) {
      value = callback(value, o[ k ], k, o);
    }
    k++;
  }
  return value;
}

// Object.fromEntries polyfill.
Object.fromEntries = Object.fromEntries || function (arr) {
  return reduce(arr, function (acc, curr) {
    acc[ curr[ 0 ] ] = curr[ 1 ];
    return acc;
  }, {});
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline/>
    <App/>
  </ThemeProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
