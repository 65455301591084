import { createResource, defaultHeaders } from "redux-rest-resource";
import { apiUrl } from "../../config/config";
import { clearAuthData } from '../authStorage';

export const { types, actions, rootReducer } = createResource({
  name: 'user',
  url: `${apiUrl}/users`,
  actions: {
    login: {
      pluralName: "login",
      method: 'POST',
      url: `${apiUrl}/session`,
      transformResponse: (res, ...rest) => ({
        res,
        body: res.body
      }),
      reduce: (state) => {
        return {
          ...state,
          isAuth: true,
        }}
    },

    logout: {
      method: 'DELETE',
      url: `${apiUrl}/session`,
      reduce: (state) => {
        clearAuthData();
        Object.assign(defaultHeaders, {
          Authorization: null
        });
        return {
          ...state,
          item: null,
          isAuth: null,
        }}
    }
  }
});
