import React, { useState } from "react";
import clsx from "clsx";
import "./statistic-block.scss";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover/Popover";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  setButton: {
    padding: "0px",
    marginRight: "12px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(3),
  },
}));

function StatisticBlock({
  clsName,
  statData,
  isMobile,
}) {
  const classes                 = useStyles();
  const [open, setOpen]         = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = () => {
    setAnchorEl(document.getElementById("helper-point"));
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <div className={clsx("statistic-wrapper", clsName, open && "show-helper", isMobile && "is-mobile")}>
      <div className="statistic-item">
        <div className="statistic-header-wrapper">
          <NumberFormat prefix={"$"} displayType={"text"} thousandSeparator=","
            value={statData ? statData.revenue : "0"} renderText={(val) => <h1>{val}</h1>}/>
        </div>
        <p>Revenue</p>
      </div>
      <div className="statistic-item">
        <div className="statistic-header-wrapper">
          <NumberFormat displayType={"text"} thousandSeparator=","
            value={statData ? statData.add_to_carts : "0"} renderText={(val) => <h1>{val}</h1>}/>
        </div>
        <p>Add to Carts</p>
      </div>
      <div className="statistic-item">
        <div className="statistic-header-wrapper">
          <NumberFormat displayType={"text"} thousandSeparator=","
            value={statData ? statData.impressions : "0"} renderText={(val) => <h1>{val}</h1>}/>
        </div>
        <p>Impressions</p>
      </div>
      <div className="statistic-item">
        <div className="statistic-header-wrapper">
          <NumberFormat displayType={"text"} thousandSeparator=","
            value={statData ? parseFloat(statData.conversion_rate).toFixed(2) : "0"}
            renderText={(val) => <h1>{val}%</h1>}/>
          {/*<span>LIVE</span>*/}
        </div>
        <p>Conversion Rate</p>
      </div>
      <div
        id="helper-point"
        className="helper-point"
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
      />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>Your upsell performance in the last 30 days.</Typography>
      </Popover>
      <IconButton
        className={clsx(classes.setButton, "helper")}
        aria-label="settings"
        onMouseEnter={handlePopoverOpen}
        onClick={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <HelpOutlineIcon fontSize="small"/>
      </IconButton>
    </div>
  );
}

export default StatisticBlock;
