import React, {useRef, useState} from "react";
import {
    Checkbox, Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton, InputLabel, MenuItem, OutlinedInput,
    Radio,
    RadioGroup, Select,
    Typography
} from "@material-ui/core";
import LightTooltip from "../LightTooltip";
import clsx from "clsx";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import TextField from "@material-ui/core/TextField/TextField";
import {makeStyles} from "@material-ui/core/styles";
import MenuList from "@material-ui/core/MenuList/MenuList";
import ImagePlaceholder from "../ImagePlaceholder";
import Flatpickr from "react-flatpickr";
// noinspection NpmUsedModulesInstalled
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import "flatpickr/dist/themes/light.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 17,
    marginBottom: "5px",
  },
  label: {
    fontSize: 15,
  },
  helpBlock: {
    display: "flex",
    alignItems: "center",
    zIndex: 99,
  },
  noPadding: {
    padding: "5px",
  },
  formControl: {
    marginBottom: theme.spacing(3),
    width: "100%",
    marginTop: 20,
  },
  outlinedInputSizeSmall: {
      '& > input': {
          padding: '10.5px 14px',
      }
  }
}));

const LabelWithHelp = ({ title, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.helpBlock}>
      <Typography className={classes.label}>{label}</Typography>
      <LightTooltip title={title}>
        <HelpOutlineIcon fontSize="small" style={{ opacity: "0.5", marginLeft: "5px" }} />
      </LightTooltip>
    </div>
  )
};

export default function OffersItem({
   item,
   removeHander,
   handleChangeOffer,
   store_url,
   sortHandler,
   k,
   product_target_sku,
   setCalendarOpen,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className="offers-item">
        <ImagePlaceholder clsName="offer-image" imgUrl={item.image} styles={{ width: "60px", marginRight: "10px" }} />
        <Typography className={classes.header}>
          {item.name}
          {product_target_sku === item.sku ? <span className="upsell-prod">Upsell product</span> : null}
          <br/>
          <Typography variant="overline" display="block" style={{opacity: .7}}>SKU: {item.sku}</Typography>
        </Typography>
        <div className="offer-actions">
          <IconButton ref={anchorRef} className={clsx(classes.noPadding, "menu-button")} onClick={handleToggle}>
            <MoreVertIcon style={{ cursor: "pointer" }} htmlColor="#002937" />
          </IconButton>
          <Popper open={open} anchorEl={anchorRef.current} role={undefined} style={{ zIndex: 9 }} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={false} id="menu-list-grow">
                      {k !== "first" && k !== "one" && (
                        <MenuItem onClick={(e) => { handleClose(e); sortHandler(item, -1);}}>Move Up</MenuItem>
                      )}
                      {k !== "first" && k !== "one" && (
                        <Divider />
                      )}
                      <MenuItem onClick={(e) => { handleClose(e); removeHander(item.sku);}}>Delete</MenuItem>
                      {k !== "last" && k !== "one" && (
                        <Divider />
                      )}
                      {k !== "last" && k !== "one" && (
                        <MenuItem onClick={(e) => { handleClose(e); sortHandler(item, 1);}}>Move Down</MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <div className="offer-settings">
        <FormControl component="fieldset">
          <FormLabel>Display as:</FormLabel>
          <RadioGroup
            aria-label="offer_intent"
            name="offer_intent"
            value={item.offer_intent || ""}
            onChange={(e) => handleChangeOffer(item.sku, { ...item, offer_intent: e.target.value })}
          >
            <div className="item-with-help">
              <FormControlLabel value="upsell" control={<Radio style={{ padding: "0 9px 0 9px" }} />} label="Upsell" />
              <LightTooltip title="This is a more premium product to the one added to cart">
                <HelpOutlineIcon fontSize="small" style={{ opacity: "0.5" }} />
              </LightTooltip>
            </div>
            <div className="item-with-help">
              <FormControlLabel value="cross_sell" control={<Radio style={{ padding: "0 9px 0 9px" }} />} label="Cross sell" />
              <LightTooltip title="This is an accompanying product that relates or compliments the product added to cart">
                <HelpOutlineIcon fontSize="small" style={{ opacity: "0.5" }} />
              </LightTooltip>
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      <FormControl component="fieldset">
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          fullWidth
          inputProps={{
              maxLength: 4096
          }}
          value={item.description || ""}
          onChange={(e) => handleChangeOffer(item.sku, { ...item, description: e.target.value })}
          // id="description"
          label="Description"
          name="description"
          helperText={<div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div>Text to show below the offer. Optional.</div>
              <div>{item.description ? item.description.length : 0}/4096</div>
          </div>}
          autoComplete="name"
        />
      </FormControl>
      {item.offer_intent && item.offer_intent === "upsell" &&
        <div className="addon-settings">
          <FormControlLabel
            align="left"
            control={
              <Checkbox
                checked={item.match_parent_quantity || false}
                onChange={(e) => handleChangeOffer(item.sku, { ...item,  [e.target.name]: !item[e.target.name] })}
                name="match_parent_quantity"
                color="primary"
              />
            }
            label={<LabelWithHelp title={"Match parent product quantity"} label={"Match parent product quantity"} />}
          />
        </div>}
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="select-label">Show quantity dropdown</InputLabel>
        <Select label="Show quantity dropdown" id="select" labelId="select-label" name="show_quantity_dropdown"
          value={item.show_quantity_dropdown}
          onChange={(e) => handleChangeOffer(item.sku, { ...item,  [e.target.name]: e.target.value })}>
          <MenuItem value={1}>Enabled</MenuItem>
          <MenuItem value={2}>Default</MenuItem>
          <MenuItem value={0}>Disabled</MenuItem>
        </Select>
      </FormControl>
      <FormControlLabel
        align="left"
        control={
          <Checkbox
            checked={item.apply_discount || false}
            onChange={(e) => handleChangeOffer(item.sku, { ...item,  [e.target.name]: !item[e.target.name] })}
            name="apply_discount"
            color="primary"
          />
        }
        label={"Apply discount"}
      />
      {item.apply_discount &&
      <>
        <div className="discount-wrapper">
          <TextField
            className="discount-amount"
            size="small"
            variant="outlined"
            margin="normal"
            required
            error={item.discount_type === 'percent' && item.discount_amount && (parseFloat(item.discount_amount) < 0 || parseFloat(item.discount_amount) > 100)}
            value={item.discount_amount || ""}
            onChange={(e) => handleChangeOffer(item.sku, { ...item, discount_amount: e.target.value.replace(",", ".") })}
            id="discount_amount"
            label="Amount"
            name="discount_amount"
            autoComplete="name"
            type="number"
            max={item.discount_type === 'percent' ? 100 : null}
            min={item.discount_type === 'percent' ? 0 : null}
            helperText={item.discount_type === 'percent' && item.discount_amount && (parseFloat(item.discount_amount) < 0 || parseFloat(item.discount_amount) > 100) ? 'The value must be between 0 and 100%' : 'Discount ' + (item.discount_type === 'percent' ? 'percentage' : 'amount')}
          />
          <FormControl size="small" variant="outlined">
            <Select
              size="small"
              className="discount-type"
              id="select"
              name="discount_type"
              value={item.discount_type}
              onChange={(e) => handleChangeOffer(item.sku, { ...item, discount_type: e.target.value })}
            >
              <MenuItem value="percent">%</MenuItem>
              <MenuItem value="amount">$</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          required
          value={item.discount_code || ""}
          onChange={(e) => handleChangeOffer(item.sku, { ...item, discount_code: e.target.value })}
          id="discount_code"
          label="Discount code"
          name="discount_code"
          autoComplete="name"
          helperText={<span>Please make sure it exists in your <a target="_blank" rel="noopener noreferrer" href={`${store_url}/_cpanel/discounts-and-coupons`}>Discounts</a> page.</span>}
        />
        <Flatpickr
            value={item.discount_start ? parseInt(item.discount_start.toString() + '000') : null}
            options={{dateFormat: 'd/m/Y G:i K', enableTime: true, plugins: [confirmDatePlugin({})], disableMobile: true}}
            onOpen={()=>{
                setCalendarOpen(true);
            }}
            onClose={()=>{
                setCalendarOpen(false);
            }}
            onChange={([date]) => {
                handleChangeOffer(item.sku, { ...item, discount_start: Math.floor(date.valueOf() / 1000) })
            }}
            render={
                ({defaultValue, value, ...props}, ref) => {
                    let dateFormatted = '';
                    if (value !== null) {
                        // The value of "value" is either Date object or the Unix timestamp we processed above in onChange. We'll convert it into Date always. In some cases it can be in ms, sometimes s, so we cover both by checking the year.
                        let date = value instanceof Date ? value : (new Date(parseInt(value.toString() + '000')).getFullYear() > 2500 ? new Date(parseInt(value)) : new Date(parseInt(value.toString() + '000')));

                        // Outputs a string such as "27/09/2023 09:00 AM".
                        dateFormatted = value ? new Intl.DateTimeFormat('en-AU', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: 'numeric',
                            hour12: true,
                        }).format(date).replace(',', '').toUpperCase() : '';
                    }

                    return <FormControl style={{ marginBottom: 15, marginTop: 20 }}>
                        <InputLabel htmlFor="discount_start" style={{ top: -13, left: 15 }}>Discount Start</InputLabel>
                        <OutlinedInput
                            value={dateFormatted}
                            id="discount_start"
                            label="Discount Start"
                            name="discount_start"
                            inputRef={ref}
                            className={classes.outlinedInputSizeSmall}
                            endAdornment={dateFormatted ? <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear field"
                                    onClick={() => {
                                        handleChangeOffer(item.sku, { ...item, discount_start: 0 })
                                    }}
                                    edge="end"
                                    style={{
                                        marginRight: 1,
                                    }}
                                >
                                    <DeleteIcon style={{ cursor: "pointer" }} htmlColor="#002937"/>
                                </IconButton>
                            </InputAdornment> : null
                            }
                        />
                        <p className={'MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-marginDense'}>
                            <span>The date from which the discount is valid from — make sure it matches the data in Neto.</span>
                        </p>
                    </FormControl>
                }
            }
        />
        <Flatpickr
          value={item.discount_end ? parseInt(item.discount_end.toString() + '000') : null}
          options={{dateFormat: 'd/m/Y G:i K', enableTime: true, plugins: [confirmDatePlugin({})], disableMobile: true}}
          onOpen={()=>{
              setCalendarOpen(true);
          }}
          onClose={()=>{
              setCalendarOpen(false);
          }}
          onChange={([date]) => {
              handleChangeOffer(item.sku, { ...item, discount_end: Math.floor(date.valueOf() / 1000) })
          }}
          render={
              ({defaultValue, value, ...props}, ref) => {
                  let dateFormatted = '';
                  if (value !== null) {
                      // The value of "value" is either Date object or the Unix timestamp we processed above in onChange. We'll convert it into Date always. In some cases it can be in ms, sometimes s, so we cover both by checking the year.
                      let date = value instanceof Date ? value : (new Date(parseInt(value.toString() + '000')).getFullYear() > 2500 ? new Date(parseInt(value)) : new Date(parseInt(value.toString() + '000')));

                      // Outputs a string such as "27/09/2023 09:00 AM".
                      dateFormatted = new Intl.DateTimeFormat('en-AU', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: 'numeric',
                          hour12: true,
                      }).format(date).replace(',', '').toUpperCase();
                  }

                  return <FormControl style={{ marginBottom: 15, marginTop: 10 }}>
                      <InputLabel htmlFor="discount_end" style={{ top: -13, left: 15 }}>Discount End</InputLabel>
                      <OutlinedInput
                          value={dateFormatted}
                          id="discount_end"
                          label="Discount End"
                          name="discount_end"
                          inputRef={ref}
                          className={classes.outlinedInputSizeSmall}
                          endAdornment={dateFormatted ? <InputAdornment position="end">
                              <IconButton
                                  aria-label="clear field"
                                  onClick={() => {
                                      handleChangeOffer(item.sku, { ...item, discount_end: 0 })
                                  }}
                                  edge="end"
                                  style={{
                                      marginRight: 1,
                                  }}
                              >
                                  <DeleteIcon style={{ cursor: "pointer" }} htmlColor="#002937"/>
                              </IconButton>
                          </InputAdornment> : null
                          }
                      />
                      <p className={'MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-marginDense'}>
                          <span>The date until which the discount is valid to — make sure it matches the data in Neto.</span>
                      </p>
                  </FormControl>
              }
          }
        />
      </>
      }
    </>
  )
}
