import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource( {
	name: "internalPlugin",
	url: `${apiUrl}/internal_plugins`,

	actions: {
		fetch: {
			transformResponse( res ) {
				return typeof res.body.plugins !== "undefined" ? ( { ...res, body: res.body.plugins } ) : res;
			},
		},
		create: {
			method: "POST", // TODO
		},
		get: {
			method: "GET", // TODO
			url: `${apiUrl}/internal_plugins/:id`,
		},
		update: {
			method: "PUT", // TODO
			url: `${apiUrl}/internal_plugins/:id`,
		},
		delete: {
			method: "DELETE", // TODO
			url: `${apiUrl}/internal_plugins/:id`,
		}
	},
} );
