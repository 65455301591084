import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "brandUpsell",
  url: `${apiUrl}/brands`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: (res) => ({
        body: res.body ? res.body.brands : [],
      }),
    },
  },
});
