const initialState = {};
const totalResults = (state = initialState, action) => {
  if (action.status === "resolved" && action.payload && action.payload.stats) {
    return Object.assign({}, state, {
      [action.payload.resourceName]: action.payload.stats,
    });
  }
return state;
};

export default totalResults;
