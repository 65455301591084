import React from "react";
import { Button, Container, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import notification from "../../Components/notification";
import clsx from "clsx";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "stretch",
    marginTop: "50vh",
    transform: "translateY(calc( -50vh / 2 ))",
  },
  title: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  paper: {
    maxWidth: "700px",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
    padding: theme.spacing(5, 5),
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  codeBlockWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
    cursor: "pointer",
    "&:hover .copy-button": {
      opacity: "1",
    }
  },
  copyButton: {
    transition: "0.3s",
    opacity: "0.5",
    right: "5px",
    top: "5px",
    position: "absolute",
  },
  codeBlock: {
    borderRadius: "5px",
    padding: "10px",
    width: "100%",
    backgroundColor: "black",
    color: "white",
  },
  codeBlockText: {
    paddingRight: "30px",
    paddingLeft: "10px",
    wordBreak: "break-all",
  }
}));

function ErrorPage({ message }) {
  const classes = useStyles();
  const history = useHistory();

  /**
   * Copies the element text to the clipboard.
   */
  const copyToClip = (e) => {
    e.stopPropagation();

    // Create the temporary input element.
    const tmpElement = document.createElement("input");
    tmpElement.setAttribute("type", "text");
    tmpElement.setAttribute("id", "tmpInput");
    tmpElement.setAttribute("value", document.getElementById("text-div").innerText);

    // Append the temporary input element to the HTML.
    const root = document.getElementById("root");
    root.appendChild(tmpElement);
    tmpElement.select();

    // Copy from the temporary element.
    document.execCommand("copy");

    // Remove the temporary element.
    root.removeChild(tmpElement);

    // Send a notification to the user.
    notification({
      isOpen: true,
      type: "success",
      messageText: "Copied to the clipboard!",
    });
  };

  const isInactiveAddon = message && message === "Addon is not active.";

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h2" variant="h4" color="primary" gutterBottom
          className={classes.title}>
          {isInactiveAddon ? "The addon is not active" : "Oops, something went wrong"}
        </Typography>
        <Typography component="h4"
          variant="h6">{isInactiveAddon ? "" : "Please try reloading the page."}</Typography>

        <p style={{
          textAlign: "center",
          paddingBottom: "16px"
        }}>
          {isInactiveAddon ? <>
            Please go to the <Link style={{ cursor: "pointer" }}
            onClick={() => history.push("/subscriptions")}>Subscriptions</Link> page to verify your
            subscription,<br/>
            or activate the addon from the <Link style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}>Dashboard</Link> if it's not already active.
            <Button
              style={{
                display: "block",
                margin: "30px auto -25px"
              }}
              color="secondary"
              variant="contained"
              aria-label="view-subscriptions"
              onClick={() => history.push("/subscriptions")}
            >
              View Subscriptions
            </Button>
          </> : <>
            If the issue persists, reach out to our
            team{message ? " and send them the error message below." : "."}
          </>}
        </p>
        {!isInactiveAddon && message &&
        <div className={classes.codeBlockWrapper} onClick={copyToClip}>
          <div className={classes.codeBlock} id="text-div">
            <code>
              <p className={classes.codeBlockText}>
                {message}
              </p>
            </code>
          </div>
          <IconButton aria-label="copy" className={clsx(classes.copyButton, "copy-button")}
            onClick={copyToClip}>
            <FileCopyIcon htmlColor="#ffffff"/>
          </IconButton>
        </div>}
      </Paper>
    </Container>
  );
}

export default ErrorPage;
