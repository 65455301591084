import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "brand",
  url: `${apiUrl}/brands`,
  actions: {
    fetch: {
      transformResponse: (res) => ({
        body: res.body ? res.body.brands : [],
        resourceName: "brands",
        pagination: {
          currentPageNumber: res.body.currentPage,
          totalPages: res.body.totalPages || false,
          numItemsPerPage: res.body.itemsPerPage || false,
          brandsCount: res.body.brandsCount || false,
        },
      }),
    },
  },
});
