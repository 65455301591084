import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "subscription",
  url: `${apiUrl}/subscriptions/:id`,

  actions: {
    get: {
      transformResponse: res => ({
        body: res.body,
        message: res.body.message ? res.body.message : null,
      }),
    },
    delete: {
      method: "DELETE",
    }
  },
});
