const loadEncharge = (callback) => {
  if ( !window.EncTracking || !window.EncTracking.started ) {
    window.EncTracking = {
      queue: window.EncTracking && window.EncTracking.queue ? window.EncTracking.queue : [],
      track: function (t) {
        this.queue.push({
          type: "track",
          props: t
        });
      },
      identify: function (t) {
        this.queue.push({
          type: "identify",
          props: t
        });
      },
      started: !0
    };
    var t              = window.EncTracking;
    t.writeKey         = "eAv7vyUdUYD3QBGxkpiBWUWyp";
    t.hasOptedIn       = true;
    t.shouldGetConsent = true;
    t.hasOptedIn && (t.shouldGetConsent = !1);
    t.optIn  = function () {
      t.hasOptedIn = !0;
      t && t.init();
    };
    t.optOut = function () {
      t.hasOptedIn = !1;
      t && t.setOptOut && t.setOptOut(!0);
    };
    var n    = function (t) {
      var n    = document.createElement("script");
      n.type   = "text/javascript";
      n.async  = void 0 === t || t;
      n.src    = "https://resources-app.encharge.io/encharge-tracking.min.js";
      n.onload = () => {
        callback();
      };
      document.getElementsByTagName("head")[ 0 ].appendChild(n);
    };
    "complete" === document.readyState ? n() : window.attachEvent ? window.attachEvent("onload", n) : window.addEventListener("load", n, !1);
  }
};

export default loadEncharge;
