import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { actions as brandUpsellsActions } from "../../../../redux/reducers/brandUpsell.resource";
import { actions as brandsActions } from "../../../../redux/reducers/brand.resource";
import notification from "../notification";

let timeout = false;

function BrandSearch({ elementId, brands, isBrandsFetching, fetchBrandUpsells, dispatchSelected, getBrand, isBrandFetching, brand_target }) {
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [search, setSearch] = useState("");
  const [inpValue, setInpValue] = useState(null);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (searchString && searchString.trim().length > 2) {
      timeout = setTimeout(() => setSearch(searchString), 500);
    }
  }, [searchString]);

  useEffect(
    () => {
      const query = {};
      query.upsell_mode = true;
      if (search && search.trim !== "") {
        query.search = search;
        fetchBrandUpsells({}, {query: {...query}})
          .catch(() => {
            notification({
              isOpen: true,
              type: "error",
              messageText: "Brands fetch error",
            });
          });
      }
    }, [fetchBrandUpsells, search],
  );

  const handleSelect = (e, value) => {
    if (value) {
      dispatchSelected(value);
      setInpValue(null);
      return false;
    }
  };

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        freeSolo
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        id={elementId}
        open={open}
        value={inpValue}
        clearOnBlur
        onInputChange={handleSearchChange}
        onChange={handleSelect}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => `${option.name} - ${option.id}`}
        renderOption={(option) => (
          <>
            {brand_target && brand_target.id === option.id ? (
              <>
                {option.name} - {option.id}
                <span style={{
                  marginLeft: "6px",
                  backgroundColor: "#0029371f",
                  fontSize: "10px",
                  fontWeight: "bold",
                  padding: "2px 4px"
                }}>
                  Upsell brand
                </span>
              </>
            ) : `${option.name} - ${option.id}`}
          </>
        )}
        options={Object.keys(brands).map((key) => ({ name: brands[key], id: key }))}
        loading={focused && (isBrandsFetching || isBrandFetching)}
        renderInput={params => (
          <TextField
            {...params}
            label="Search by brand name"
            fullWidth
            // helperText="Search by brand name or id."
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {(focused && (isBrandsFetching || isBrandFetching)) ? <CircularProgress color="inherit" size={20} style={{ position: "absolute", right: "11px" }} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  brands: state.reducerBrandUpsells.items || false,
  isBrandsFetching: state.reducerBrandUpsells.isFetching,
  isBrandFetching: state.reducerBrands.isFetchingItem,
});

export default connect(
  mapStateToProps,
  {
    ...brandUpsellsActions,
    ...brandsActions,
  },
)(BrandSearch);
