import React, { Component } from "react";
import PropTypes from "prop-types";
import DefinedRange from "react-date-range/dist/components/DefinedRange";
import DateRange from "react-date-range/dist/components/DateRange";
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  startOfMonth,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  format,
} from "date-fns";
import { OutlinedInput } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "./recommendations.scss";

/**
 * Helper function from vendor.
 */
function findNextRangeIndex(ranges, currentRangeIndex = -1) {
  const nextIndex = ranges.findIndex(
    (range, i) => i > currentRangeIndex && range.autoFocus !== false && !range.disabled
  );
  if ( nextIndex !== -1 ) return nextIndex;
  return ranges.findIndex(range => range.autoFocus !== false && !range.disabled);
}

class RecommendationsDateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedRange: [findNextRangeIndex(props.ranges), 0],
      open: false,
      isMobile: props.isMobile,
      noCssPosition: props.noCssPosition,
    };

    this.openClosePicker = this.openClosePicker.bind(this);
  }

  openClosePicker() {
    this.setState(state => ({
      open: !state.open,
    }));
  }

  render() {
    const {
            focusedRange,
            open,
            isMobile,
            noCssPosition,
          } = this.state;
    return (
      <div className="date-picker-container" style={{
        position: "relative",
      }}>
        <OutlinedInput
          onClick={this.openClosePicker}
          style={{
            width: isMobile ? "100%" : 260,
            height: 40,
            background: "white",
            top: noCssPosition ? null : (isMobile ? null : "50%"),
            transform: noCssPosition ? null : (isMobile ? null : "translateY(-50%)"),
          }}
          value={format(this.props.ranges[ 0 ].startDate, "LLL d, y") + " - " + format(this.props.ranges[ 0 ].endDate, "LLL d, y")}
          startAdornment={<InputAdornment position="start"><DateRangeIcon
            style={{ opacity: 0.5 }}/></InputAdornment>}/>
        {open && <>
          <div style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }} onClick={this.openClosePicker}
            className="picker-container"/>
          <div style={{
            display: "flex",
            position: "absolute",
            top: 50,
            right: -30,
            zIndex: 4,
            background: "#fff",
            border: "1px solid #ccc",
            flexDirection: isMobile ? "column" : "row",
          }}>
            <DefinedRange
              focusedRange={focusedRange}
              onPreviewChange={value =>
                this.dateRange.updatePreview(
                  value ? this.dateRange.calcNewSelection(value, typeof value === "string") : null
                )
              }
              {...this.props}
              range={this.props.ranges[ focusedRange[ 0 ] ]}
              className={isMobile ? "static-ranges" : ""}
              inputRanges={[]}
              staticRanges={[
                {
                  label: "Last 30 days",
                  range: () => ({
                    startDate: addDays(new Date(), -30),
                    endDate: new Date()
                  }),
                  isSelected() {
                    return false;
                  }
                },
                {
                  label: "This month",
                  range: () => ({
                    startDate: startOfMonth(new Date()),
                    endDate: endOfMonth(new Date())
                  }),
                  isSelected() {
                    return false;
                  }
                },
                {
                  label: "Last month",
                  range: () => ({
                    startDate: startOfMonth(addMonths(new Date(), -1)),
                    endDate: endOfMonth(addMonths(new Date(), -1))
                  }),
                  isSelected() {
                    return false;
                  }
                },
                {
                  label: "This week",
                  range: () => ({
                    startDate: startOfWeek(new Date()),
                    endDate: endOfWeek(new Date())
                  }),
                  isSelected() {
                    return false;
                  },
                },
                {
                  label: "Last week",
                  range: () => ({
                    startDate: startOfWeek(addDays(new Date(), -7)),
                    endDate: endOfWeek(addDays(new Date(), -7))
                  }),
                  isSelected() {
                    return false;
                  },
                },
                {
                  label: "This year",
                  range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfYear(new Date())
                  }),
                  isSelected() {
                    return false;
                  },
                },
                {
                  label: "Last year",
                  range: () => ({
                    startDate: startOfYear(addYears(new Date(), -1)),
                    endDate: endOfYear(addYears(new Date(), -1))
                  }),
                  isSelected() {
                    return false;
                  },
                }
              ]}
            />
            <DateRange
              onRangeFocusChange={focusedRange => this.setState({ focusedRange })}
              focusedRange={focusedRange}
              {...this.props}
              ref={t => (this.dateRange = t)}
              className={undefined}
            />
          </div>
        </>}
      </div>
    );
  }

}

RecommendationsDateRangePicker.defaultProps = {};

RecommendationsDateRangePicker.propTypes = {
  ...DateRange.propTypes,
  ...DefinedRange.propTypes,
  className: PropTypes.string,
};

export default RecommendationsDateRangePicker;
