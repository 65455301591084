import React, { useEffect, useState } from "react";
import {
  Button, Checkbox,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyFormat from "react-currency-format";
import Truncate from "react-truncate";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  recommendationCard: {
    padding: "20px 0px",
    marginBottom: 10,
    border: "1px solid #e1e1e3",
    borderRadius: 5,
  },
  recommendationProductItem: {
    margin: "0",
    display: "flex",
    position: "relative",
  },
  recommendationProductImage: {
    width: 60,
    marginRight: 10,
  },
  recommendationProductName: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  recommendationStats: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 10,
    borderTop: "1px solid #e1e1e3",
    paddingTop: 10,
  },
  recommendationsFooter: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 20,
  },
  recommendationStat: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  recommendationAction: {
    margin: "auto 10px",
    width: "100%",
  },
  selectAllAction: {
    margin: "10px 0",
    width: "50%",
  },
  recommendationAddIcon: {
    fontSize: 24,
    color: "#b3b3b3",
    textAlign: "center",
    margin: "-15px 0 -5px 0",
  },
}));

export default function RecommendationsCards({
  sortOrder,
  sortHandler,
  recommendations,
  isFetching,
  popupCallback,
  showCheckbox,
  showMainProduct: showMainProductAndSecondOffer,
  scrollable,
  handleCreateAllUpsellsClick,
  showCreateAllButton,
  checkedProductsForwarder,
  popupIsOpen,
}) {
  const classes                                             = useStyles();
  const [checkedProductsTracker, setCheckedProductsTracker] = useState({});
  const [checkedAllProducts, setCheckedAllProducts] = useState(false);

  useEffect(() => {
    if ( checkedProductsForwarder ) {
      checkedProductsForwarder(checkedProductsTracker);
    }
  }, [checkedProductsTracker, checkedProductsForwarder]);

  /**
   * Returns the product data based on the SKU.
   *
   * @param {string} sku The SKu to look for.
   *
   * @return {Object} The product data.
   */
  const getProductInfo = (sku) => {
    return typeof recommendations.skus_data[ sku ] !== "undefined" ? recommendations.skus_data[ sku ] : {
      "name": "",
      "image": "",
    };
  };

  // eslint-disable-next-line
  const checkProduct = (id) => {
    let checkedProducts = checkedProductsTracker;
    if ( Object.keys(checkedProductsTracker)
      .indexOf(id.toString()) === -1 ) {
      checkedProducts[ id ] = false;
    }

    checkedProducts = { ...checkedProducts, ...{ [ id ]: !checkedProducts[ id ] } };

    setCheckedProductsTracker(checkedProducts);
  };

  const checkAllProductsOnPage = () => {
    if (Object.keys(checkedProductsTracker).length < recommendations.recommendations.length){
      let checkedProducts = {};
      recommendations.recommendations.forEach((recommendation)=>{
        checkedProducts[recommendation.id] = true;
      });
      setCheckedProductsTracker(checkedProducts);
      setCheckedAllProducts(true);
    } else {
      setCheckedProductsTracker({});
      setCheckedAllProducts(false);
    }
  };

  const Recommendation = ({
    recommendation,
    checkData,
  }) => {
    let mainProduct = getProductInfo(recommendation.main),
        offer1      = getProductInfo(recommendation.offer1),
        offer2      = recommendation.offer2 ? getProductInfo(recommendation.offer2) : false;

    const ProductCard = ({ product }) => {
      return <div className={classes.recommendationProductItem}>
        <div>
          <img src={product.image} alt={product.name}
            className={classes.recommendationProductImage}/>
        </div>
        <div className={classes.recommendationProductName}>
          <Truncate lines={3}>{product.name}</Truncate>
        </div>
      </div>;
    };

    return (
      <Paper className={classes.recommendationCard}>
        <div
          style={{
            display: "inline-flex",
            paddingLeft: showCheckbox ? 0 : 10,
            paddingRight: showCheckbox ? 0 : 10,
            width: "100%",
            padding: "0 10px",
          }}
        >
          <div style={{width: "100%",}}>
            <div style={{display: "inline-flex"}}>
              <div
                style={{
                  display: "flex",
                }}>
                <Checkbox
                  checked={Object.keys(checkData)
                    .indexOf(recommendation.id.toString()) === -1 ? false : checkData[ recommendation.id ]}
                  onChange={() => checkProduct(recommendation.id)}
                />
              </div>
              <div>
                {showMainProductAndSecondOffer && <ProductCard product={mainProduct}/>}
                {showMainProductAndSecondOffer &&
                <div className={classes.recommendationAddIcon}>
                  +
                </div>}
                <ProductCard product={offer1}/>
                {showMainProductAndSecondOffer && offer2 &&
                <div className={classes.recommendationAddIcon}>
                  +
                </div>}
                {showMainProductAndSecondOffer && offer2 && <ProductCard product={offer2}/>}
              </div>
            </div>
            <div className={classes.recommendationStats}>
              <div className={classes.recommendationStat}>
                <div>Total Sales</div>
                <div><b><CurrencyFormat value={recommendation.revenue.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true} prefix={"$"}/></b></div>
              </div>
              <div className={classes.recommendationStat}>
                <div>Ordered Together</div>
                <div>{recommendation.bought}</div>
              </div>
            </div>
          </div>

        </div>
        <div className={classes.recommendationsFooter}>
          {!showCheckbox &&
          <Button className={classes.recommendationAction} color="primary" variant="contained"
            style={{
              display: !showCreateAllButton ? "initial" : "none",
            }}
            onClick={() => {
              popupCallback(recommendation.main, mainProduct.name);
            }}>Create Upsell</Button>}
        </div>
      </Paper>
    );
  };


  return (
    <div className={classes.root}>
      {popupIsOpen && !showCreateAllButton ?
        !checkedAllProducts ?
          <Button className={classes.selectAllAction} color="primary" variant="contained"
            onClick={checkAllProductsOnPage}
          >
            Select All
          </Button>
          :
          <Button className={classes.selectAllAction} color="default" variant="contained"
            onClick={checkAllProductsOnPage}>
            Deselect All
          </Button>
      :
        <div style={{
          display: showCreateAllButton ? "inline-flex" : "none",
          position: "sticky",
          top: 0,
          zIndex: 10,
          background: "#ffffff",
          padding: "12px 10px",
          marginLeft: "-10px",
          marginRight: "-10px",
        }}>
          {!checkedAllProducts ?
            <Button className={classes.selectAllAction} color="primary" variant="contained"
              onClick={checkAllProductsOnPage}
            >
              Select All
            </Button>
            :
            <Button className={classes.selectAllAction} color="default" variant="contained"
              onClick={checkAllProductsOnPage}>
              Deselect All
            </Button>
          }
          <Button
            color="primary"
            variant="outlined"
            onClick={handleCreateAllUpsellsClick}
            className={classes.selectAllAction}
            style={{
              fontSize: "12px",
              marginLeft: 10,
            }}
          >Create All Upsells</Button>
        </div>
      }
      {!isFetching && recommendations !== false && Array.isArray(recommendations.recommendations) &&
      (recommendations.recommendations.length ? recommendations.recommendations.map((recommendation, i) => {
          return <Recommendation
            recommendation={recommendation}
            checkData={checkedProductsTracker}
            key={recommendation.main + i}
          />;
        })
        : <p>There are no recommendations at this moment. Try expanding the date range.</p>)}
    </div>
  );
}
