import React, { useEffect, useState } from "react";
import BeforeUnloadComponent from "react-beforeunload-component";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { isEqual, cloneDeep } from "lodash";
import clsx from "clsx";
import {
  Grid,
  Container, Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import UpsellForm from "../Components/UpsellComponents/UpsellForm";
import canvas from "../../../assets/images/gplay.png";
import { confirm } from "../Components/confirm";
import { connect } from "react-redux";
import { actions as settingsActions } from "../../../redux/reducers/setting.resource";
import { actions as upsellsActions } from "../../../redux/reducers/upsell.resource";
import notification from "../Components/notification";
import moment from "moment";
import PopupPreview from "../Components/PopupPreview";
import {
  ArrowBack,
  ArrowForwardRounded,
  Assessment,
  AssessmentOutlined,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";
import { appUrl } from "../../../config/config";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: "0px !important",
    // marginTop: theme.spacing(3),
  },
  rootMobile: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: "5px",
    zIndex: 3,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  scroll: {
    overflowY: "auto",
    height: "100vh",
    borderRight: "1px solid #0000001f",
  },
  container: {
    backgroundImage: `url(${canvas})`,
    backgroundRepeat: "repeat",
    margin: 0,
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "none",
  },
  upsellTopBar: {
    alignItems: "center",
    display: "flex",
    padding: "10px",
    position: "sticky",
    top: "0px",
    width: "100%",
    backgroundColor: "white",
    zIndex: 2,
  },
  questWrapper: {
    position: "relative",
  },
  buttons: {
    marginRight: "10px",
  },
  lastButton: {
    marginRight: "0px",
    marginLeft: "auto",
    color: '#c00',
  },
  disabled: {
    opacity: 0.7,
  },
  upsellSettings: {
    position: "relative",
    display: 'flex',
    flexDirection: 'column',
  },
  settingsPreviewButton: {
    display: "none",
    float: "left",
    position: "fixed",
    bottom: "15px",
    left: "20px",
    zIndex: 3,
  },
  settingsViewProductButton: {
    position: "fixed",
    top: 10,
    right: 16,
    zIndex: 3,
    fontSize: "smaller",
    display: "flex",
    "& > button": {
      marginLeft: 5,
    },
    "& svg": {
      marginLeft: 5,
    }
  },
}));

function UpsellQuest({
  createUpsell,
  updateUpsell,
  upsellItem,
  deleteUpsell,
  getUpsell,
  getSetting,
}) {
  const { id }                                            = useParams();
  const history                                           = useHistory();
  const s                                                 = useSelector(state => (state.reducerSettings.item));
  const [fData, setData]                                  = useState(upsellItem || {
    name: "",
    store_url: "",
    apply_discount: false
  });
  const [initData, setInitData]                           = useState({});
  const [isLoadingStyle, setIsLoadingStyle]               = useState(true);
  const [previewOpen, setPreviewOpen]                     = useState(false);
  const [isMobile, setIsMobile]                           = useState(window.innerWidth < 1024);
  const [viewProductButtonOpen, setViewProductButtonOpen] = useState(false);
  const [showHiddenOffers, setShowHiddenOffers]           = useState(true);
  const [hasHiddenOffers, setHasHiddenOffers]             = useState(false);
  const [showAnalytics, setShowAnalytics]                 = useState(false);

  const classes = useStyles();

  if ( id === "new" ) {
    document.title = document.title.replace("Edit", "Add");
  }

  useEffect(() => {
    getSetting()
      .then(res => {
        if ( !res.body ) {
          notification({
            isOpen: true,
            type: "error",
            messageText: res.message || "Unknown error.",
          });
        }
      });
  }, [getSetting]);

  useEffect(() => {
    if ( upsellItem ) {
      const data = {
        ...upsellItem,
        upsell_products: [...upsellItem.upsell_products.sort((a, b) => a.priority > b.priority ? 1 : -1)],
      };
      setInitData(data);
    }
  }, [upsellItem]);

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  const showPreview = () => {
    setPreviewOpen(true);
  };

  const hidePreview = () => {
    setPreviewOpen(false);
  };

  const dispatchData = (values) => {
    setData(values);

    if ( Object.keys(initData).length === 0 ) {
      setInitData(values);
    }
  };

  const {
          name,
          display_for,
          display_on,
          product_target,
          category_target,
          brand_target,
          custom_target,
          upsell_products,
        } = fData;

  const getEmptyValues = (name) => {
    const res = {
      all: {
        brand_target: false,
        category_target: false,
        product_target: false,
      },
      brand_target: {
        category_target: false,
        product_target: false,
      },
      category_target: {
        brand_target: false,
        product_target: false,
      },
      product_target: {
        brand_target: false,
        category_target: false,
      }
    };
    return res[ name ] || {};
  };

  const prepareSendData = () => {
    const data  = cloneDeep(fData);
    let message = false;
    if ( !name || name.trim() === "" ) message = "Upsell name is a required field.";
    if ( display_for === "category_target" && !category_target ) message = "At least one category target must be selected.";
    if ( display_for === "brand_target" && !brand_target ) message = "At least one brand target must be selected.";
    if ( display_for === "product_target" && !product_target ) message = "At least one product target must be selected.";
    if ( display_for === "custom_target" && !custom_target ) message = "At least one content target must be selected.";
    if ( display_on.length === 0 ) message = "At least one \"Display On\" trigger must be selected.";
    data.display_on = display_on.map(item => item.val);
    if ( !upsell_products || upsell_products.length === 0 ) message = "At least one upsell offer must be added.";
    const index = upsell_products.findIndex(({ discount_amount }) => parseFloat(discount_amount, 2) > 100);
    data.upsell_products.forEach((offer) => {
      if ( offer.apply_discount && (!offer.discount_code || !offer.discount_amount) ) {
        message = "Both discount amount and discount code have to be filled out if discounts are being used.";
      }

      if ( offer.apply_discount && offer.discount_amount && parseFloat(offer.discount_amount) === 0.00 ) {
        message = "Discount amount must be greater than zero.";
      }
    });

    if ( index !== -1 ) {
      message = "Discount amount is not valid.";
    }
    data.product_target  = product_target && display_for === "product_target" ? product_target.sku : false;
    data.category_target = category_target && display_for === "category_target" ? category_target.id : false;
    data.brand_target    = brand_target && display_for === "brand_target" ? brand_target.id : false;
    data.custom_target   = custom_target && display_for === "custom_target" ? custom_target.id : false;
    Object.assign(data, getEmptyValues(display_for));

    data.upsell_products = upsell_products.map((item, key) => ({
      apply_discount: item.apply_discount || false,
      description: item.description || "",
      discount_amount: item.discount_amount || "",
      discount_type: item.discount_type || "",
      discount_code: item.discount_code || "",
      discount_start: item.discount_start || "",
      discount_end: item.discount_end || "",
      match_parent_quantity: item.match_parent_quantity || false,
      show_quantity_dropdown: item.show_quantity_dropdown || false,
      offer_intent: item.offer_intent || false,
      remove_on_parent_remove: item.remove_on_parent_remove || false,
      sku: item.sku || false,
      priority: item.priority || key,
    }))
      .sort((a, b) => a.priority > b.priority ? 1 : -1);
    if ( message ) {
      notification({
        isOpen: true,
        type: "error",
        messageText: message,
      });
      return false;
    }
    if ( parseInt(id) > 0 ) {
      data.updated_at = moment(Date.now())
        .format("X");
    } else {
      data.created_at = data.updated_at = moment(Date.now())
        .format("X");
    }
    return data;
  };

  const save = (e) => {
    e.preventDefault();
    const data = prepareSendData();
    if ( !data ) return false;
    if ( parseInt(id) > 0 ) {
      update(data);
    } else {
      create(data);
    }
    if ( fData.display_for === "product_target" ) {
      setViewProductButtonOpen(true);
    }
  };

  const create = (data, location = false) => {
    createUpsell(data)
      .then((res) => {
        if ( res.body && res.body.status === "success" ) {
          notification({
            isOpen: true,
            type: "success",
            messageText: "Upsell created",
          });
          if ( location ) {
            history.push(location.pathname);
          } else {
            history.push(`/upsells/${res.body.upsell}`);
          }
        } else {
          notification({
            isOpen: true,
            type: "error",
            messageText: "Error creating upsell",
          });
        }
      })
      .catch(() => {
        notification({
          isOpen: true,
          type: "error",
          messageText: "There was an error during upsell creation, please try again later.",
        });
      });
  };

  const update = (data, location = false) => {
    updateUpsell({ id, ...data })
      .then((res) => {
        if ( res.body && res.body.status === "success" ) {
          notification({
            isOpen: true,
            type: "success",
            messageText: "Upsell updated",
          });
          if ( location ) {
            history.push(location.pathname);
            return;
          }
          getUpsell({ id: id });
        } else {
          notification({
            isOpen: true,
            type: "error",
            messageText: "There was an error during upsell save, please try again.",
          });
        }
      })
      .catch(() => {
        notification({
          isOpen: true,
          type: "error",
          messageText: "There was an error during upsell save, please try again.",
        });
      });
  };

  const getCropped = () => {
    if ( fData ) {
      const o = cloneDeep(fData);
      delete o[ "default_heading" ];
      if ( o[ "is_custom_heading" ] === false ) {
        delete o[ "custom_heading" ];
      }
      if ( o.display_on ) {
        o.display_on = o.display_on.map(item => item.val);
      }
      return o;
    }
    return {};
  };

  const getCroppedInitData = () => {
    if ( initData ) {
      const o = cloneDeep(initData);
      delete o[ "default_heading" ];
      if ( o[ "is_custom_heading" ] === false ) {
        delete o[ "custom_heading" ];
      }
      if ( o.display_on ) {
        o.display_on = o.display_on.map(item => {
          return typeof item === "object" ? item.val : item;
        });
      }
      return o;
    }
    return {};
  };

  const deleteUpsellHandler = (id, name) => {
    confirm({
      confirmActionText: "Delete",
      cancelActionText: "Cancel",
      confirmAction: () => {
        deleteUpsell(id)
          .then((res) => {
            if ( res.body && res.body.status === "success" ) {
              notification({
                isOpen: true,
                type: "success",
                messageText: "Upsell deleted",
              });
              window.location.href = "/upsells";
            } else {
              notification({
                isOpen: true,
                type: "error",
                messageText: "Upsell delete error",
              });
            }
          })
          .catch((err) => {
            notification({
              isOpen: true,
              type: "error",
              messageText: "Upsell delete error",
            });
          });
      }, // optional
      cancelAction: () => {
      }, // optional
      text: `Are you sure you want to delete upsell '${name}'?`,
    });
  };

  const handleCancel = () => {
    window.history.back();
  };

  let product = {
    name: "All Products",
  };

  switch ( fData.display_for ) {
    case "brand_target":
      product = {
        name: fData.brand_target ? "Products with brand: " + fData.brand_target.name : "Specific Brand",
      };
      break;
    case "category_target":
      product = {
        name: fData.category_target ? "Products with category: " + fData.category_target.name : "Specific Category",
      };
      break;
    case "product_target":
      product = fData.product_target ? fData.product_target : {
        name: "Specific product",
      };
      break;
    case "all":
    default:
      break;
  }

  return (
    <div className={classes.questWrapper} key={id}>
      <BeforeUnloadComponent blockRoute={!isEqual(getCroppedInitData(), getCropped())}/>
      <Grid container className={clsx(classes.root)} item xs={12} sm={12} spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}
          className={clsx(classes.root, classes.scroll, classes.upsellSettings)}>
          <div className={classes.upsellTopBar}>
            <Button onClick={handleCancel} className={classes.buttons} size="large"
                variant="text" startIcon={<ArrowBack/>}>Back</Button>
            <Button onClick={save} className={classes.buttons} size="large" variant="contained"
              color="primary">Save</Button>
            <Button onClick={() => deleteUpsellHandler(id, fData.name)} color="primary"
              className={clsx(classes.lastButton)} disabled={!(parseInt(id) > 0)} size="large"
              variant="text">Delete</Button>
          </div>
          {s && s.upsells_global_popup_title_has_offers &&
          <UpsellForm settings={s} dispatchData={dispatchData} save={save}
            getEmptyValues={getEmptyValues}/>}
          <Button
            onClick={showPreview}
            className={clsx(classes.settingsPreviewButton)}
            style={{
              display: isMobile ? "flex" : "none",
            }}
            variant="contained"
            color="secondary">Preview
          </Button>
        </Grid>
        {(!isMobile || (isMobile && previewOpen)) &&
        <Grid item className={clsx(classes.root, isMobile ? classes.rootMobile : null)} sm={7}
          md={8} lg={8}>
          <div className={clsx(classes.settingsViewProductButton)}>
            <Button
              onClick={() => setShowAnalytics(!showAnalytics)}
              variant="contained"
              size="small"
              color="secondary">{showAnalytics ? "Hide" : "Show"} offer analytics
              {showAnalytics ?
                <Assessment fontSize="small"/> :
                <AssessmentOutlined fontSize="small"/>
              }
            </Button>
            {hasHiddenOffers && <Button
              onClick={() => setShowHiddenOffers(!showHiddenOffers)}
              variant="contained"
              size="small"
              color="secondary">{showHiddenOffers ? "Hide" : "Show"} hidden offers
              {showHiddenOffers ?
                <VisibilityOff fontSize="small"/> :
                <Visibility fontSize="small"/>
              }
            </Button>}
            {product_target && (viewProductButtonOpen || fData.display_for === "product_target") &&
            <Button
              onClick={() => window.open(s.siteUrl + product_target.url)}
              variant="contained"
              size="small"
              color="secondary">View product
              <ArrowForwardRounded
                fontSize="small"
              />
            </Button>}
          </div>
          <Container className={classes.container} style={{
            position: isMobile ? "absolute" : "relative",
            top: isMobile && 65,
            height: isMobile ? "90vh" : "100vh",
          }}>
            {s && <>
              <link rel={"stylesheet"}
                href={appUrl + "/" + s.upsells_global_popup_layout + ".css"}
                onLoad={() => setIsLoadingStyle(false)}
              />
              <PopupPreview settings={s} offers={fData.upsell_products} product={product}
                heading={fData.is_custom_heading ? fData.custom_heading : false}
                isLoading={isLoadingStyle} popupCloseCallback={hidePreview}
                showHiddenOffers={showHiddenOffers}
                hasHiddenOffersCallback={setHasHiddenOffers}
                showAnalytics={showAnalytics}/>
            </>}
          </Container>
        </Grid>}
      </Grid>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => (
  {
    upsellItem: state.reducerUpsells.item || false,
  }
);

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
    ...upsellsActions,
  },
)(UpsellQuest);
