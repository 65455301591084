import React from "react";
import { connect } from "react-redux";
import {
  Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { hasAuthData, reSetAuthToken } from "./redux/authStorage";
import DashApp from "./containers/DashApp/App";
import App from "./containers/App/App";
import "./redux/reducers/globalAuth";
import { ResetPassword } from "./containers/MainApp/Pages";

const PrivateRoute = ({ children, isAuth, ...rest }) => {
  reSetAuthToken();
  return (
    <Route
      // eslint-disable-next-line
      {...rest}
      render={
        // eslint-disable-next-line
        ({ location }) => {
          if ( isAuth ) {
            return (children);
          } else {
            window.location = '/signin';
          }
        }
      }
    />
  );
};

// eslint-disable-next-line
const SiteRoute = ({ children, isAuth, ...rest }) => {
  reSetAuthToken();
  return (
    <Route
      // eslint-disable-next-line
      {...rest}
     render={
      ({ location }) => (
        ["/signin"].indexOf(location.pathname) !== -1
        && isAuth
      ) ? (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
        ) : (children)
      }
    />
  );
};

const Routes = function({history, isAuth}) {
  const api_regex = /^\/api\/.*/
  if (api_regex.test(window.location.pathname)) {
    return <div/>;
  } else {
    return(
      <Router history={history}>
        <Switch>
          <SiteRoute exact path="/signin" isAuth={isAuth}>
            <App isAuth={isAuth} />
          </SiteRoute>
          <SiteRoute exact path="/passwordReset">
            <ResetPassword />
          </SiteRoute>
          <PrivateRoute exact path="/*" isAuth={isAuth}>
            <DashApp isAuth={isAuth} />
          </PrivateRoute>
          <Redirect exact from="/*" to="/signin" />
        </Switch>
      </Router>
    )
  }
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  isAuth: hasAuthData(),
});
export default connect(
  mapStateToProps,
  {},
)(Routes);
