const loadDashly = (callback) => {
  function t(t, e) {
    return function () {
      window.dashlyasync.push(t, arguments);
    };
  }

  if ( "undefined" == typeof window.dashly ) {
    var e    = document.createElement("script");
    e.type   = "text/javascript";
    e.async  = !0;
    e.src    = "//cdn.dashly.app/api.min.js";
    e.onload = () => {
      callback();
    };
    document.getElementsByTagName("head")[ 0 ].appendChild(e);
    window.dashly          = {};
    window.dashlyasync     = [];
    window.dashly.settings = {};
    for ( var n = ["connect", "track", "identify", "auth", "oth", "onReady", "addCallback", "removeCallback", "trackMessageInteraction"], a = 0; a < n.length; a++ ) window.dashly[ n[ a ] ] = t(n[ a ]);
  }
  window.dashly.connect("1579-40c5ee09d645c08121df8b7f551");
};

export default loadDashly;
