import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container, Grid, Typography,
} from "@material-ui/core";
import "whatwg-fetch";
import { makeStyles } from "@material-ui/core/styles";
import { isLocal, isStaging } from "../../../config/config";
import { actions as meActions } from "../../../redux/reducers/me.resource";
import googleLogo from "../../../assets/images/google-logo.png";
import facebookLogo from "../../../assets/images/facebook-logo.png";
import clsx from "clsx";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  table: {
    borderCollapse: 'collapse',
    '& td, & th': {
      fontFamily: 'Roboto Light',
      padding: '10px 20px',
      fontSize: 16,
    }
  },
  tableLogo: {
    maxWidth: 150,
  },
  tableBorderRight: {
    borderRight: '2px solid #ccc',
  },
  tableBorderBottom: {
    borderBottom: '2px solid #ccc',
  }
}));

function SocialLoginAnalytics({
  me,
  getMe,
}) {
  const [ isMobile, setIsMobile ]                     = useState(window.innerWidth < 1024);
  const classes                                       = useStyles();
  const [ analyticsError, setAnalyticsError ]         = useState('');
  const [ isLoadingAnalytics, setIsLoadingAnalytics ] = useState(true);
  const [ analytics, setAnalytics ]                   = useState(null);

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  useEffect(
    () => {
      getMe()
        .catch(console.log);
    }, [ getMe ],
  );

  // Download analytics.
  useEffect(() => {
    if ( me ) {
      fetch('https://misc.pdsync.com.au/neto-social-login' + (isLocal || isStaging ? '-staging' : '') + '/stats.php?ref=' + me.track_id).then(r => r.json()).then(r => {
        setIsLoadingAnalytics(false);
        if ( typeof r.status === 'undefined' ) {
          setAnalyticsError('Invalid API response.');
          return;
        }

        if ( r.status === 'error' ) {
          setAnalyticsError('API error: ' + r.message);
          return;
        }

        setAnalytics(r.message);
      }).catch(r => {
        setIsLoadingAnalytics(false);
        setAnalyticsError(r.toString())
      });
    }
  }, [ me ])

  return (
    <Container maxWidth="xl">
      <Grid className={classes.root} item xs={12} sm={12}>
        <Typography component="h2" variant="h6" color="secondary" gutterBottom
          className={classes.title} style={{
          display: isMobile ? "inherit" : "flex",
          textAlign: "center",
        }}>
          Social Login | Analytics
        </Typography>
        {isLoadingAnalytics ? <p>Loading...</p> : ''}
        {analyticsError ? <p>There was an error loading analytics: {analyticsError}</p> : ''}
        {analytics === null ? '' : (analytics === 'No data.' ?
          <p>No analytics data at this moment. Please try again later.</p> : <table className={classes.table}>
            <thead>
            <tr>
              <th className={clsx(classes.tableBorderRight, classes.tableBorderBottom)}>Date
                Calculated <br/>{new Date(parseInt(analytics.last_updated) * 1000).toLocaleString('en-AU', { dateStyle: "medium" })}
              </th>
              <th className={classes.tableBorderBottom}><img className={classes.tableLogo} src={googleLogo}
                alt="Google"/>
              </th>
              <th className={classes.tableBorderBottom}><img className={classes.tableLogo} src={facebookLogo}
                alt="Facebook"/></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className={classes.tableBorderRight}>Date of deployment</td>
              <td
                className="tg-0lax">{typeof analytics.networks.google !== 'undefined' ? new Date(parseInt(analytics.networks.google.deployment_date) * 1000).toLocaleString('en-AU', { dateStyle: "medium" }) : 'N/A'}</td>
              <td
                className="tg-0lax">{typeof analytics.networks.facebook !== 'undefined' ? new Date(parseInt(analytics.networks.facebook.deployment_date) * 1000).toLocaleString('en-AU', { dateStyle: "medium" }) : 'N/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableBorderRight}>Total Customers</td>
              <td
                className="tg-0lax">{typeof analytics.networks.google !== 'undefined' ? analytics.networks.google.total_customers : 'N/A'}</td>
              <td
                className="tg-0lax">{typeof analytics.networks.facebook !== 'undefined' ? analytics.networks.facebook.total_customers : 'N/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableBorderRight}>% of Total Customers</td>
              <td
                className="tg-0lax">{typeof analytics.networks.google !== 'undefined' ? analytics.networks.google.social_percentage : 'N/A'}%
              </td>
              <td
                className="tg-0lax">{typeof analytics.networks.facebook !== 'undefined' ? analytics.networks.facebook.social_percentage : 'N/A'}%
              </td>
            </tr>
            <tr>
              <td className={classes.tableBorderRight}>Revenue Generated</td>
              <td
                className="tg-0lax">${typeof analytics.networks.google !== 'undefined' ? analytics.networks.google.additional_revenue_social : 'N/A'}</td>
              <td
                className="tg-0lax">${typeof analytics.networks.facebook !== 'undefined' ? analytics.networks.facebook.additional_revenue_social : 'N/A'}</td>
            </tr>
            <tr>
              <td className={classes.tableBorderRight}>% of Total Revenue</td>
              <td
                className="tg-0lax">{typeof analytics.networks.google !== 'undefined' ? analytics.networks.google.social_revenue_percentage : 'N/A'}%
              </td>
              <td
                className="tg-0lax">{typeof analytics.networks.facebook !== 'undefined' ? analytics.networks.facebook.social_revenue_percentage : 'N/A'}%
              </td>
            </tr>
            </tbody>
          </table>)}
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  me: state.reducerMe.item,
});

export default connect(
  mapStateToProps,
  {
    ...meActions,
  }
)(SocialLoginAnalytics);
