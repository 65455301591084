import React, { useEffect, useState } from "react";
import {
  Button, CircularProgress,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import "./index.scss";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import { loadCachedRequestData, saveCachedRequestData } from "../../../../helpers/cache";
import { apiUrl, isLocal, isStaging } from "../../../../../../config/config";
import googleLogo from "../../../../../../assets/images/google-logo-icon.png";
import facebookLogo from "../../../../../../assets/images/facebook-logo-icon.png";
import { getAuthData } from "../../../../../../redux/authStorage";
import {Refresh} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  itemTitle: {
    fontWeight: 500,
    color: "#002937",
  },
}));

function MyApplications({
  uData: tmpStats,
  isStatsLoading,
  subscriptions,
  getSubscriptions,
}) {
  const classes                                   = useStyles();
  const history                                   = useHistory();
  const [ stats, setStats ]                       = useState(loadCachedRequestData("stats", false));
  const [ socialLoginStats, setSocialLoginStats ] = useState(loadCachedRequestData("social-login-stats", null));

  useEffect(() => {
    if ( tmpStats ) {
      saveCachedRequestData(tmpStats, "stats");
      setStats(tmpStats);
    }
  }, [ tmpStats ]);

  useEffect(() => {
    if ( socialLoginStats === null && subscriptions && loadCachedRequestData("me", false) ) {
      let doHaveSocialLogin = false;
      Object.values(subscriptions).forEach(sub => {
        if ( sub.plan.product === 'prod_KpVoGKwxadTIxl' ) {
          doHaveSocialLogin = true;
        }
      })

      if ( doHaveSocialLogin ) {
        fetch('https://misc.pdsync.com.au/neto-social-login' + (isLocal || isStaging ? '-staging' : '') + '/stats.php?ref=' + loadCachedRequestData("me", { track_id: null }).track_id).then(res => res.json())
          .then(stats => setSocialLoginStats(stats.message))
      }
    }
  }, [ socialLoginStats, subscriptions ])

  const appItem = (statData = false, subscription) => {
    let subscription_status_pretty = '';
    let subscription_problem       = false;
    switch ( subscription.status ) {
      case 'active':
        subscription_status_pretty = 'Active';
        break;
      case 'past_due':
        subscription_problem       = true;
        subscription_status_pretty = 'Past Due';
        break;
      case 'incomplete':
        subscription_problem       = true;
        subscription_status_pretty = 'Incomplete';
        break;
      case 'unpaid':
        subscription_problem       = true;
        subscription_status_pretty = 'Unpaid';
        break;
      default:
        subscription_problem = false;
        break;
    }

    if ( subscription_problem ) {
      return (
        <Paper className="application-item" key={subscription.id} style={{ borderColor: '#cc0000' }} onClick={() => window.open(apiUrl + "/subscriptions_portal?token=" + (Object.keys(getAuthData()).length > 0 ? getAuthData().sid : ""))}>
          <Typography component="h1" variant="h5" className={classes.itemTitle}>
            {subscription.plan.product_name}
          </Typography>
          <p style={{ fontWeight: 'bold', margin: '20px 0 5px' }}>Subscription is inactive.</p>
          <p style={{margin: '5px 0'}}>Subscription status is <span style={{
            background: '#c00',
            color: 'white',
            padding: '2px 6px',
            fontWeight: 'bold',
            borderRadius: 3,
          }}>{subscription_status_pretty}</span></p>
          <p style={{margin: '5px 0'}}>Click on card to view the subscription details.</p>
        </Paper>
      )
    } else {
      return (
        <Paper className="application-item" onClick={() => history.push(subscription.plan.product === 'prod_Ji6g9qtr2IPDqX' ? "/upsells" : "/social-login/analytics")} key={subscription.id}>
          {(subscription.plan.product === 'prod_KpVoGKwxadTIxl' ? socialLoginStats === null : isStatsLoading) &&
            <CircularProgress style={{
              position: "absolute",
              top: 15,
              right: 15,
              width: 16,
              height: 16,
            }}/>}
          <Typography component="h1" variant="h5" className={classes.itemTitle}>
            {subscription.plan.product_name}
          </Typography>
          {subscription.plan.product === 'prod_Ji6g9qtr2IPDqX' ? <div className="table-content">
            <div className="row">
              <div className="col">Revenue</div>
              <div className="col">Add to Carts</div>
            </div>
            <div className="row">
              <div className="col">
                <NumberFormat
                  prefix={"$"}
                  displayType={"text"}
                  thousandSeparator=","
                  value={statData ? statData.revenue : "0"}
                  renderText={(val) => val}
                />
              </div>
              <div className="col">
                <NumberFormat
                  displayType={"text"}
                  thousandSeparator=","
                  value={statData ? statData.add_to_carts : "0"}
                  renderText={(val) => val}
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-padding">Impressions</div>
              <div className="col col-padding">Conversion Rate</div>
            </div>
            <div className="row">
              <div className="col">
                <NumberFormat displayType={"text"} thousandSeparator=","
                  value={statData ? statData.impressions : "0"} renderText={(val) => val}/>
              </div>
              <div className="col">
                <NumberFormat displayType={"text"} thousandSeparator=","
                  value={statData ? parseFloat(statData.conversion_rate).toFixed(2) : "0"}
                  renderText={(val) => <>{val}%</>}/>
              </div>
            </div>
          </div> : ''}
          {subscription.plan.product === 'prod_KpVoGKwxadTIxl' ?
            <div className="table-content" style={{ borderCollapse: 'collapse' }}>
              {socialLoginStats && typeof socialLoginStats[ 'networks' ] !== 'undefined' ?
                <>
                  <div className="row">
                    <div className="col" style={{ width: '50%' }}/>
                    <div className="col" style={{ textAlign: "center", width: '25%' }}><img src={googleLogo} alt="Google"
                      style={{ width: 50 }}/></div>
                    <div className="col" style={{ textAlign: "center", width: '25%' }}><img src={facebookLogo}
                      alt="Facebook"
                      style={{ width: 50 }}/></div>
                  </div>
                  <div className="row" style={{ borderBottom: '2px solid rgb(205 216 220)' }}>
                    <div className="col" style={{ paddingBottom: 5 }}>Total customers</div>
                    <div style={{ textAlign: 'center' }}
                      className="col">{typeof socialLoginStats[ 'networks' ][ 'google' ] !== 'undefined' && typeof socialLoginStats[ 'networks' ][ 'google' ][ 'total_customers' ] !== 'undefined' ? socialLoginStats[ 'networks' ][ 'google' ][ 'total_customers' ] : 'N/A'}</div>
                    <div style={{ textAlign: 'center' }}
                      className="col">{typeof socialLoginStats[ 'networks' ][ 'facebook' ] !== 'undefined' && typeof socialLoginStats[ 'networks' ][ 'facebook' ][ 'total_customers' ] !== 'undefined' ? socialLoginStats[ 'networks' ][ 'facebook' ][ 'total_customers' ] : 'N/A'}</div>
                  </div>
                  <div className="row">
                    <div className="col">Revenue</div>
                    <div style={{ textAlign: 'center' }}
                      className="col">{typeof socialLoginStats[ 'networks' ][ 'google' ] !== 'undefined' && typeof socialLoginStats[ 'networks' ][ 'google' ][ 'additional_revenue_social' ] !== 'undefined' ?
                      <NumberFormat
                        prefix={"$"}
                        displayType={"text"}
                        thousandSeparator=","
                        value={socialLoginStats[ 'networks' ][ 'google' ][ 'additional_revenue_social' ]}
                        renderText={(val) => val}
                      /> : 'N/A'}</div>
                    <div style={{ textAlign: 'center' }}
                      className="col">{typeof socialLoginStats[ 'networks' ][ 'facebook' ] !== 'undefined' && typeof socialLoginStats[ 'networks' ][ 'facebook' ][ 'additional_revenue_social' ] !== 'undefined' ?
                      <NumberFormat
                        prefix={"$"}
                        displayType={"text"}
                        thousandSeparator=","
                        value={socialLoginStats[ 'networks' ][ 'facebook' ][ 'additional_revenue_social' ]}
                        renderText={(val) => val}
                      /> : 'N/A'}</div>
                  </div>
                </>
                : ''}
            </div> : ''}
        </Paper>
      );
    }
  };

  const addNewApp = () => (
    <Paper
      className="application-item application-item-new"
      onClick={() => document.getElementById("otherApps")
        .scrollIntoView({
          behavior: "smooth"
        })}
    >
      <Button className="add-application-button" variant="contained" aria-label="add-application"
        color="primary">
        <AddIcon/> Add Application
      </Button>
    </Paper>
  );

  return (
    <div className="application-block-wrapper">
      <div style={{
        display: 'flex',
        alignItems: 'end',
      }}>
        <Typography component="h1" variant="h4" color="secondary">My applications</Typography>
        <Button variant={"text"} color={"primary"} style={{
          minWidth: 0,
        }} onClick={()=>{
          getSubscriptions({}, {query: {nocache: 'true'}});
        }}><Refresh/></Button>
      </div>
      <div className="application-block">
        {Object.keys(subscriptions)
          .map((key) => {
            return subscriptions[ key ].status !== "canceled" && subscriptions[ key ].status !== 'incomplete_expired' ? appItem(stats, subscriptions[ key ]) : null;
          })}
        {addNewApp(Object.keys(subscriptions).length === 0)}
      </div>
    </div>
  );
}

export default MyApplications;
