import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { actions as settingsActions } from "../../../redux/reducers/setting.resource";
import { actions as productActions } from "../../../redux/reducers/product.resource";
import { actions as subscriptionsActions } from "../../../redux/reducers/subscriptions.resource";
import clsx from "clsx";
import {
  Button,
  Card,
  CardContent, CircularProgress,
  Container,
  Grid, IconButton,
  InputLabel,
  Switch,
  TextField, Tooltip,
  Typography
} from "@material-ui/core";
import canvas from "../../../assets/images/gplay.png";
import slideoutImage from "../../../assets/images/chooser-slideout.png";
import popupImage from "../../../assets/images/chooser-popup.png";
import popup1Image from "../../../assets/images/chooser-popup-1.png";
import popup2Image from "../../../assets/images/chooser-popup-2.png";
import checkmark from "../../../assets/images/checkmark.svg";
import notification from "../Components/notification";
import ColorPicker from "../Components/Settings/ColorPicker";
import PopupPreview from "../Components/PopupPreview";
import BeforeUnloadComponent from "react-beforeunload-component";
import { appUrl } from "../../../config/config";
import SyncButton from "../Components/UserDashboardCompponents/SyncButton";
import NumberFormat from "react-number-format";

let resizeTimeout = false;
const useStyles   = makeStyles(() => ({
  root: {
    margin: 0,
    padding: "0px !important",
    height: "100%"
  },
  rootMobile: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: "5px",
    zIndex: 3,
  },
  scroll: {
    overflowY: "auto",
    height: "100vh",
    borderRight: "1px solid #0000001f",
  },
  container: {
    position: "relative",
    backgroundImage: `url(${canvas})`,
    backgroundRepeat: "repeat",
    margin: 0,
    height: "100vh",
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "none",
    overflow: "hidden",
  },
  containerMobile: {
    top: 65,
    height: "90vh",
  },
  card: {
    margin: 10,
    overflow: "initial",
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    }
  },
  settingContent: {
    padding: "10px 20px",
  },
  settingItemContainer: {
    margin: "5px 0",
  },
  settingsSubTitle: {
    margin: "5px 0",
    fontWeight: "600",
  },
  designPicker: {
    cursor: "pointer",
    position: "relative",
    maxWidth: "50%",
    marginRight: 20,
    "&:last-child": {
      marginRight: 0,
    },
    "& .design-title": {
      padding: "10px 30px",
      marginTop: 5,
      marginLeft: "auto",
      marginRight: "auto",
    },
    "& img": {
      maxWidth: "100%",
    }
  },
  designPickerActive: {
    "&::after": {
      content: "url(" + checkmark + ")",
      display: "block",
      position: "absolute",
      top: "calc(50% - 20px)",
      left: "50%",
      transform: "translate(-50%,-50%)",
      width: 40,
      height: 40,
      borderRadius: "50%",
      padding: 10,
      backgroundColor: "#002937",
    },
    "& .design-title": {
      background: "#f6f6f6",
      borderRadius: 20,
      fontWeight: "bold",
    }
  },
  settingTitle: {
    borderBottom: "1px solid #e0e0e0",
    padding: "10px 20px",
    position: "sticky",
    background: "#fff",
    top: 0,
    zIndex: 3,
  },
  settingSaveContainer: {
    float: "right",
    top: "-3px",
    position: "relative",
  },
  settingSaveChangesIndicator: {
    animation: "settingsUnsavedSettingsAnimation 2s ease-out infinite",
  },
  verticalCenterFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  settingLabel: {
    marginLeft: 0,
    color: "black",
    cursor: "pointer",
    display: "inline-block",
  },
  settingInput: {
    margin: 0,
    padding: 0,
    width: "100%",
  },
  styleHeading: {
    fontSize: 18,
    marginBottom: 15,
  },
  designContent: {},
  hide: {
    display: "none",
  },
  colorPresetContainer: {
    width: "calc(100% + 20px)",
    marginRight: - 10,
    marginLeft: - 10,
  },
  colorPresetWrapper: {
    paddingRight: 10,
    paddingLeft: 10,
  },
  colorPreset: {
    backgroundColor: "#f9f9f9",
    borderRadius: "6px",
    padding: "15px 10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    }
  },
  colorPresetName: {
    marginBottom: "10px",
    textAlign: "center",
    fontSize: "1rem",
  },
  colorPresetColors: {
    display: "flex",
    justifyContent: "center",
  },
  colorPresetColorsCircle: {
    width: "30px",
    height: "30px",
    border: "2px solid #fff",
    borderRadius: "50%",
    marginLeft: "-7px",
    marginRight: "-7px",
  },
  settingsPreviewButton: {
    display: "none",
    float: "left",
    position: "fixed",
    bottom: "15px",
    left: "20px",
    zIndex: 3,
  },
}));

// Workaround because of Chrome, since it does not trigger onload event on link elements after
// changing the URL due to a bug from 2008 at least.
const loadDesignCss = (design, callbackError, callbackSuccess) => {
  let link      = document.getElementById("previewcssloader"),
      firstTime = false;
  if ( ! link ) {
    firstTime    = true;
    link         = document.createElement("link");
    link.rel     = "stylesheet";
    link.href    = appUrl + "/" + design + ".css";
    link.id      = "previewcssloader";
    link.onload  = () => {
      if ( callbackSuccess ) callbackSuccess(link);
    };
    link.onerror = () => {
      callbackError();
    };
    document.getElementsByTagName("head")[ 0 ].appendChild(link);
  }

  if ( firstTime ) {
    link.href = appUrl + "/" + design + ".css";
  } else {
    link.href   = appUrl + "/" + design + ".css";
    let img     = document.createElement("img");
    img.onerror = function () {
      if ( callbackSuccess ) callbackSuccess(link);
    };
    img.src     = appUrl + "/" + design + ".css";
  }
};

function Settings({
  settings,
  updateSetting,
  getSetting,
  fetchProducts,
  subscriptions,
}) {
  const classes                                   = useStyles();
  const [ fData, setFdata ]                       = useState(settings || {
    upsells_global_popup_enabled: "",
    upsells_global_popup_title_no_offers: "",
    upsells_global_popup_title_has_offers: "",
    upsells_global_popup_heading_offers: "",
    upsells_global_popup_layout: "",
    upsells_global_branding_enabled: "",
    upsells_global_popup_checkout_button_enabled: "",
    upsells_global_popup_show_price_with_tax: "",
    upsells_global_popup_show_qty_dropdown: "",
    upsells_color_neutral_color: "",
    upsells_color_heading_color: "",
    upsells_color_primary_fill: "",
    upsells_color_secondary_fill: "",
    upsells_color_offer_background_color: "",
    upsells_recommendations_exclude_variant_products: "",
    upsells_custom_content_type: "",
  });
  const [ crpopupIsLoading, setCrpopupisLoading ] = useState(true);
  const [ loadError, setLoadError ]               = useState(false);
  const [ previousDesign, setPreviousDesign ]     = useState(null);
  const [ product, setProduct ]                   = useState({});
  const [ offers, setOffers ]                     = useState([]);
  const [ previewOpen, setPreviewOpen ]           = useState(false);
  const [ isMobile, setIsMobile ]                 = useState(window.innerWidth < 1024);

  const settingsToSectionsMap = {
    "upsells_global_popup_layout": "app_design",
    "upsells_global_popup_enabled": "global_popup",
    "upsells_global_popup_title_no_offers": "global_popup",
    "upsells_global_popup_title_has_offers": "global_popup",
    "upsells_global_popup_heading_offers": "global_popup",
    "upsells_global_branding_enabled": "global_popup",
    "upsells_global_popup_checkout_button_enabled": "global_popup",
    "upsells_global_popup_show_price_with_tax": "global_popup",
    "upsells_global_popup_show_qty_dropdown": "global_popup",
    "upsells_color_neutral_color": "colors",
    "upsells_color_heading_color": "colors",
    "upsells_color_primary_fill": "colors",
    "upsells_color_secondary_fill": "colors",
    "upsells_color_offer_background_color": "colors",
    "upsells_recommendations_exclude_variant_products": "recommendations",
    "upsells_custom_content_type": "dataSync",
  };

  const [ settingsHasChanges, setSettingsHasChanges ] = useState({
    app_design: false,
    global_popup: false,
    colors: false,
    recommendations: false,
    dataSync: false,
  });

  /* Check if in trial, and calculate remaining days in trial. */
  let isInTrial = false;
  Object.keys(subscriptions)
    .forEach((key) => {
      let subscription = subscriptions[ key ];
      if ( subscription[ "is_in_trial" ] === true ) {
        isInTrial = subscription[ "is_in_trial" ];
      }
    });

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  useEffect(
    () => {
      if ( ! settings ) {
        getSetting()
          .then(res => {
            if ( ! res.body ) {
              notification({
                isOpen: true,
                type: "error",
                messageText: res.message || "Unknown error.",
              });
            }
          });
      }
    }, [ settings, getSetting ],
  );

  useEffect(
    () => {
      fetchProducts({}, {
        query: {
          limit: 4,
          orderby: "random"
        }
      })
        .then((res) => {
          if ( res.body && Array.isArray(res.body) && res.body.length > 0 ) {
            let product = res.body,
                offers  = product.splice(1);

            if ( offers[ 0 ] ) {
              offers[ 0 ].description  = "This one is better!";
              offers[ 0 ].offer_intent = "upsell";
            }

            if ( offers[ 1 ] ) {
              offers[ 1 ].description       = "I'm a preorder!";
              offers[ 1 ].is_preorder       = true;
              offers[ 1 ].preorder_quantity = 2;
              offers[ 1 ].preorder_arrival  = (+ new Date()) + (60 * 60 * 24 * 3);
            }

            offers.forEach((offer, i) => {
              if ( ! offer.name ) {
                offers[ i ].name = 'Example title';
              }
            })

            setProduct(product[ 0 ]);
            setOffers(offers);
          }
        });
    }, [ fetchProducts ],
  );

  useEffect(
    () => {
      if ( settings ) {
        loadDesignCss(settings.upsells_global_popup_layout, showLoadError, animatePopup);
        setPreviousDesign(settings.upsells_global_popup_layout);
        setFdata(settings);
      }
    }, [ settings ],
  );

  const {
          upsells_global_popup_layout,
          upsells_global_popup_enabled,
          upsells_global_popup_title_no_offers,
          upsells_global_popup_title_has_offers,
          upsells_global_popup_heading_offers,
          upsells_global_branding_enabled,
          upsells_global_popup_checkout_button_enabled,
          upsells_global_popup_show_price_with_tax,
          upsells_backorder_enabled,
          upsells_global_popup_show_qty_dropdown,
          upsells_color_neutral_color,
          upsells_color_heading_color,
          upsells_color_primary_fill,
          upsells_color_secondary_fill,
          upsells_color_offer_background_color,
          upsells_recommendations_exclude_variant_products,
          upsells_custom_content_type,
        } = fData;

  const handleSave = () => {
    return new Promise( ( resolve, reject ) => {
      let settingsToSave = {
        ...fData, ...{
          upsells_global_popup_enabled: upsells_global_popup_enabled === undefined ? false : upsells_global_popup_enabled,
        }
      };
      delete settingsToSave.siteUrl;
      delete settingsToSave._product_sync_count;
      updateSetting( settingsToSave )
          .then( ( res ) => {
            if ( res.body && res.body.status === "success" ) {
              // Reset setting indicator.
              let tmp_settingsHasChanges = {};
              Object.keys( settingsHasChanges )
                  .forEach( ( key ) => {
                    tmp_settingsHasChanges[ key ] = false;
                  } );
              // noinspection JSCheckFunctionSignatures
              setSettingsHasChanges( tmp_settingsHasChanges );
              notification( {
                isOpen: true,
                type: "success",
                messageText: "Settings updated",
              } );
              resolve( true );
            } else {
              notification( {
                isOpen: true,
                type: "error",
                messageText: res.body.message,
              } );
              reject();
            }
          } )
          .catch( () => {
            notification( {
              isOpen: true,
              type: "error",
              messageText: "Settings save error",
            } );
            reject();
          } );
    } )
  };

  const handleDesignChange = (e) => {
    let currentDesign = e.currentTarget.dataset.value;
    if ( previousDesign !== currentDesign ) {
      if ( (previousDesign === "popup" || previousDesign.indexOf("popup-") !== - 1) && currentDesign === "parent-popup" ) {
        return;
      }

      currentDesign = currentDesign === "parent-popup" ? "popup" : currentDesign;

      setCrpopupisLoading(true);
      window.setTimeout(() => {
        loadDesignCss(currentDesign, showLoadError, animatePopup);
      }, 250);
      setPreviousDesign(currentDesign);
      setFdata({
        ...fData,
        upsells_global_popup_layout: currentDesign
      });
      let tmp_settingsHasChanges                                                       = settingsHasChanges;
      tmp_settingsHasChanges[ settingsToSectionsMap[ "upsells_global_popup_layout" ] ] = settings.upsells_global_popup_layout !== currentDesign;
      setSettingsHasChanges(tmp_settingsHasChanges);
    }
  };

  const handleSwitchChange = (event) => {
    setFdata({
      ...fData,
      [ event.target.name ]: event.target.checked
    });
    let tmp_settingsHasChanges                                           = settingsHasChanges;
    tmp_settingsHasChanges[ settingsToSectionsMap[ event.target.name ] ] = settings[ event.target.name ] !== event.target.checked;
    setSettingsHasChanges(tmp_settingsHasChanges);
  };

  const showPreview = () => {
    setPreviewOpen(true);
  };

  const hidePreview = () => {
    setPreviewOpen(false);
  };

  const handleInputChange = (event) => {
    setFdata({
      ...fData,
      [ event.target.name ]: event.target.value
    });
    let tmp_settingsHasChanges                                           = settingsHasChanges;
    tmp_settingsHasChanges[ settingsToSectionsMap[ event.target.name ] ] = settings[ event.target.name ] !== event.target.value;
    setSettingsHasChanges(tmp_settingsHasChanges);
  };

  const handleColorChange = (data) => {
    setFdata({
      ...fData,
      [ data.id ]: data.value
    });
    let tmp_settingsHasChanges                                 = settingsHasChanges;
    tmp_settingsHasChanges[ settingsToSectionsMap[ data.id ] ] = settings[ data.id ] !== data.value;
    setSettingsHasChanges(tmp_settingsHasChanges);
  };

  const handleColorPresetClick = (index) => {
    let colors           = colorPresets[ index ].colors;
    let newColorSettings = {};
    Object.keys(colors)
      .forEach((key) => {
        newColorSettings = {
          ...newColorSettings,
          [ key ]: colors[ key ]
        };
      });
    setFdata({
      ...fData,
      ...newColorSettings,
    });
    setSettingsHasChanges({ ...settingsHasChanges, ...{ colors: true } });
  };

  const animatePopup = () => {
    setLoadError(false);
    setCrpopupisLoading(false);
  };

  const showLoadError = () => {
    setLoadError(true);
  };

  let colorPresets = [
    {
      "name": "Default Theme",
      "colors": {
        "upsells_color_neutral_color": "#374151",
        "upsells_color_heading_color": "#1F2937",
        "upsells_color_primary_fill": "#52a317",
        "upsells_color_secondary_fill": "#7123e2",
        "upsells_color_offer_background_color": "#f7f3fb",
      }
    },
    {
      "name": "Banana",
      "colors": {
        "upsells_color_neutral_color": "#e2d929",
        "upsells_color_heading_color": "#7c4e28",
        "upsells_color_primary_fill": "#d4c213",
        "upsells_color_secondary_fill": "#d6ac2b",
        "upsells_color_offer_background_color": "#f7f3fb",
      }
    }
  ];

  return (
    <Grid container className={clsx(classes.root)} item xs={12} sm={12} spacing={3}>
      <Grid item sm={loadError ? 9 : 6} md={loadError ? 9 : 6} lg={loadError ? 9 : 5}
        xl={loadError ? 9 : 4} className={clsx(classes.root, classes.scroll)}>
        <BeforeUnloadComponent blockRoute={Object.values(settingsHasChanges)
          .some(v => v)}/>
        <Grid item xs={12}>
          <Card className={clsx(classes.card)}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" component="h2" className={classes.settingTitle}>
                Cart Design
                <div className={classes.settingSaveContainer}>
                  <Button onClick={handleSave}
                    className={settingsHasChanges.app_design ? classes.settingSaveChangesIndicator : null}
                    variant="contained"
                    color="primary">Save</Button>
                </div>
              </Typography>
              <Grid className={classes.settingContent} container>
                <Typography variant="h5" component="h5" className={classes.styleHeading}>Choose
                  Design</Typography>
                <Grid container className={classes.designContent}>
                  <Grid item xs onClick={handleDesignChange}
                    className={clsx(classes.designPicker, upsells_global_popup_layout === "slideout" ? classes.designPickerActive : null)}
                    data-value="slideout">
                    <Grid item container direction="column">
                      <Grid item>
                        <img alt="slideout" src={slideoutImage}/>
                      </Grid>
                      <Grid item className="design-title">
                        Slideout
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs onClick={handleDesignChange}
                    className={clsx(classes.designPicker, upsells_global_popup_layout === "popup" || upsells_global_popup_layout === "popup-2" ? classes.designPickerActive : null)}
                    data-value="parent-popup">
                    <Grid item container direction="column">
                      <Grid item>
                        <img alt="popup" src={popupImage}/>
                      </Grid>
                      <Grid item className="design-title">
                        Popup
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {upsells_global_popup_layout === "popup" || upsells_global_popup_layout === "popup-2" ?
                  <>
                    <Typography variant="h5" component="h5" className={classes.styleHeading}>Choose
                      Style</Typography>
                    <Grid container className={classes.designContent}>
                      <Grid item xs onClick={handleDesignChange}
                        className={clsx(classes.designPicker, upsells_global_popup_layout === "popup" ? classes.designPickerActive : null)}
                        data-value="popup">
                        <Grid item container direction="column">
                          <Grid item>
                            <img alt="popup" src={popup1Image}/>
                          </Grid>
                          <Grid item className="design-title">
                            Style 1
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs onClick={handleDesignChange}
                        className={clsx(classes.designPicker, upsells_global_popup_layout === "popup-2" ? classes.designPickerActive : null)}
                        data-value="popup-2">
                        <Grid item container direction="column">
                          <Grid item>
                            <img alt="popup-2" src={popup2Image}/>
                          </Grid>
                          <Grid item className="design-title">
                            Style 2
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </> : null}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={clsx(classes.card)}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" component="h2" className={classes.settingTitle}>
                Colours
                <div className={classes.settingSaveContainer}>
                  <Button onClick={handleSave}
                    className={settingsHasChanges.colors ? classes.settingSaveChangesIndicator : null}
                    variant="contained"
                    color="primary">Save</Button>
                </div>
              </Typography>
              <Grid container className={classes.settingContent}>
                {colorPresets.length &&
                <h3 className={classes.settingsSubTitle}>Choose a preset</h3>}
                <Grid container
                  className={clsx(classes.settingItemContainer, classes.colorPresetContainer)}>
                  {colorPresets.map((preset, i) => {
                    return (
                      <Grid onClick={() => {
                        handleColorPresetClick(i);
                      }} item xs={6}
                        className={classes.colorPresetWrapper}>
                        <div className={classes.colorPreset}>
                          <div className={classes.colorPresetName}>
                            {preset.name}
                          </div>
                          <div className={classes.colorPresetColors}>
                            {Object.keys(preset.colors)
                              .map((key) => {
                                return (
                                  <div className={classes.colorPresetColorsCircle}
                                    style={{ backgroundColor: preset.colors[ key ] }}>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
                {colorPresets.length &&
                <h3 className={classes.settingsSubTitle}>Or create your own style...</h3>}
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={8} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_color_neutral_color"
                      className={classes.settingLabel}
                    >Neutral Color</InputLabel>
                  </Grid>
                  <Grid item xs={4}>
                    {upsells_color_neutral_color ?
                      <ColorPicker hex={upsells_color_neutral_color}
                        id="upsells_color_neutral_color"
                        updateSetting={handleColorChange}/> : null}
                  </Grid>
                  <Grid container className={classes.settingItemContainer}>
                    <Grid item xs={8} className={classes.verticalCenterFlex}>
                      <InputLabel
                        htmlFor="upsells_color_heading_color"
                        className={classes.settingLabel}
                      >Heading Color</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      {upsells_color_heading_color ?
                        <ColorPicker hex={upsells_color_heading_color}
                          id="upsells_color_heading_color"
                          updateSetting={handleColorChange}/> : null}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.settingItemContainer}>
                    <Grid item xs={8} className={classes.verticalCenterFlex}>
                      <InputLabel
                        htmlFor="upsells_color_primary_fill"
                        className={classes.settingLabel}
                      >Primary Fill</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      {upsells_color_primary_fill ?
                        <ColorPicker hex={upsells_color_primary_fill}
                          id="upsells_color_primary_fill"
                          updateSetting={handleColorChange}/> : null}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.settingItemContainer}>
                    <Grid item xs={8} className={classes.verticalCenterFlex}>
                      <InputLabel
                        htmlFor="upsells_color_secondary_fill"
                        className={classes.settingLabel}
                      >Secondary Fill</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      {upsells_color_secondary_fill ?
                        <ColorPicker hex={upsells_color_secondary_fill}
                          id="upsells_color_secondary_fill"
                          updateSetting={handleColorChange}/> : null}
                    </Grid>
                  </Grid>
                  {upsells_global_popup_layout === "slideout" || upsells_global_popup_layout === "popup-2" ?
                    <Grid container className={classes.settingItemContainer}>
                      <Grid item xs={8} className={classes.verticalCenterFlex}>
                        <InputLabel
                          htmlFor="upsells_color_offer_background_color"
                          className={classes.settingLabel}
                        >Offer Background</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        {upsells_color_offer_background_color ?
                          <ColorPicker hex={upsells_color_offer_background_color}
                            id="upsells_color_offer_background_color"
                            updateSetting={handleColorChange}/> : null}
                      </Grid>
                    </Grid>
                    : null
                  }
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={clsx(classes.card)}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" component="h2" className={classes.settingTitle}>
                Global Popup
                <div className={classes.settingSaveContainer}>
                  <Button onClick={handleSave}
                    className={settingsHasChanges.global_popup ? classes.settingSaveChangesIndicator : null}
                    variant="contained"
                    color="primary">Save</Button>
                </div>
              </Typography>
              <Grid container className={classes.settingContent}>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_enabled"
                      className={classes.settingLabel}
                    >Enabled</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_global_popup_enabled || false}
                      onChange={handleSwitchChange}
                      color="primary"
                      id="upsells_global_popup_enabled"
                      name="upsells_global_popup_enabled"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_title_has_offers"
                      className={classes.settingLabel}
                    >Default Title</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="normal"
                      required
                      value={upsells_global_popup_title_has_offers}
                      id="upsells_global_popup_title_has_offers"
                      name="upsells_global_popup_title_has_offers"
                      onChange={handleInputChange}
                      autoComplete="upsells_global_popup_title_has_offers"
                      className={classes.settingInput}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_title_no_offers"
                      className={classes.settingLabel}
                    >No Offers Title</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      size="small"
                      value={upsells_global_popup_title_no_offers}
                      id="upsells_global_popup_title_no_offers"
                      name="upsells_global_popup_title_no_offers"
                      onChange={handleInputChange}
                      autoComplete="upsells_global_popup_title_no_offers"
                      className={classes.settingInput}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_heading_offers"
                      className={classes.settingLabel}
                    >Offers Heading</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      size="small"
                      value={upsells_global_popup_heading_offers}
                      id="upsells_global_popup_heading_offers"
                      name="upsells_global_popup_heading_offers"
                      onChange={handleInputChange}
                      autoComplete="upsells_global_popup_heading_offers"
                      className={classes.settingInput}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_branding_enabled"
                      className={classes.settingLabel}
                    >
                      Branding enabled
                    </InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_global_branding_enabled || false}
                      onChange={handleSwitchChange}
                      disabled={isInTrial}
                      color="primary"
                      id="upsells_global_branding_enabled"
                      name="upsells_global_branding_enabled"
                    />
                    {isInTrial &&
                    <Tooltip
                      style={{
                        padding: "0 5px",
                        verticalAlign: "middle",
                      }}
                      enterTouchDelay={10}
                      title="This setting will become enabled once you purchase the app.">
                      <IconButton>ⓘ</IconButton>
                    </Tooltip>
                    }
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_checkout_button_enabled"
                      className={classes.settingLabel}
                    >
                      Checkout button enabled
                    </InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_global_popup_checkout_button_enabled || false}
                      onChange={handleSwitchChange}
                      color="primary"
                      id="upsells_global_popup_checkout_button_enabled"
                      name="upsells_global_popup_checkout_button_enabled"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_show_price_with_tax"
                      className={classes.settingLabel}
                    >Show prices with tax</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_global_popup_show_price_with_tax || false}
                      onChange={handleSwitchChange}
                      color="primary"
                      id="upsells_global_popup_show_price_with_tax"
                      name="upsells_global_popup_show_price_with_tax"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_backorder_enabled"
                      className={classes.settingLabel}
                    >Show out of stock</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_backorder_enabled || false}
                      onChange={handleSwitchChange}
                      color="primary"
                      id="upsells_backorder_enabled"
                      name="upsells_backorder_enabled"
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_global_popup_show_qty_dropdown"
                      className={classes.settingLabel}
                    >Show quantity dropdown</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_global_popup_show_qty_dropdown || false}
                      onChange={handleSwitchChange}
                      color="primary"
                      id="upsells_global_popup_show_qty_dropdown"
                      name="upsells_global_popup_show_qty_dropdown"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={clsx(classes.card)}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" component="h2" className={classes.settingTitle}>
                Recommendations AI
                <div className={classes.settingSaveContainer}>
                  <Button onClick={handleSave}
                    className={settingsHasChanges.recommendations ? classes.settingSaveChangesIndicator : null}
                    variant="contained"
                    color="primary">Save</Button>
                </div>
              </Typography>
              <Grid container className={classes.settingContent}>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="upsells_recommendations_exclude_variant_products"
                      className={classes.settingLabel}
                    >Exclude Variant Products</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Switch
                      checked={upsells_recommendations_exclude_variant_products || false}
                      onChange={handleSwitchChange}
                      color="primary"
                      id="upsells_recommendations_exclude_variant_products"
                      name="upsells_recommendations_exclude_variant_products"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={clsx(classes.card)}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" component="h2" className={classes.settingTitle}>
                Data synchronization
                {settingsHasChanges.dataSync && <div className={classes.settingSaveContainer}>
                  <Button onClick={()=>{
                        handleSave().then(()=>{
                          document.getElementById('sync_button').click();
                          notification( {
                            isOpen: true,
                            type: "info",
                            duration: 10000,
                            messageText: "Background data sync is in progress. You can stay on this page to watch the current progress, or you can close this page and the sync will run in the background. This might take a while. Custom Content will not work until the sync is completed.",
                          } );
                        })
                      }}
                      className={classes.settingSaveChangesIndicator}
                      variant="contained"
                      color="primary">Save & Sync</Button>
                </div>}
              </Typography>
              <Grid container className={classes.settingContent}>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                        htmlFor="upsells_custom_content_type"
                        className={classes.settingLabel}
                    >Additional Content Type ID to sync</InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        size="small"
                        type="text"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={upsells_custom_content_type}
                        id="upsells_custom_content_type"
                        name="upsells_custom_content_type"
                        onChange={handleInputChange}
                        autoComplete="upsells_custom_content_type"
                        className={classes.settingInput}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="_product_sync_count"
                      className={classes.settingLabel}
                    >
                      Products count
                    </InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h6" component="h5" id={"_product_sync_count"}><NumberFormat displayType={"text"}
                      thousandSeparator="," value={settings._product_sync_count}/></Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <Grid item xs={5} className={classes.verticalCenterFlex}>
                    <InputLabel
                      htmlFor="_product_manual_sync"
                      className={classes.settingLabel}
                    >
                      Manual trigger
                    </InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <SyncButton id={"_product_manual_sync"} fetcher={() => fetchProducts({}, {
                      query: {
                        page: 1,
                        limit: 10
                      }
                    })}/>
                  </Grid>
                </Grid>
                <Grid container className={classes.settingItemContainer}>
                  <p><i>Data synchronization happens automatically in the background, but you can also trigger it
                    manually by clicking on the button above.</i></p>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Button
          onClick={showPreview}
          className={clsx(classes.settingsPreviewButton)}
          style={{
            display: isMobile ? "flex" : "none",
          }}
          variant="contained"
          color="secondary">Preview
        </Button>
      </Grid>
      {! isMobile || (isMobile && previewOpen) ?
        <Grid item
          className={clsx(classes.root, isMobile ? classes.rootMobile : null)}
          sm={loadError ? 3 : 6}
          md={loadError ? 3 : 6}
          lg={loadError ? 3 : 7}
          xl={loadError ? 3 : 8}>
          <Container className={clsx(classes.container, isMobile ? classes.containerMobile : null)}>
            {loadError ?
              <div>Error loading preview. You can still make changes to the settings on the
                left.</div> : null}
            {crpopupIsLoading ? <CircularProgress/> : null}
            {<PopupPreview settings={fData} isLoading={crpopupIsLoading} product={product}
              offers={offers} popupCloseCallback={hidePreview}/>}
          </Container>
        </Grid>
        : null}
    </Grid>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
  return {
    settings: state.reducerSettings.item || false,
    products: state.reducerProducts.items || false,
    subscriptions: state.reducerSubscriptions.item || {},
    isSettingsFetching: state.reducerSettings.isFetchingItem,
    isSettingsUpdating: state.reducerSettings.isUpdating,
  };
};

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
    ...productActions,
    ...subscriptionsActions,
  },
)(Settings);
