import React from "react";
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import PublicRoutes from "./router";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PublicRoutes history={history} />
      </Provider>
    </div>
  );
}

export default App;
