import { defaultGlobals } from 'redux-rest-resource';
import { clearAuthData } from '../authStorage';
import { fetch } from 'whatwg-fetch';

const PATH_THROUGH_REQUEST_REGEXP = /\/(auth-token-refresh|login|forgot-password|reset-password|session)/i;

Object.assign(defaultGlobals, {
  fetch: (...args) =>
    fetch.apply(this, args).then(response => {
      if (response.status !== 401 || PATH_THROUGH_REQUEST_REGEXP.test(response.url)) {
        return response;
      }
      clearAuthData();
      document.location.href = '/signin';
      return Promise.reject(response);
    })
});
