import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "upsell",
  url: `${apiUrl}/upsells/:id`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: res => ({
        body: res.body ? res.body.upsells : [],
        message: res.body.message,
        resourceName: "upsells",
        pagination: {
          currentPageNumber: res.body.currentPage,
          totalPages: res.body.totalPages || false,
          numItemsPerPage: res.body.itemsPerPage || false,
        },
        stats: res.body.stats || false,
      }),
    },
    get: {
      transformResponse: res => ({ body: res.body.upsell }),
    },
    create: {
      assignResponse: false,
      method: "POST",
    },
    update: {
      assignResponse: false,
      method: "PUT",
    },
    delete: {
      assignResponse: false,
      method: "DELETE",
    },
    clear: {
      isPure: true,
      reduce: state => ({ ...state, item: null }),
    },
  },
});
