import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import clsx from "clsx";
import { Clear, ErrorOutline } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  popupBackground: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    background: "rgba(0,0,0,0.3)",
  },
  popup: {
    position: "fixed",
    width: "101%",
    height: "100%",
    maxWidth: 600,
    maxHeight: 300,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    border: "1px solid #ccc",
  },
  popupBody: {
    position: "absolute",
    top: "50%",
    padding: 20,
    transform: "translateY(-50%)",
    width: "100%",
  },
  center: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "#434343",
    cursor: "pointer",
  }
}));

export default function RecommendationsLoaderPopup({
  isFetching,
  fetchError = false,
  setFetchError,
  displayFrom: from,
  displayTo: to,
  displaySearch: search,
  cancelRequest,
  isConvertPopup = false,
}) {
  const classes                   = useStyles();
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    setShowPopup(isFetching || fetchError);
  }, [isFetching, fetchError]);

  return (showPopup && <div className={classes.popupBackground}>
    <div className={classes.popup}>
      {fetchError && <Clear className={classes.closeButton} onClick={() => {
        setFetchError("");
      }}/>}
      <div className={clsx(classes.popupBody, classes.center)}>
        {fetchError ?
          // Show on error.
          <div>
            <div>
              <ErrorOutline style={{
                color: "#cc0000",
                width: 40,
                height: 40
              }}/>
            </div>
            <h2 style={{
              marginTop: 5,
              marginBottom: 5,
            }}>There was an error.</h2>
            <div style={{
              fontSize: 10,
              color: "#757575",
            }}>Error message: {fetchError}</div>
          </div> :
          // Show on success.
          <div>
            <div>
              <CircularProgress/>
            </div>
            <h2 style={{
              marginTop: 10,
              marginBottom: 5,
            }}>
              {isConvertPopup ? "Converting Recommendations" : "Calculating Recommendations"}
            </h2>

            {isConvertPopup ?
              <p>Please wait...</p> :
              <p>Calculating recommendations for the period
              from {format(from, "LLL d, y")} to {format(to, "LLL d, y")}{search ? ", with keyword \"" + search + "\"" : ""}</p>}

            {isConvertPopup ? null : <Button onClick={() => {
              cancelRequest();
              setShowPopup(false);
            }}>Cancel</Button>}
          </div>
        }
      </div>
    </div>
  </div>);
}
