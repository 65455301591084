import React from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ProductSearchModal from "./ProductSearchModal";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  title: {
    // marginBottom: theme.spacing(2),
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
    padding: theme.spacing(2, 4, 0),
    paddingLeft: "8px",
  },
  modal: {
    border: "none !important",
    boxShadow: "unset !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 0",
  },
  paper: {
    position: "relative",
    width: "100%",
    maxWidth: "600px",
    backgroundColor: theme.palette.background.paper,
    border: "none !important",
    outline: "none !important",
    padding: "0 24px 0 24px",
    marginTop: "auto",
    marginBottom: "auto",
    height: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[ 500 ],
  },
  text: {
    paddingLeft: "8px",
    fontSize: 15,
  },
}));

function UpsellTargetModal({
  isOpen,
  isMobile,
  onClose,
  onCancel,
  product_target,
  onRemove,
  onSelect,
  canSelectMultiple,
}) {
  const classes = useStyles();
  return (
    <Modal
      className={clsx(classes.modal, "products-modal")}
      disableEnforceFocus
      open={isOpen}
      onClose={onCancel}
    >
      <Paper className={clsx(classes.paper, "products-popup")}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom
          className={classes.title}>
          Select product
          <IconButton aria-label="close" className={classes.closeButton}
            onClick={onCancel}>
            <CloseIcon/>
          </IconButton>
        </Typography>
        <Typography className={classes.text}>Choose product</Typography>
        <ProductSearchModal
          dispatchSelected={onSelect}
          product_target={product_target}
          removeHander={onRemove}
          setIsOpened={onClose}
          isMobile={isMobile}
          upsell_products={[]}
          canSelectMultiple={canSelectMultiple}
          onCancel={onCancel}
          calledFromDisplayFor={true}
        />
      </Paper>
    </Modal>
  );
}

export default UpsellTargetModal;
