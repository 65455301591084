// eslint-disable-next-line no-unused-vars
const isLocal       = /localhost/i.test(document.location.hostname) || process.env.NODE_ENV === "local";
// eslint-disable-next-line no-unused-vars
const isStaging = /dev\.app/i.test(document.location.hostname) || process.env.NODE_ENV === "staging";
// eslint-disable-next-line no-unused-vars
const localCfg      = {
  apiUrl: "https://dev.api.outsmart.digital",
  appUrl: "https://dev.app.outsmart.digital",
  useTrackingScripts: false,
};
// eslint-disable-next-line no-unused-vars
const stagingCfg        = {
  apiUrl: "https://dev.api.outsmart.digital",
  appUrl: "https://dev.app.outsmart.digital",
  useTrackingScripts: false,
};
// eslint-disable-next-line no-unused-vars
const prodCfg       = {
  apiUrl: "https://api.outsmart.digital",
  appUrl: "https://app.outsmart.digital",
  useTrackingScripts: true,
};

const {
        apiUrl,
        appUrl,
        useTrackingScripts,
        // eslint-disable-next-line no-nested-ternary
      } = isLocal ? localCfg
  : isStaging
    ? stagingCfg
    : prodCfg;

export {
  apiUrl,
  appUrl,
  isLocal,
  isStaging,
  useTrackingScripts,
};
