import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "item",
  url: `${apiUrl}/content`,
  actions: {
    fetch: {
      transformResponse: (res) => ({
        body: res.body ? res.body.items : [],
        resourceName: "item",
        pagination: {
          currentPageNumber: res.body.currentPage,
          totalPages: res.body.totalPages || false,
          numItemsPerPage: res.body.itemsPerPage || false,
          itemCount: res.body.itemCount || false,
        },
      }),
    },
  },
});
