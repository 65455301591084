import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "setting",
  url: `${apiUrl}/settings`,

  actions: {
    get: {
      transformResponse: res => ({
        body: res.body.settings ? res.body.settings : false,
        message: res.body.message ? res.body.message : null,
      }),
    },
    update: {
      method: "PUT",
      transformResponse: (res) => ({
        body: res.body,
      }),
    },
  },
});
