import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { apiUrl, isLocal, isStaging } from "../../../config/config";
import clsx from "clsx";
import { defaultHeaders } from "redux-rest-resource";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { actions as upsellsActions } from "../../../redux/reducers/upsell.resource";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { confirm } from "../Components/confirm";
import notification from "../Components/notification";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import StatisticBlock from "../Components/StatsBlock/StatisticBlock";
import ErrorPage from "./errors/ErrorPage";
import {
  ArrowDownward,
  ArrowUpward, Cached,
  Clear,
  CloudDownload,
  CloudUpload,
  InfoOutlined,
  ScheduleOutlined,
  VisibilityOffOutlined
} from "@material-ui/icons";
import "whatwg-fetch";
import UpsellsListMobile from "../Components/UpsellsListMobile";
import UpsellsImportPopup from "../Components/UpsellComponents/UpsellsImportPopup";
import { useCookies } from "react-cookie";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { Alert } from "@material-ui/lab";

let timeout       = false;
let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  pointerToCell: {
    cursor: "pointer",
  },
  pg: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  limit: {
    height: 22,
    padding: 2,
    width: 50,
    marginLeft: 5,
    border: "1px solid #c4c4c4",
    background: "#fff",
    appearance: "none",
    borderRadius: "0.25rem",
    boxShadow: "inset 0 1px 2px rgba(28,29,34,.075)",
  },
  form: {
    float: "left",
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  tableControls: {
    marginLeft: "auto",
    display: "flex",
    flexFlow: "row",
    flexWrap: "nowrap",
    maxWidth: "100px",
    alignItems: "center",
    justifyContent: "space-around"
  },
  margin: {
    marginRight: theme.spacing(3),
  },
  minWidth200: {
    minWidth: "200px",
    maxWidth: "200px",
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  loader: {
    width: "100%",
    display: "block",
    margin: "3rem auto",
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const StyledTableCell = withStyles(() => ({
  head: {
    fontWeight: 600,
  },
}))(TableCell);

function Upsells({
  upsells,
  fetchUpsells,
  pgData,
  deleteUpsell,
  statData,
  isUpsellsFetching,
}) {
  let cookie_order   = null,
      cookie_orderby = null,
      cookie_search = null,
      cookie_status = null;
  if ( document.cookie.split("; ")
    .find(row => row.startsWith("ocu_sort_upsells=")) && document.cookie.split("; ")
    .find(row => row.startsWith("ocu_sort_upsells_direction=")) ) {

    cookie_orderby = document.cookie.split("; ")
      .find(row => row.startsWith("ocu_sort_upsells="))
      .split("=")[ 1 ];
    cookie_order   = document.cookie.split("; ")
      .find(row => row.startsWith("ocu_sort_upsells_direction="))
      .split("=")[ 1 ];
  }

  if ( document.cookie.split("; ").find(row => row.startsWith("ocu_search_upsell="))){
    cookie_search = document.cookie.split("; ").find(row => row.startsWith("ocu_search_upsell=")).split("=")[1];
  }
  if ( document.cookie.split("; ").find(row => row.startsWith("ocu_status_upsell="))){
    cookie_status = document.cookie.split("; ").find(row => row.startsWith("ocu_status_upsell=")).split("=")[1];
  }
  const [sortData, setSortData] = useState({
    order: cookie_order ? cookie_order : "desc",
    orderby: cookie_orderby ? cookie_orderby : "revenue",
  });

  const [fetchError, setFetchError]             = useState({
    error: false,
    message: ""
  });
  const [checked, setChecked]                   = useState([]);
  const [activeInActionId, setActiveInActionId] = useState([]);
  const [stateUpsells, setStateUpsells]         = useState([]);
  const [search, setSearch]                     = useState("");
  const [status, setStatus]                     = useState(cookie_status ? cookie_status : "all");
  const [searchString, setSearchString]         = useState(cookie_search ? cookie_search : "");
  const [page, setPage]                         = useState(1);
  const [importPopupOpen, setImportPopupOpen]   = useState(false);
  const [isMobile, setIsMobile]                 = useState(window.innerWidth < 1024);
  const [cookiesLimit, setCookieLimit]          = useCookies(["ocu_limit_upsells"]);
  const [limit, setLimit]                       = useState(cookiesLimit.ocu_limit_upsells || 10);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isExportsDataReady, setIsExportsDataReady] = useState(false);
  const [exportFileError, setExportFileError] = useState('');
  const [exports, setExports] = useState([]);

  const options = [
    {
      value: "id",
      label: "ID"
    },
    {
      value: "name",
      label: "Name"
    },
    {
      value: "active",
      label: "Active"
    },
    {
      value: "revenue",
      label: "Revenue"
    },
    {
      value: "add_to_carts",
      label: "Add to carts"
    },
    {
      value: "impressions",
      label: "Impressions"
    },
    {
      value: "conversion_rate",
      label: "Conversion rate"
    },
  ];

  useEffect(() => {
    setStateUpsells(upsells || []);
  }, [upsells, setStateUpsells]);

  useEffect(() => {
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearch(searchString);
      document.cookie = "ocu_search_upsell=" + searchString + ";";
    }, 500);
  }, [searchString]);

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  const classes = useStyles();

  useEffect(
    () => {
      const query = {};
      if ( page ) {
        query.page = page;
      }
      if ( status !== "all" ) {
        query.status = status;
      }
      if ( sortData.orderby ) {
        query.order   = sortData.order;
        query.orderby = sortData.orderby;
      }
      if ( search && search.trim() !== "" ) {
        query.search = search;
      }
      if ( limit ) {
        query.limit = limit;
        setCookieLimit("ocu_limit_upsells", limit, {
          expires: new Date("December 31, 9999 23:59:59 GMT"),
        });
      }
      fetchUpsells({}, {
        query: {
          ...query,
        }
      })
        .then((res) => {
          if ( res.body === undefined ) {
            setFetchError({
              error: true,
              message: res.message
            });
          }
        });
    }, [fetchUpsells, page, limit, sortData, search, status, setFetchError, setCookieLimit],
  );

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked   = [...checked];

    if ( currentIndex === -1 ) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if ( numberOfChecked(items) === items.length ) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleChangePage = (e, p) => {
    setPage(p);
  };

  const handleChangeLimit = (e) => {
    setLimit(e.currentTarget.value);
  };

  const history = useHistory();

  const sort = (orderby, forceSort = "") => {
    let order = forceSort ? forceSort : (sortData.orderby !== orderby ? "desc" : sortData.order === "asc" ? "desc" : "asc");
    // Set order to requested column, switch order if same column otherwise use "asc".
    setSortData({
      order: order,
      orderby: orderby,
    });
    document.cookie = "ocu_sort_upsells=" + orderby + "; Expires=Fri, 31 Dec 9999 23:59:59 GMT;";
    document.cookie = "ocu_sort_upsells_direction=" + order + ";";
    // Reset the page, good UX.
    setPage(1);
  };

  const handleChange = (e) => {
    setSearchString(e.target.value);
  };

  const handleSortByChangeMobile = (e) => {
    sort(e.target.value);
  };

  const changeItem = (event, upsell) => {
    const index = stateUpsells.findIndex(({ id }) => id === upsell.id);
    if ( index !== -1 ) {
      stateUpsells[ index ] = {
        ...stateUpsells[ index ],
        active: event.target.checked
      };
      setStateUpsells(stateUpsells);
      setActiveInActionId((activeInActionId) => [...activeInActionId, upsell.id]);
    }
  };

  const revertItem = (event, upsell) => {
    const index = stateUpsells.findIndex(({ id }) => id === upsell.id);
    if ( index !== -1 ) {
      stateUpsells[ index ] = {
        ...stateUpsells[ index ],
        active: !event.target.checked
      };
      setStateUpsells(stateUpsells);
    }
  };

  const closeImportPopup = () => {
    setImportPopupOpen(false);
  };

  const handleChangeSwitch = (event, upsell) => {
    changeItem(event, upsell);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", defaultHeaders.Authorization);
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ active: event.target.checked }),
      redirect: "follow",
    };

    fetch(`${apiUrl}/upsells/${upsell.id}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setActiveInActionId((activeInActionId) => activeInActionId.filter(item => item !== upsell.id));
        if ( res && res.status === "success" ) {
          notification({
            isOpen: true,
            type: "success",
            messageText: event.target.checked ? "Upsell activated" : "Upsell deactivated",
          });
        } else {
          revertItem(event, upsell);
          notification({
            isOpen: true,
            type: "error",
            messageText: event.target.checked ? "Upsell activation error" : "Upsell deactivation error",
          });
        }
      })
      .catch(() => {
        revertItem(event, upsell);
        setActiveInActionId((activeInActionId) => activeInActionId.filter(item => item !== upsell.id));
        notification({
          isOpen: true,
          type: "error",
          messageText: event.target.checked ? "Upsell activation error" : "Upsell deactivation error",
        });
      });
  };

  const deleteUpsellHandler = (id, name) => {
    confirm({
      confirmActionText: "Delete",
      cancelActionText: "Cancel",
      confirmAction: () => {
        deleteUpsell(id)
          .then((res) => {
            if (typeof res.body !== "undefined") {
              if ( res.body.status === "success" ) {
                notification({
                  isOpen: true,
                  type: "success",
                  messageText: "Upsell deleted.",
                });
                const query = {};
                if ( page ) {
                  query.page = page;
                }
                if ( limit ) {
                  query.limit = limit;
                }
                if ( sortData.orderby ) {
                  query.order   = sortData.order;
                  query.orderby = sortData.orderby;
                }
                if ( searchString && searchString.trim() !== "" ) {
                  query.search = searchString;
                }
                fetchUpsells({}, {
                  query: {
                    ...query,
                  }
                });
              } else {
                notification({
                  isOpen: true,
                  type: "error",
                  messageText: "There was an error: " + res.body.message,
                });
              }
            } else {
              notification({
                isOpen: true,
                type: "error",
                messageText: "There was an unknown error.",
              });
            }
          })
          .catch((err) => {
            notification({
              isOpen: true,
              type: "error",
              messageText: "There was an error: " + err,
            });
          });
      }, // optional
      cancelAction: () => {
      }, // optional
      text: `Are you sure you want to delete upsell '${name}'?`,
    });
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
    document.cookie = "ocu_status_upsell=" + e.target.value + ";";
  };

  const deleteManyUpsellHandler = () => {
    confirm({
      confirmActionText: "Delete",
      cancelActionText: "Cancel",
      confirmAction: async () => {
        const promises = checked
          .map((item) => new Promise(
            (resolve) => deleteUpsell(item)
              .then(resolve)
            )
          );
        await Promise.all(promises)
          .then(() => {
            notification({
              isOpen: true,
              type: "success",
              messageText: "Upsell deleted",
            });
            setChecked([]);
            const query = {};
            if ( page ) {
              query.page = page;
            }

            if ( limit ) {
              query.limit = limit;
            }

            if ( sortData.orderby ) {
              query.order   = sortData.order;
              query.orderby = sortData.orderby;
            }
            if ( searchString && searchString.trim() !== "" ) {
              query.search = searchString;
            }
            fetchUpsells({}, {
              query: {
                ...query,
              }
            });
          });
      }, // optional
      cancelAction: () => {
      }, // optional
      text: `Are you sure you want to delete upsells?`,
    });
  };

  const clearFilters = () => {
    setStatus("all");
    setSearchString("");
    document.cookie = "ocu_search_upsell=;";
    document.cookie = "ocu_status_upsell=all;";
  };

  const hiddenFileInput = React.useRef(null);

  const handleImportClick = () => {
    try {
      if (window.confirm('The import process requires that the data is filled out from the Import Template which can be downloaded from below the Import button. Are you sure that the data matches the columns in that file?')) {
        hiddenFileInput.current.click();
      }
    } catch (e){ // We don't care about the confirmation too much, the file will error out if it's not valid.
      hiddenFileInput.current.click();
    }
  };

  const handleExportClick = () => {
    setIsOpenExportModal(true);
    reloadExportData();
  }

  const reloadExportData = (doInitialUiUpdate = true)=>{
    if (doInitialUiUpdate) {
      setIsExportsDataReady(false);
      setExportFileError(false);
    }

    if (typeof window.exportCheckerInterval === 'undefined'){
      window.exportCheckerInterval = null;
    }

    fetch( 'https://' + ( isLocal || isStaging ? 'dev.' : '' ) + 'api.outsmart.digital/ocu-exports', {
      headers: new Headers({
        'Authorization': 'Bearer ' + defaultHeaders.Authorization,
      })
    } ).then( res => res.json() ).then( res => {
      if (res.status === 'error') {
        setExportFileError(res.message);
      } else {
        setIsExportsDataReady(true);
        setExports(res.message);

        // Run check for export file update.
        if (window.exportCheckerInterval === null && res.message.filter(item => item.status === 'in_progress').length) {
          window.exportCheckerInterval = window.setInterval(()=>{
            console.log('checking');
            reloadExportData(false);
          }, 2000);
        }

        // Cancel check once the export is done.
        if (window.exportCheckerInterval !== null && res.message.filter(item => item.status === 'in_progress').length === 0){
          window.clearInterval(window.exportCheckerInterval);
          window.exportCheckerInterval = null;
        }
      }
    }).catch(e=>{
      setExportFileError(e.toString());
    });
  }

  const handleInputChange = event => {
    notification({
      isOpen: true,
      type: "info",
      permanent: true,
      messageText: "Import is in progress. Please wait.",
    });

    const fileUploaded = event.target.files[ 0 ];
    const formData     = new FormData();
    formData.append("File", fileUploaded);
    fetch(`${apiUrl}/ocu-import`,
      {
        method: "POST",
        body: formData,
        headers: new Headers({
          "Authorization": defaultHeaders.Authorization
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if ( result.status !== "success" ) {
          notification({
            isOpen: true,
            type: "error",
            permanent: true,
            messageText: "There was an error during import: " + result.message,
          });
        } else {
          notification({
            isOpen: true,
            type: "success",
            permanent: true,
            messageText: result.message,
          });

          sort("id", "desc");
        }
      })
      .catch((error) => {
        notification({
          isOpen: true,
          type: "error",
          permanent: true,
          messageText: "There was an error. (" + error + ")",
        });
      });
  };

  let timeSegments = [
    3.154e10,
    2.628e9,
    6.048e8,
    8.64e7,
    3.6e6,
    60000,
    -Infinity,
  ];

  let makeTimeString = (unit, singularString) => (timeSegment, time) =>
      time >= 2 * timeSegment
          ? `${Math.floor(time / timeSegment)} ${unit}s ago`
          : singularString;

  let timeFunctions = [
    makeTimeString('year', '1 year ago'),
    makeTimeString('month', '1 month ago'),
    makeTimeString('week', '1 week ago'),
    makeTimeString('day', '1 day ago'),
    makeTimeString('hour', 'an hour ago'),
    makeTimeString('minute', 'a minute ago'),
    _ => 'just now',
  ];

  const timeAgoFn = timeStamp => {
    let timeDifference = Date.now() - timeStamp;
    let index = timeSegments.findIndex(time => timeDifference >= time);
    return timeFunctions[index](timeSegments[index], timeDifference);
  }

  function humanFileSize(size) {
    var i = size === '0' || size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  if ( fetchError.error ) return (<ErrorPage message={fetchError.message}/>);
  const items = stateUpsells.map(({ id }) => id);
  return (
    <Container maxWidth="xl">
      {
        importPopupOpen &&
        <UpsellsImportPopup open={importPopupOpen} popupClose={closeImportPopup}
          isMobile={isMobile}/>
      }
      <Modal
          open={isOpenExportModal}
          onClose={()=>{
            setIsOpenExportModal(false);

            // If needed.
            window.clearInterval(window.exportCheckerInterval);
            window.exportCheckerInterval = null;
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '1px solid rgba(0, 0, 0, 0.1215686275)',
          boxShadow: 24,
          p: 2,
          outline: 0,
          borderRadius: 5,
          maxWidth: '100%',
        }}>
          <Button style={{
            position: 'absolute',
            top: 0,
            right: 0,
            maxWidth: '36.5px',
            padding: 0,
            minWidth: '36.5px',
            height: '36.5px',
          }} onClick={()=>{
            setIsOpenExportModal(false);

            // If needed.
            window.clearInterval(window.exportCheckerInterval);
            window.exportCheckerInterval = null;
          }}>X</Button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Exports
          </Typography>
          {exportFileError ? <>
            <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{color: 'red'}}>
              There was an error: {exportFileError}
            </Typography>
          </> : <>
            {isExportsDataReady ? <>
              {exports.filter(item => item.status === 'in_progress').length === 0 ?
                  <Button color="primary" variant="contained"
                      style={{ margin: '10px auto 0', paddingLeft: 10, paddingRight: 10, width: '100%' }}
                      onClick={() => {
                        setIsExportsDataReady(false);
                        fetch('https://' + (isLocal || isStaging ? 'dev.' : '') + 'api.outsmart.digital/ocu-exports', {
                          method: 'POST',
                          headers: new Headers({
                            'Authorization': 'Bearer ' + defaultHeaders.Authorization,
                          })
                        }).then(res => res.json()).then(res => {
                          if (res.status === 'error') {
                            setExportFileError('Could not create an export request: ' + res.message);
                          } else {
                            reloadExportData();
                          }
                        }).catch(e => {
                          setExportFileError(e.toString());
                        });
                      }}>
                    <Cached style={{ marginRight: 5 }}/>Generate New
                  </Button> : <Alert severity={"info"} style={{marginTop: 10}}>There is an export in progress. The export list will refresh once it's done. You may close this window and come back at any time, the export will be at the top of the list.</Alert>}
              {exports.length > 0 && <Table>
                <TableHead>
                  <TableCell width={10}></TableCell>
                  <TableCell>Age</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Link</TableCell>
                </TableHead>
                <TableBody>
                  {exports.map(item => {
                    let fileTime = new Date();
                    fileTime.setTime(parseInt(item.time + "000"));

                    return <TableRow key={item.id}>
                      <TableCell>#{parseInt(item.index) + 1}</TableCell>
                      <TableCell title={fileTime.toISOString()}>{timeAgoFn(item.time + "000")}</TableCell>
                      <TableCell>{humanFileSize(item.size)}</TableCell>
                      <TableCell>{item.status === 'in_progress' ?
                          <>{typeof item.progress !== "undefined" && item.progress !== null ? <div style={{display: 'flex', alignItems: 'center'}}>
                              <LinearProgress value={parseInt(item.progress)} variant={"determinate"} style={{width:'100%', marginRight: 5}}/>
                            <span>{item.progress}%</span>
                          </div>: <Cached style={{ animation: "spin 1s linear infinite" }}/>}</> : <a
                              href={'https://' + (isLocal || isStaging ? 'dev.' : '') + 'api.outsmart.digital/exports/' + item.file}
                              download>Download</a>}</TableCell>
                    </TableRow>;
                  })}
                </TableBody>
              </Table>}
            </> : <LinearProgress style={{ marginTop: 15 }}/>}
          </>}
        </Box>
      </Modal>
      <StatisticBlock clsName="upsells-page-stats" statData={statData} isMobile={isMobile}/>
      <Grid className={classes.root} item xs={12} sm={12}>
        <Typography component="h2" variant="h6" color="secondary" gutterBottom
          className={classes.title} style={{
          display: isMobile ? "inherit" : "flex",
          textAlign: "center",
        }}>
          Your Upsells
          <div style={{
            marginTop: 15,
            display: 'flex',
            marginBottom: isMobile ? 30 : 10,
            flexWrap: isMobile ? 'wrap' : null,
            gap: isMobile ? 10 : null,
            justifyContent: isMobile ? 'space-between' : null,
          }}>
            <Button color="secondary" variant="outlined"
              style={{
                marginRight: isMobile ? null : 20,
                width: isMobile ? 'calc(50% - 10px)' : null,
              }}
              onClick={handleExportClick}>
              <CloudDownload style={{marginRight: '5px'}}/>
              Exports
            </Button>
            <div style={{
              position: 'relative',
              width: isMobile ? 'calc(50% - 10px)' : null,
            }}>
              <Button color="secondary" variant="outlined"
                style={{
                  marginRight: isMobile ? null : 20,
                  width: isMobile ? '100%' : null,
                }}
                onClick={handleImportClick}>
                <CloudUpload style={{ marginRight: "5px" }}/>
                Import
              </Button>
              <a href={"https://docs.outsmart.digital/article/2401"} target={"_blank"} rel={"noreferrer"}
                 style={{position: 'absolute', bottom: -20, left: 0, right: isMobile ? 0 : 20, fontSize: 12, whiteSpace: 'nowrap'}}>Download Template</a>
            </div>
            <input
              style={{ display: "none" }}
              ref={hiddenFileInput}
              type="file"
              onChange={handleInputChange}
            />
            <Button color="secondary" variant="contained" style={{
              order: isMobile ? -1 : null,
              width: isMobile ? '100%' : null,
            }}
              onClick={() => history.push("/upsells/new")}>
              <ControlPointIcon style={{ marginRight: "5px" }}/>
              Create
            </Button>
          </div>
        </Typography>
        <form className={classes.form} style={{
          float: isMobile ? "none" : "",
          padding: isMobile ? 0 : "",
          width: "100%",
        }} noValidate
          autoComplete="off">
          <FormControl size="small" fullWidth variant="outlined"
            className={clsx(classes.margin, classes.minWidth200)}
            style={{
              width: isMobile ? "100%" : "",
              maxWidth: isMobile ? "100%" : 350,
              marginRight: isMobile ? 0 : "",
              background: isMobile ? "white" : "",
            }}>
            <OutlinedInput
              placeholder={"Search Upsells"}
              id="outlined-adornment-amount"
              value={searchString}
              onChange={handleChange}
              startAdornment={<InputAdornment position="start"><SearchIcon
                style={{ opacity: 0.5 }}/></InputAdornment>}
              endAdornment={<InputAdornment position="end"><Tooltip title={"You can search by upsell ID, upsell title, offer or target SKU, as well as offer or target product title."} placement={"top"} arrow><InfoOutlined style={{cursor: 'help', marginRight: 5, opacity: 0.4}}/></Tooltip></InputAdornment>}
            />
          </FormControl>
          {!isMobile && <FormControl size="small" fullWidth variant="outlined"
            className={clsx(classes.margin, classes.minWidth200)}>
            <InputLabel id="select-label">Status</InputLabel>
            <Select label="Status" id="select" labelId="select-label" name="status" value={status}
              onChange={handleStatus}>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Not Active</MenuItem>
            </Select>
          </FormControl>}
          {(searchString !== "" || status !== "all") &&
          <FormControl size="small" fullWidth variant="outlined" className={classes.margin}>
            <Button color="secondary" variant="contained" style={{
              width: "max-content",
            }} onClick={clearFilters}>
              <Clear style={{ marginRight: "5px" }}/>
              Clear
            </Button>
          </FormControl>}
          {checked.length > 0 &&
          <FormControl size="small" variant="outlined"
            style={{
              display: isMobile && "none",
              width: 300,
            }}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={deleteManyUpsellHandler}
            >Delete selected</Button>
          </FormControl>
          }
          <FormControl size="small" variant="outlined" fullWidth
            style={{ display: isMobile && "none", }}>
            <div className={classes.pg}>
              {
                pgData && pgData.totalPages > 1
                && <Pagination
                  count={parseInt(pgData.totalPages)}
                  page={parseInt(pgData.currentPageNumber)}
                  onChange={handleChangePage}
                />
              }
            </div>
          </FormControl>
        </form>

        {isMobile ?
          <div style={{
            display: "flex",
            justifyContent: "flex-start",
          }}>
            <Typography style={{
              alignSelf: "center"
            }} component="h4" variant="h7" color="secondary" gutterBottom>
              <p>Sort by: </p>
            </Typography>
            <form className={classes.form} style={{
              float: isMobile ? "none" : "",
              padding: isMobile ? 0 : "",
            }} noValidate autoComplete="off">
              <FormControl variant="standard" size="small">
                <Select native id="grouped-native-select"
                  IconComponent={() => null}
                  onChange={handleSortByChangeMobile}
                  style={{
                    paddingLeft: 5,
                  }}
                >
                  {options.map((option) => (
                    <option
                      value={option.value}
                      selected={sortData.orderby === option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </form>
            <div style={{
              padding: 5,
              alignSelf: "center"
            }}>
              {
                sortData.order === "asc" ?
                  <ArrowUpward
                    onClick={() => sort(sortData.orderby)}
                    cursor="pointer"
                  />
                  :
                  <ArrowDownward
                    onClick={() => sort(sortData.orderby)}
                    cursor="pointer"
                  />
              }
            </div>
          </div>
          : ""}

        {isMobile ? <UpsellsListMobile
          upsells={stateUpsells}
          isFetching={isUpsellsFetching}
          pagination={pgData}
          paginationHandler={handleChangePage}
        /> : <Paper><Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  color="primary"
                  onClick={handleToggleAll(items)}
                  checked={numberOfChecked(items) === items.length && items.length !== 0}
                  indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                  disabled={items.length === 0}
                  inputProps={{ "aria-label": "all items selected" }}
                />
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortData.orderby === "id"}
                  direction={sortData.orderby === "id" ? sortData.order : "desc"}
                  onClick={() => sort("id")}
                >
                  ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell style={{textAlign: 'center'}}>
                <TableSortLabel
                  active={sortData.orderby === "active"}
                  direction={sortData.orderby === "active" ? sortData.order : "desc"}
                  onClick={() => sort("active")}
                >
                  Active
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortData.orderby === "name"}
                  direction={sortData.orderby === "name" ? sortData.order : "desc"}
                  onClick={() => sort("name")}
                >
                  Upsell Title
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortData.orderby === "revenue"}
                  direction={sortData.orderby === "revenue" ? sortData.order : "desc"}
                  onClick={() => sort("revenue")}
                >
                  Additional Revenue
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortData.orderby === "add_to_carts"}
                  direction={sortData.orderby === "add_to_carts" ? sortData.order : "desc"}
                  onClick={() => sort("add_to_carts")}
                >
                  Add To Carts
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortData.orderby === "impressions"}
                  direction={sortData.orderby === "impressions" ? sortData.order : "desc"}
                  onClick={() => sort("impressions")}
                >
                  Impressions
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortData.orderby === "conversion_rate"}
                  direction={sortData.orderby === "conversion_rate" ? sortData.order : "desc"}
                  onClick={() => sort("conversion_rate")}
                >
                  Conversion rate
                </TableSortLabel>
              </StyledTableCell>
              <TableCell align="right"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {isUpsellsFetching === false && stateUpsells.length === 0 &&
            <TableRow>
              <TableCell colSpan={8}>
                <p style={{textAlign: "center"}}>Please create your first upsell by clicking on the "Add New" button above.</p>
              </TableCell>
            </TableRow>}

            {isUpsellsFetching ?
              <TableRow>
                <TableCell colSpan={8}>
                  <CircularProgress className={classes.loader}/>
                </TableCell>
              </TableRow> : stateUpsells && stateUpsells.map( ( upsell ) => {
              // Find out if the upsell has start and/or end dates, so we can show a clock icon.
              let upsell_start = upsell.upsell_start && upsell.upsell_start !== '0' ? parseInt( upsell.upsell_start ) : null;
              try { // Process into Date object.
                upsell_start = upsell_start ? new Date( parseInt( upsell_start.toString() + '000' ) ) : null;
              } catch ( e ) { // We don't care if it fails, it's an invalid value anyway.
              }
              let upsell_end = upsell.upsell_end && upsell.upsell_end !== '0' ? parseInt( upsell.upsell_end ) : null;
              try { // Process into Date object.
                upsell_end = upsell_end ? new Date( parseInt( upsell_end.toString() + '000' ) ) : null;
              } catch ( e ) { // We don't care if it fails, it's an invalid value anyway.
              }
              let is_scheduled  = upsell_start || upsell_end;
              let schedule_text = null;
              let is_visible = true;

              // For the time controlled upsells - Generate the tooltip text depending on which fields are configured.
              if ( is_scheduled ) {
                const dateTimeFormat = {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: 'numeric',
                  hour12: true,
                };
                if ( upsell_start && upsell_end ) {
                  schedule_text = 'This upsell is configured to display from ' + new Intl.DateTimeFormat( 'en-AU', dateTimeFormat ).format( upsell_start ).replace( ',', '' ).toUpperCase() + ' until ' + new Intl.DateTimeFormat( 'en-AU', dateTimeFormat ).format( upsell_end ).replace( ',', '' ).toUpperCase() + '.';
                  is_visible = upsell_start < new Date() && upsell_end > new Date();
                } else if ( upsell_start ) {
                  schedule_text = 'This upsell is configured to display from ' + new Intl.DateTimeFormat( 'en-AU', dateTimeFormat ).format( upsell_start ).replace( ',', '' ).toUpperCase();
                  is_visible = upsell_start < new Date();
                } else if ( upsell_end ) {
                  schedule_text = 'This upsell is configured to display until ' + new Intl.DateTimeFormat( 'en-AU', dateTimeFormat ).format( upsell_end ).replace( ',', '' ).toUpperCase()
                  is_visible = upsell_end > new Date();
                }
              }

              return (
                  <TableRow key={upsell.id}>
                    <TableCell>
                      <Checkbox
                          color="primary"
                          onClick={handleToggle( upsell.id )}
                          checked={checked.indexOf( upsell.id ) !== - 1}
                          tabIndex={- 1}
                          disableRipple
                      />
                    </TableCell>
                    <TableCell className={classes.pointerToCell}
                        onClick={() => history.push( `/upsells/${upsell.id}` )}>{upsell.id}
                    </TableCell>
                    <TableCell style={{textAlign: 'center', maxWidth: 90, whiteSpace: 'nowrap'}}>
                      <Switch
                          disabled={activeInActionId.indexOf( upsell.id ) !== - 1}
                          checked={upsell.active || false}
                          onChange={( e ) => handleChangeSwitch( e, upsell )}
                          color="primary"
                      />
                      {is_scheduled && <Tooltip title={schedule_text} arrow>
                        {is_visible ? <ScheduleOutlined style={{ color: 'purple', top: 8, position: 'relative', cursor: 'help' }}/> : <VisibilityOffOutlined style={{ color: '#c00', top: 8, position: 'relative', cursor: 'help' }}/>}
                      </Tooltip>}
                    </TableCell>
                    <TableCell className={classes.pointerToCell}
                        onClick={() => history.push( `/upsells/${upsell.id}` )}>{upsell.name}
                    </TableCell>
                    <TableCell>${upsell.revenue || "-"}</TableCell>
                    <TableCell>{upsell.add_to_carts || "-"}</TableCell>
                    <TableCell>{upsell.impressions || "-"}</TableCell>
                    <TableCell>{upsell.conversion_rate || "-"}%</TableCell>
                    <TableCell align="right">
                      <div className={classes.tableControls}>
                        <EditIcon
                            htmlColor="#002937"
                            style={{
                              cursor: "pointer",
                              marginRight: "20px"
                            }}
                            onClick={() => history.push( `/upsells/${upsell.id}` )}
                        />
                        <DeleteIcon
                            htmlColor="#002937"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteUpsellHandler( upsell.id, upsell.name )}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
              );
            })}
          </TableBody>
        </Table>
          <div style={{
            display: isMobile ? "inline" : "flex",
            marginRight: 35,
            justifyContent: "space-between",
          }}>
            <div style={{
              display: "flex",
              color: "#b3b3b3",
              padding: "21px 16px"
            }}>
              <span>Show</span>
              <select className={classes.limit} value={limit} onChange={handleChangeLimit}>
                <option value={10}>10</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            <div className={classes.pg}>
              {
                pgData && pgData.totalPages > 1
                && <Pagination
                  count={parseInt(pgData.totalPages)}
                  page={parseInt(pgData.currentPageNumber)}
                  onChange={handleChangePage}
                />
              }
            </div>
          </div>
        </Paper>}
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  upsells: state.reducerUpsells.items || false,
  pgData: state.pagination.upsells,
  statData: state.stats.upsells,
  isUpsellsFetching: state.reducerUpsells.isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...upsellsActions,
  },
)(Upsells);
