import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

import * as actions from "../actions/setup.actions";

const initialState = {
  setup: {},
};

const SetupData = handleActions(
  {
    [actions.setSetup](state, { payload }) {
      return { ...payload };
    },
  },
  initialState.setup,
);

export default combineReducers({
  SetupData,
});
