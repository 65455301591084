import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MyApplications from "./MainPageComponents/ApplicationsBlock/MyApplications";
import { actions as upsellsActions } from "../../../../redux/reducers/upsell.resource";
import { actions as subscriptionsActions } from "../../../../redux/reducers/subscriptions.resource";
import { actions as internalPluginActions } from "../../../../redux/reducers/internalPlugin.resource";
import { actions as availableSubscriptionsActions } from "../../../../redux/reducers/availableSubscriptions.resource";
import "./mainPage.scss";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import OtherApplicationsBlock
  from "./MainPageComponents/OtherApplicationsBlock/OtherApplicationsBlock";
import { loadCachedRequestData, saveCachedRequestData } from "../../helpers/cache";

const useStyles = makeStyles((theme) => ({
  rootMainPage: {
    backgroundColor: "white",
    display: "flex",
    flexFlow: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
  rootMainPageGray: {
    backgroundColor: "#f4f6f8",
  },
  grid: {
    backgroundColor: "white",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
  },
}));

function MainPage({
  fetchUpsells,
  createSubscriptions,
  createInternalPlugin,
  isCreating,
  getAvailableSubscriptions,
  statData,
  getSubscriptions,
  isStatsLoading,
  realSubscriptions,
  realAvailableSubscriptions,
  internalPlugins,
}) {
  const classes                                             = useStyles();
  const [subscriptions, setSubscriptions]                   = useState(false);
  const [availableSubscriptions, setAvailableSubscriptions] = useState(false);

  // Save updated subscriptions to the cache and load it.
  useEffect(() => {
    if ( realSubscriptions !== false ) {
      saveCachedRequestData(realSubscriptions, "subscriptions");
      setSubscriptions(realSubscriptions);
    }
  }, [realSubscriptions]);

  // Load cache and request fresh subscriptions, if not already set.
  useEffect(() => {
    if ( subscriptions === false ) {
      setSubscriptions(loadCachedRequestData("subscriptions", {}));
      getSubscriptions();
    }
  }, [getSubscriptions, subscriptions]);

  // Save updated available subscriptions to the cache and load it.
  useEffect(() => {
    if ( realAvailableSubscriptions !== false ) {
      saveCachedRequestData(realAvailableSubscriptions, "available_subscriptions");
      setAvailableSubscriptions(realAvailableSubscriptions);
    }
  }, [realAvailableSubscriptions]);

  // Load cache and request fresh available subscriptions, if not already set.
  useEffect(() => {
    if ( availableSubscriptions === false ) {
      setAvailableSubscriptions(loadCachedRequestData("available_subscriptions", []));
      getAvailableSubscriptions();
    }
  }, [getAvailableSubscriptions, availableSubscriptions]);

  useEffect(
    () => {
      fetchUpsells({}, {
        query: {
          page: 1,
          limit: 10
        }
      });
    }, [fetchUpsells],
  );

  return (
    <div className="main-page-wrapper">
      <Container maxWidth="xl" className={clsx(classes.rootMainPage, classes.rootMainPageGray)}>
        <Grid item xl={10} lg={12} xs={12} sm={12} md={12} className={classes.grid}>
          <MyApplications
            uData={statData}
            subscriptions={subscriptions}
            getSubscriptions={getSubscriptions}
            isStatsLoading={isStatsLoading}
          />
        </Grid>
      </Container>
      <Container maxWidth="xl" className={clsx(classes.rootMainPage)}>
        <Grid item xl={10} lg={12} xs={12} sm={12} md={12} className={classes.grid}>
          <OtherApplicationsBlock
            availableSubscriptions={availableSubscriptions}
            createSubscriptions={createSubscriptions}
            createInternalPlugin={createInternalPlugin}
            isCreating={isCreating}
            subscriptions={subscriptions}
            internalPlugins={internalPlugins}
            getSubscriptions={getSubscriptions}
          />
        </Grid>
      </Container>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  statData: state.stats.upsells,
  isStatsLoading: state.reducerUpsells.isFetching,
  isCreating: state.reducerSubscriptions.isCreating || false,
  realSubscriptions: state.reducerSubscriptions.item || false,
  realAvailableSubscriptions: state.reducerAvailableSubscriptions.item || false,
  internalPlugins: state.reducerInternalPlugin.lastUpdated === 0 ? false : state.reducerInternalPlugin.items,
});

export default connect(
  mapStateToProps,
  {
    ...upsellsActions,
    ...subscriptionsActions,
    ...availableSubscriptionsActions,
    ...internalPluginActions,
  },
)(MainPage);
