import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  toast: {
    pointerEvents: "none",
    position: "absolute",
    bottom: 25,
    right: 95,
    padding: 20,
    background: "rgba(0,0,0,.8)",
    borderRadius: 6,
    color: "#fff",
    zIndex: 1,
    "&:hover": {
      opacity: "0.3",
    },
  },
  toastError: {
    background: "rgba(165,14,14,0.8)",
  },
  progress: {
    marginLeft: 5,
    fontWeight: "bold",
  }
}));

export default function InitialSyncIndicator({
  events,
  getMe,
}) {
  const classes     = useStyles();
  let syncDataEvent = null;

  useEffect(() => {
    if (syncDataEvent === null || syncDataEvent.status === "pending" || syncDataEvent.status === "in_progress"){
      window.setTimeout(() => {
        getMe();
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, getMe]);

  events.forEach((event) => {
    if ( event.function === "sync_data" ) {
      syncDataEvent = event;
    }
  });

  return (
    <div className={clsx(classes.toast, syncDataEvent && syncDataEvent.status === "error" ? classes.toastError :'')}>
      {syncDataEvent && syncDataEvent.status === "success" && <span>Neto data synchronisation is in progress, please wait until it completes.</span>}
      {syncDataEvent && syncDataEvent.status === "success" && <span className={classes.progress}>{syncDataEvent.progress ? syncDataEvent.progress : 0}%</span>}
      {syncDataEvent && syncDataEvent.status === "error" && <span>There was an error during sync. Please contact support.</span>}
    </div>
  );
}
