import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  popupBackground: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10999,
    background: "rgba(0,0,0,0.3)",
  },
  popup: {
    position: "fixed",
    width: "101%",
    height: "100%",
    maxWidth: 600,
    maxHeight: 300,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "white",
    border: "1px solid #ccc",
    zIndex: 10999,
  },
  popupBody: {
    position: "absolute",
    top: "50%",
    padding: 20,
    transform: "translateY(-50%)",
    width: "100%",
  },
  center: {
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "#434343",
    cursor: "pointer",
  }
}));

export default function UpsellsImportPopup() {
  const classes                   = useStyles();


  return (
    <>
      <div className={classes.popupBackground}>
        <div className={classes.popup}>
          <div className={clsx(classes.popupBody, classes.center)}>
            <h1 style={{ marginTop: 0 }}>Let's create the upsell</h1>
            <p>
             DISPLAY FOR
            </p>
          </div>
        </div>
      </div>
    </>
  );
}