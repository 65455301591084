import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container, Grid, Typography,
} from "@material-ui/core";
import "whatwg-fetch";
import { makeStyles } from "@material-ui/core/styles";
import dawnImage from "../../../assets/images/pretty-emails-dawn.jpg";
import newYorkImage from "../../../assets/images/pretty-emails-new-york.png";
import Button from "@material-ui/core/Button";
import { isLocal, isStaging } from "../../../config/config";
import notification from "../Components/notification";
import { actions as invoicesActions } from "../../../redux/reducers/invoices.resource";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  activateButton: {
    marginRight: 10,
  },
  themes: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  theme: {
    width: 480,
    height: 538,
    border: '1px solid #eaeaea',
    borderRadius: 5,
    '&:hover $themeInfoBlock': {
      opacity: 1,
    },
    position: 'relative',
    marginBottom: 60,
  },
  themeInfoBlock: {
    background: 'rgba(255,255,255,0.90)',
    opacity: 0,
    transition: "opacity .2s",
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  themeInfoBlockContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    fontSize: 16,
  },
  themeTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  themeStartButton: {
    display: "block",
    margin: "auto",
  },
  themeMoreDetails: {
    display: 'block',
    marginTop: 15,
    textDecoration: "underline",
    color: "#000",
  },
  themeBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backdropFilter: 'blur(5px)',
    textAlign: 'center',
    fontSize: 28,
    paddingTop: '50%',
    textTransform: 'uppercase',
  }
}));

function PrettyEmails({
  me,
  createInvoices,
  getInvoices,
  invoices,
}) {
  const [ isMobile, setIsMobile ]                                         = useState(window.innerWidth < 1024);
  const classes                                                           = useStyles();
  const [ subscriptionStatusesPerTheme, setSubscriptionStatusesPerTheme ] = useState({});

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  // Load invoices on page load.
  useEffect(() => {
    if ( invoices !== false ) {
      return;
    }

    getInvoices();
  }, [ invoices, getInvoices ])

  const themes = [
    {
      id: 'dawn',
      styleId: 'default',
      name: 'Dawn',
      styleName: '',
      price: '$697 / One-time fee',
      image: dawnImage,
      numberOfTemplates: 20,
      url: 'https://outsmart.digital/prettyemails-for-maropost/',
      blur: false,
    },
    {
      id: 'new-york',
      styleId: 'default',
      name: 'New York',
      styleName: 'Light',
      price: '$697 / One-time fee',
      image: newYorkImage,
      numberOfTemplates: 20,
      url: '',
      blur: true,
    }
  ];

  let themeProductIdMapping = {
    'dawn': 'prod_M3VuqOngWV31A8',
  }

  useEffect(() => {
    if ( invoices === false ) {
      return;
    }

    // Check if we can edit or buy each theme.
    let toSaveStatuses = {};
    themes.forEach(theme => {
      if ( typeof themeProductIdMapping[ theme.id ] === 'undefined' ) {
        // Cannot buy or verify if bought - mark as unusable.
        toSaveStatuses[ theme.id ] = null;
        return;
      }

      Object.values(invoices).forEach(invoice => {
        if ( typeof invoice.lines === 'undefined' || typeof invoice.lines.data === 'undefined' ) {
          return;
        }

        invoice.lines.data.forEach(lineItem => {
          if ( typeof lineItem.price === 'undefined' ) {
            return;
          }

          // Skip void invoices.
          if ( invoice.status === 'void' ) {
            return;
          }

          if ( lineItem.price.product === themeProductIdMapping[ theme.id ] ) {
            toSaveStatuses[ theme.id ] = invoice.paid ? 'paid' : 'unpaid';
          }
        })
      })

      // Not bought but it can be bought - mark as buyable.
      if ( typeof toSaveStatuses[ theme.id ] === 'undefined' ) {
        toSaveStatuses[ theme.id ] = 'can-buy';
      }
    })

    setSubscriptionStatusesPerTheme(toSaveStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ invoices ]);

  const openPaymentPage = (themeId) => {
    let invoiceUrl = null;
    Object.values(invoices).forEach(invoice => {
      if ( typeof invoice.lines === 'undefined' || typeof invoice.lines.data === 'undefined' ) {
        return;
      }

      invoice.lines.data.forEach(lineItem => {
        if ( typeof lineItem.price === 'undefined' ) {
          return;
        }

        // Skip void invoices.
        if ( invoice.status === 'void' ) {
          return;
        }

        if ( lineItem.price.product === themeProductIdMapping[ themeId ] ) {
          invoiceUrl = invoice.hosted_invoice_url;
        }
      })
    })

    if ( invoiceUrl ) {
      window.open(invoiceUrl);
    } else {
      notification({
        isOpen: true,
        type: "error",
        messageText: "Could not open the invoice payment page. Please contact support.",
      });
    }
  }

  const openEditor = (theme, isTrial) => {
    let dataToSend = {
      s: JSON.parse(localStorage.getItem('authData')).sid,
      tN: theme.name,
      tI: theme.id,
      tS: theme.styleName,
      tSI: theme.styleId,
      iT: isTrial,
    }
    window.open((isStaging ? 'https://dev.prettyemails.outsmart.digital' : (isLocal ? 'http://localhost:3001' : 'https://prettyemails.outsmart.digital')) + '/?d=' + btoa(JSON.stringify(dataToSend)), '_self');
  }

  const activateTheme = (pid) => {
    createInvoices({ pid: pid })
      .then((res) => {
        if ( res.body ) {
          if ( res.body.status === "success" ) {
            notification({
              isOpen: true,
              type: "success",
              messageText: "Successfully created the invoice. Opening payment page...",
            });
          } else {
            notification({
              isOpen: true,
              type: "error",
              messageText: "Error: " + res.body.message,
            });
          }
        } else {
          notification({
            isOpen: true,
            type: "error",
            messageText: "Unknown error.",
          });
        }

        // Update the subscriptions data and open payment page.
        getInvoices({ nocache: true }).then(() => {
          window.open(res.body.invoice.hosted_invoice_url);
        });
      })
      .catch(() => {
        notification({
          isOpen: true,
          type: "error",
          messageText: "Server error.",
        });

        // Update the subscriptions data.
        getInvoices({ nocache: true });
      });
  };

  return (
    <Container maxWidth="xl">
      <Grid className={classes.root} item xs={12} sm={12}>
        <div style={{ display: 'flex' }}>
          <Typography component="h2" variant="h6" color="secondary" gutterBottom
            className={classes.title} style={{
            display: isMobile ? "inherit" : "flex",
            textAlign: "center",
          }}>
            Select Your Template
          </Typography>
          <Button variant={"contained"} color={"primary"} style={{ margin: '0 0 16px 16px' }} onClick={() => {
            invoices = false;
            setSubscriptionStatusesPerTheme({});
            getInvoices();
          }}>Reload payment statuses</Button>
        </div>
        <div className={classes.themes}>
          {Object.keys(subscriptionStatusesPerTheme).length === 0 ? 'Loading...' : themes.map(theme => {
            return <div key={theme.id} className={classes.theme} style={{
              background: `url('${theme.image}')`,
            }}>
              {theme.blur && <div className={classes.themeBlur}>Coming Soon</div>}
              <div className={classes.themeInfoBlock} style={theme.blur ? { display: 'none' } : null}>
                <div className={classes.themeInfoBlockContent}>
                  <p className={classes.themeTitle}>{theme.name}{theme.styleName === '' ? '' : ' - ' + theme.styleName}</p>
                  <p>{theme.numberOfTemplates} Neto emails included</p>
                  {subscriptionStatusesPerTheme[ theme.id ] === null ?
                    <p>$N/A</p> : (subscriptionStatusesPerTheme[ theme.id ] === 'paid' ? null : <p>{theme.price}</p>)}
                  <Button className={classes.themeStartButton} color={subscriptionStatusesPerTheme[ theme.id ] === 'can-buy' ? "secondary" : "primary"} variant={"contained"}
                    disabled={subscriptionStatusesPerTheme[ theme.id ] === null} onClick={() => {
                    if ( subscriptionStatusesPerTheme[ theme.id ] === 'paid' ) {
                      openEditor(theme, false);
                    } else {
                      openEditor(theme, true);
                    }
                  }}>{subscriptionStatusesPerTheme[ theme.id ] === null ? 'Cannot use' : (subscriptionStatusesPerTheme[ theme.id ] === 'paid' ? 'Open editor with ' + theme.name : 'Try with ' + theme.name)}</Button>
                  {subscriptionStatusesPerTheme[ theme.id ] === 'can-buy' || subscriptionStatusesPerTheme[ theme.id ] === 'unpaid' ? <Button className={classes.themeStartButton} color={"primary"} variant={"contained"} style={{ marginTop: 15 }} onClick={() => {
                    if ( subscriptionStatusesPerTheme[ theme.id ] === 'can-buy' ) {
                      activateTheme('prettyemails-' + theme.id);
                    } else if ( subscriptionStatusesPerTheme[ theme.id ] === 'unpaid' ) {
                      openPaymentPage(theme.id);
                    }
                  }}>{subscriptionStatusesPerTheme[ theme.id ] === 'can-buy' ? 'Buy Now' : 'Finish Payment'}</Button> : null}
                  {theme.url !== '' && <a href={theme.url} className={classes.themeMoreDetails} target={'_blank'} rel={'noopener noreferrer'}>More Details →</a>}
                </div>
              </div>
            </div>;
          })}
          {/* This is ugly af, but it's a quick layout fix: */}
          <div className={classes.theme} style={{ border: 0 }}></div>
        </div>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => (
  {
    me: state.reducerMe.item || false,
    invoices: state.reducerInvoices.item || false,
  }
);

export default connect(
  mapStateToProps, {
    ...invoicesActions,
  }
)(PrettyEmails);
