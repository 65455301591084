import React from "react";
import { connect } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  loader: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  }
}));

function LoaderProgress({
  isAvailableSubscriptionsFetching,
  isAvailableSubscriptionsFetchingItem,
  isAvailableSubscriptionsGetting,
  isBrandsFetching,
  isBrandsFetchingItem,
  isBrandUpsellFetching,
  isBrandUpsellFetchingItem,
  isCategoryFetching,
  isCategoryFetchingItem,
  isCategoryUpsellFetching,
  isCategoryUpsellFetchingItem,
  isEventFetching,
  isEventFetchingItem,
  isEventGetting,
  isMeFetching,
  isMeFetchingItem,
  isMeUpdating,
  isMeClearing,
  isMeGetting,
  isProductsFetching,
  isProductsFetchingItem,
  isProductsUpdating,
  isProductsGetting,
  isProductUpsellFetching,
  isProductUpsellFetchingItem,
  isSettingsFetching,
  isSettingsFetchingItem,
  isSettingsUpdating,
  isSettingsGetting,

  isStatsFetching,
  isStatsFetchingItem,
  isStatsUpdating,
  isStatsGetting,

  isSubscriptionFetching,
  isSubscriptionFetchingItem,
  isSubscriptionDeleting,
  isSubscriptionGetting,

  isSubscriptionsFetching,
  isSubscriptionsFetchingItem,
  isSubscriptionsCreating,
  isSubscriptionsGetting,

  isInvoicesFetching,
  isInvoicesFetchingItem,
  isInvoicesCreating,
  isInvoicesGetting,

  isUpsellsFetching,
  isUpsellsFetchingItem,
  isUpsellsGetting,
  isUpsellsCreating,
  isUpsellsUpdating,
  isUpsellsDeleting,
  isUpsellsClearing,

  isUpsellSettingsFetching,
  isUpsellSettingsFetchingItem,
  isUpsellSettingsGetting,
  isUpsellSettingsUpdating,
}) {
  const isLoading =
          isAvailableSubscriptionsFetching ||
          isAvailableSubscriptionsFetchingItem ||
          isAvailableSubscriptionsGetting ||
          isBrandsFetching ||
          isBrandsFetchingItem ||
          isBrandUpsellFetching ||
          isBrandUpsellFetchingItem ||
          isCategoryFetching ||
          isCategoryFetchingItem ||
          isCategoryUpsellFetching ||
          isCategoryUpsellFetchingItem ||
          isEventFetching ||
          isEventFetchingItem ||
          isEventGetting ||
          isMeFetching ||
          isMeFetchingItem ||
          isMeUpdating ||
          isMeClearing ||
          isMeGetting ||
          isProductsFetching ||
          isProductsFetchingItem ||
          isProductsUpdating ||
          isProductsGetting ||
          isProductUpsellFetching ||
          isProductUpsellFetchingItem ||
          isSettingsFetching ||
          isSettingsFetchingItem ||
          isSettingsUpdating ||
          isSettingsGetting ||
          isStatsFetching ||
          isStatsFetchingItem ||
          isStatsUpdating ||
          isStatsGetting ||
          isSubscriptionFetching ||
          isSubscriptionFetchingItem ||
          isSubscriptionDeleting ||
          isSubscriptionGetting ||
          isSubscriptionsFetching ||
          isSubscriptionsFetchingItem ||
          isSubscriptionsCreating ||
          isSubscriptionsGetting ||
          isInvoicesFetching ||
          isInvoicesFetchingItem ||
          isInvoicesCreating ||
          isInvoicesGetting ||
          isUpsellsFetching ||
          isUpsellsFetchingItem ||
          isUpsellsGetting ||
          isUpsellsCreating ||
          isUpsellsUpdating ||
          isUpsellsDeleting ||
          isUpsellsClearing ||
          isUpsellSettingsFetching ||
          isUpsellSettingsFetchingItem ||
          isUpsellSettingsGetting ||
          isUpsellSettingsUpdating
  ;

  const classes = useStyles();

  return (isLoading ? <LinearProgress className={classes.loader}/> : null);
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  isAvailableSubscriptionsFetching: state.reducerAvailableSubscriptions.isFetching,
  isAvailableSubscriptionsFetchingItem: state.reducerAvailableSubscriptions.isFetchingItem,
  isAvailableSubscriptionsGetting: state.reducerAvailableSubscriptions.isGetting,

  isBrandsFetching: state.reducerBrands.isFetching,
  isBrandsFetchingItem: state.reducerBrands.isFetchingItem,

  isBrandUpsellFetching: state.reducerBrandUpsells.isFetching,
  isBrandUpsellFetchingItem: state.reducerBrandUpsells.isFetchingItem,

  isCategoryFetching: state.reducerCategorys.isFetching,
  isCategoryFetchingItem: state.reducerCategorys.isFetchingItem,

  isCategoryUpsellFetching: state.reducerCategoryUpsells.isFetching,
  isCategoryUpsellFetchingItem: state.reducerCategoryUpsells.isFetchingItem,

  isEventFetching: state.reducerEvents.isFetching,
  isEventFetchingItem: state.reducerEvents.isFetchingItem,
  isEventGetting: state.reducerEvents.isGetting,

  isMeFetching: state.reducerEvents.isFetching,
  isMeFetchingItem: state.reducerEvents.isFetchingItem,
  isMeUpdating: state.reducerEvents.isUpdating,
  isMeClearing: state.reducerEvents.isClearing,
  isMeGetting: state.reducerEvents.isMeGetting,

  isProductsFetching: state.reducerProducts.isFetching,
  isProductsFetchingItem: state.reducerProducts.isFetchingItem,
  isProductsUpdating: state.reducerProducts.isUpdating,
  isProductsGetting: state.reducerProducts.isGetting,

  isProductUpsellFetching: state.reducerProductUpsells.isFetching,
  isProductUpsellFetchingItem: state.reducerProductUpsells.isFetchingItem,

  isSettingsFetching: state.reducerSettings.isFetching,
  isSettingsFetchingItem: state.reducerSettings.isFetchingItem,
  isSettingsUpdating: state.reducerSettings.isUpdating,
  isSettingsGetting: state.reducerSettings.isGetting,

  isStatsFetching: state.reducerStats.isFetching,
  isStatsFetchingItem: state.reducerStats.isFetchingItem,
  isStatsUpdating: state.reducerStats.isUpdating,
  isStatsGetting: state.reducerStats.isGetting,

  isSubscriptionFetching: state.reducerSubscription.isFetching,
  isSubscriptionFetchingItem: state.reducerSubscription.isFetchingItem,
  isSubscriptionDeleting: state.reducerSubscription.isDeleting,
  isSubscriptionGetting: state.reducerSubscription.isGetting,

  isSubscriptionsFetching: state.reducerSubscriptions.isFetching,
  isSubscriptionsFetchingItem: state.reducerSubscriptions.isFetchingItem,
  isSubscriptionsCreating: state.reducerSubscriptions.isCreating,
  isSubscriptionsGetting: state.reducerSubscriptions.isGetting,

  isInvoicesFetching: state.reducerInvoices.isFetching,
  isInvoicesFetchingItem: state.reducerInvoices.isFetchingItem,
  isInvoicesCreating: state.reducerInvoices.isCreating,
  isInvoicesGetting: state.reducerInvoices.isGetting,

  isUpsellsFetching: state.reducerUpsells.isFetching,
  isUpsellsFetchingItem: state.reducerUpsells.isFetchingItem,
  isUpsellsGetting: state.reducerUpsells.isGetting,
  isUpsellsCreating: state.reducerUpsells.isCreating,
  isUpsellsUpdating: state.reducerUpsells.isUpdating,
  isUpsellsDeleting: state.reducerUpsells.isDeleting,
  isUpsellsClearing: state.reducerUpsells.isClearing,

  isUpsellSettingsFetching: state.reducerUpsellSettings.isFetching,
  isUpsellSettingsFetchingItem: state.reducerUpsellSettings.isFetchingItem,
  isUpsellSettingsGetting: state.reducerUpsellSettings.isGetting,
  isUpsellSettingsUpdating: state.reducerUpsellSettings.isUpdating,
});

export default connect(
  mapStateToProps
)(LoaderProgress);
