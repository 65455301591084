import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "category",
  url: `${apiUrl}/categories`,
  actions: {
    fetch: {
      transformResponse: (res) => ({
        body: res.body ? res.body.categories : [],
        resourceName: "categorys",
        pagination: {
          currentPageNumber: res.body.currentPage,
          totalPages: res.body.totalPages || false,
          numItemsPerPage: res.body.itemsPerPage || false,
          categoriesCount: res.body.categoriesCount || false,
        },
      }),
    },
  },
});
