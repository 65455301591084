import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "stat",
  url: `${apiUrl}/stats`,
  actions: {
    get: {
      transformResponse: res => ({ body: res.body.data }),
    },
    update: {
      method: "PUT",
      transformResponse: (res) => ({
        data: res.body.data,
        message: res.body.message,
      }),
    },
  },
});
