import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container, Grid, Typography,
} from "@material-ui/core";
import "whatwg-fetch";
import { makeStyles } from "@material-ui/core/styles";
import { isLocal, isStaging } from "../../../config/config";
import notification from "../Components/notification";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  activateButton: {
    marginRight: 10,
  },
}));

function SmartAddress({
  me,
}) {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 1024);
  const classes                   = useStyles();

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  const CodeBlock = () => {
    return (<xmp style={{
      background: '#797979',
      fontFamily: 'monospace',
      padding: 20,
      color: '#fff',
      width: 1200,
      borderRadius: 10,
      whiteSpace: 'pre-wrap',
      cursor: 'pointer'
    }} onClick={(e) => {
      navigator.clipboard.writeText(e.target.innerText);
      notification({
        isOpen: true,
        type: 'success',
        messageText: 'Copied to clipboard.',
      })
    }}>{`<script>window.pd_sm_nuid = '[@user:user_id@]';</script><script src="https://`+(isLocal||isStaging ? 'dev.' : '')+`app.outsmart.digital/smart-address.js" defer></script>`}
    </xmp>);
  }

  return (
    <Container maxWidth="xl">
      <Grid className={classes.root} item xs={12} sm={12}>
        <Typography component="h2" variant="h6" color="secondary" gutterBottom
          className={classes.title} style={{
          display: isMobile ? "inherit" : "flex",
          textAlign: "center",
        }}>
          SmartAddress | Get Started
        </Typography>
        <p>Please create a new Custom Script in your store's cPanel -> Settings & Tools -> All Settings & Tools -> Custom Scripts, with the following
          content in the "Page Footer" section.</p>
        <CodeBlock/>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  me: state.reducerMe.item || false,
});

export default connect(
  mapStateToProps
)(SmartAddress);
