import React, {useEffect} from "react";
import { render, unmountComponentAtNode } from "react-dom";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
/*
USAGE
import { confirm } from "../Modals/Confirm";
showDeleteModal = () => {
 confirm({
   confirmActionText: "Delete", // optional
   cancelActionText: "Cancel", // optional
   confirmAction: () => {}, // optional
   cancelAction: () => {}, // optional
   text: "Are you sure you want to delete?", // optional
   type: "success", // optional
 });
};
*/

function AlertDialog({ messageData, messageData: { isOpen, type, messageText, permanent, duration }, removeElementReconfirm }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);

  useEffect(
    () => {
      setOpen(isOpen);
    }, [setOpen, isOpen, messageData],
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    removeElementReconfirm();
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={permanent === true ? null : (duration ? duration : 5000) }
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={type || "error"}>
          {messageText}
        </Alert>
      </Snackbar>
    </div>
  );
}

function removeElementReconfirm() {
  const target = document.getElementById("react-confirm-alert");
  document.body.children[1].classList.remove("react-confirm-alert-blur");
  unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
}

function createElementReconfirm(properties) {
  let div = document.getElementById("react-confirm-alert");
  if (div) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ConfirmModal {...properties} />, div);
  } else {
    // Mount the ReactConfirmAlert component
    document.body.children[1].classList.add("react-confirm-alert-blur");
    div = document.createElement("div");
    div.id = "react-confirm-alert";
    document.body.appendChild(div);
    render(<ConfirmModal {...properties} />, div);
  }
}

function ConfirmModal({ isOpen, type, messageText, permanent, duration }) {
  return (
    <AlertDialog messageData={{isOpen, type, messageText, permanent, duration}} removeElementReconfirm={removeElementReconfirm} />
  );
}

/**
 * isOpen = true, type = "success || error || warning", messageText = ""
 * @param properties
 */
export default function notification(properties) {
  createElementReconfirm(properties);
}
