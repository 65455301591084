import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button, FormControlLabel, FormGroup, Switch, TextField } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import notification from "../notification";

const useStyles = makeStyles((theme) => ({
  networkCard: {
    border: '3px solid #CDD8DC',
    borderRadius: 10,
    width: 100,
    minWidth: 350,
    display: 'flex',
    flexFlow: 'column',
    padding: 30,
    margin: 20,
    background: 'white',
  },
  networkCardInactive: {
    height: 130,
    '& .network-header': {
      borderBottom: 'none',
      margin: 'none',
      padding: 'none',
    }
  },
  title: {
    fontWeight: 500,
    margin: 0,
  },
  header: {
    position: 'relative',
    marginBottom: '1rem',
    paddingBottom: '1rem',
    borderBottom: '1px solid #cdd8dc',
  },
  setupGuide: {
    textDecoration: 'none',
    position: 'relative',
    paddingLeft: 20,
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  buttonActive: {},
  formControl: {
    width: '100%',
  },
  helpIcon: {
    width: 17,
    height: 17,
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-52%)',
  },
}));
export default function SocialLoginNetworkCard({ network, settings, updateSetting, createInternalPlugin }) {
  const classes                         = useStyles();
  const [ forceReload, setForceReload ] = useState(1);

  const FormField = ({ field, value, prefix }) => {
    const [ stateValue, setStateValue ] = useState(value);
    const onChangeHandler               = value => {
      setStateValue(value);
      settings._social_login.networks[ network.id ].parameters[ field.id ] = value;
      updateSetting({ _social_login: settings._social_login });
      window[ prefix + '-inputTimer-' + field.id ] = window.setTimeout(() => {
        notification({
          isOpen: true,
          type: 'success',
          messageText: 'Updated changes.',
        })
      }, 1500);
    };

    switch ( field.type ) {
      case 'input':
        return <div style={{
          position: 'relative'
        }}>
          <TextField
            id={prefix + '-' + field.id}
            value={stateValue}
            label={field.title}
            variant="outlined"
            margin={'dense'}
            style={{
              width: '100%',
            }}
            helperText={typeof field.helpText !== 'undefined' ? field.helpText : null}
            onChange={e => onChangeHandler(e.target.value)}/>
        </div>;
      case 'toggle':
        return <div style={{ position: 'relative' }}><FormGroup>
          <FormControlLabel
            control={<Switch defaultChecked color={"primary"} onChange={e => onChangeHandler(e.target.checked)}/>}
            checked={stateValue}
            label={field.title}/>
        </FormGroup></div>;
      default:
        return <div>Type "{field.type}" is not defined in code.</div>;
    }
  }

  if ( settings !== null ) {
    if ( typeof settings._social_login === 'undefined' || settings._social_login === null ) {
      settings._social_login = {
        networks: {},
        settings: {},
      };
    }

    network.requiredParameters.forEach((param) => {
      if ( typeof settings._social_login.networks[ network.id ] === 'undefined' ) {
        settings._social_login.networks[ network.id ] = {
          active: false,
          parameters: {},
        };
      }

      if ( typeof settings._social_login.networks[ network.id ].parameters[ param.id ] === 'undefined' ) {
        settings._social_login.networks[ network.id ].parameters[ param.id ] = typeof param.default === 'undefined' ? '' : param.default;
      }
    });
  }

  return (
    <div
      className={clsx(classes.networkCard, 'application-item', (settings && ! settings._social_login.networks[ network.id ].active ? classes.networkCardInactive : null))}
    >
      <div className={clsx(classes.header, 'network-header')}>
        <h1 className={classes.title}>{network.name}</h1>
        <a href={network.setupGuideUrl} className={classes.setupGuide} target={'_blank'}
          rel={'noopener noreferrer'}><Help
          className={classes.helpIcon}/> Setup Guide</a>
        {settings ? <Button
          className={clsx(classes.button, settings._social_login.networks[ network.id ].active ? classes.buttonActive : null)}
          color={settings._social_login.networks[ network.id ].active ? 'default' : 'primary'}
          variant={"contained"}
          onClick={() => {
            settings._social_login.networks[ network.id ].active = ! settings._social_login.networks[ network.id ].active;
            createInternalPlugin({ id: 'social-login-network-' + network.id })
              .catch(e => console.log('Potential error during network activation: ' + e))
              .finally(() => {
                updateSetting({ _social_login: settings._social_login });
              });
            setForceReload(forceReload + 1);
            notification({
              type: 'success',
              isOpen: true,
              messageText: settings._social_login.networks[ network.id ].active ? 'Activated network ' + network.name : 'Deactivated network ' + network.name,
            });
          }}
        >{settings._social_login.networks[ network.id ].active ? 'Deactivate' : 'Activate'}</Button> : ''}
      </div>
      {settings ? (settings._social_login.networks[ network.id ].active ? <div className={classes.formControl}>
        {network.requiredParameters.map(parameter => <FormField field={parameter} prefix={network.id}
          value={settings._social_login.networks[ network.id ].parameters[ parameter.id ]}
          key={network.id + '-' + parameter.id}/>)}
      </div> : '') : <div>Loading settings...</div>}
    </div>
  );
}
