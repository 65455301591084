import React from "react";
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Typography } from "@material-ui/core";
import LightTooltip from "../../Components/LightTooltip";
import "./product.scss";
import ImagePlaceholder from "../ImagePlaceholder";

export default function Product({ product, removeHander }) {
  return (
    <div className="form-product">
      <ImagePlaceholder clsName="form-product-image" imgUrl={product.image} styles={{ width: "60px", marginRight: "10px" }} />
      <div className="form-product-settings">
        <Typography>{product.name}</Typography>
        <Typography variant="overline" display="block" style={{opacity: .7}}>SKU: {product.sku}</Typography>
      </div>
      <div>
        <LightTooltip aria-setsize="50" title="Remove product">
          <IconButton aria-label="delete" onClick={() => removeHander(product.sku)} >
            <DeleteIcon style={{ cursor: "pointer" }} htmlColor="#002937" />
          </IconButton>
        </LightTooltip>
      </div>
    </div>
  )
}
