import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "sync",
  url: `${apiUrl}/sync`,
  actions: {
    get: {
      gerundName: "getSync",
      url: `${apiUrl}/sync`,
      method: "GET",
      transformResponse: (res) => ({
        body: res.body,
      }),
    },
    clear: {
      isPure: true,
      reduce: state => ({ ...state, item: null, items: [] }),
    }
  },
});
