import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container, Grid, TextField, Typography,
} from "@material-ui/core";
import "whatwg-fetch";
import { makeStyles } from "@material-ui/core/styles";
import notification from "../Components/notification";
import { actions as settingsActions } from "../../../redux/reducers/setting.resource";
import Button from "@material-ui/core/Button";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
}));

function SocialLoginSettings({
  settings,
  getSetting,
  updateSetting,
}) {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 1024);
  const classes                   = useStyles();

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  useEffect(
    () => {
      if ( ! settings ) {
        getSetting()
          .then(res => {
            if ( ! res.body ) {
              notification({
                isOpen: true,
                type: "error",
                messageText: res.message || "Unknown error.",
              });
            }
          });
      }
    }, [ settings, getSetting ],
  );

  const settingsFields = [
    {
      'id': 'netoLoggedOutUserId',
      'title': 'User ID of logged out user',
      'type': 'input',
      'default': '1',
      'helpText': <a href="https://docs.outsmart.digital/article/2761" target={'_blank'} rel={'noopener noreferrer'}>What's this?</a>
    },
    {
      'id': 'loginSelector',
      'title': 'Login box CSS selector',
      'type': 'input',
      'default': '#login',
    },
    {
      'id': 'registerSelector',
      'title': 'Register box CSS selector',
      'type': 'input',
      'default': '#register'
    },
    {
      'id': 'passwordResetSelector',
      'title': 'Forgot password CSS selector',
      'type': 'input',
      'default': '#main-content form',
      'helpText': <a href="https://docs.outsmart.digital/article/2762" target={'_blank'} rel={'noopener noreferrer'}>CSS selector help page</a>
    },
  ];

  const FormField = ({ field, value, prefix }) => {
    const [ stateValue, setStateValue ] = useState(value);

    switch ( field.type ) {
      case 'input':
        return <div style={{
          position: 'relative'
        }}>
          <TextField
            id={prefix + '-' + field.id}
            value={stateValue}
            label={field.title}
            variant="outlined"
            margin={'dense'}
            style={{
              width: '100%',
              background: '#fff',
            }}
            helperText={typeof field.helpText !== 'undefined' ? field.helpText : null}
            onChange={(e) => {
              setStateValue(e.target.value);
              settings._social_login.settings[ field.id ] = e.target.value;
            }}/>
        </div>;
      default:
        return <div>Type "{field.type}" is not defined in code.</div>;
    }
  }

  if ( settings !== null ) {
    if ( typeof settings._social_login === 'undefined' || settings._social_login === null ) {
      settings._social_login = {
        networks: {},
        settings: {},
      };
    }

    if ( typeof settings._social_login.settings === 'undefined' ) {
      settings._social_login.settings = {};
    }

    // Must be an object!
    if ( Array.isArray(settings._social_login.settings) ) {
      settings._social_login.settings = {};
    }

    settingsFields.forEach(field => {
      if ( typeof settings._social_login.settings[ field.id ] === 'undefined' ) {
        settings._social_login.settings[ field.id ] = typeof field.default === 'undefined' ? '' : field.default;
      }
    });
  }

  return (
    <Container maxWidth="xl">
      <Grid className={classes.root} item xs={12} sm={12}>
        <Typography component="h2" variant="h6" color="secondary" gutterBottom
          className={classes.title} style={{
          display: isMobile ? "inherit" : "flex",
          textAlign: "center",
        }}>
          Social Login | Settings
        </Typography>
        <div style={{
          maxWidth: 500,
        }}>
          {settings ? <>
            {settingsFields.map(field => <FormField
              field={field}
              prefix={'settings'}
              value={settings._social_login.settings[ field.id ]}/>
            )}
            <Button color="primary" variant="contained"
              style={{
                marginTop: 10,
              }}
              onClick={() => {
                updateSetting({ _social_login: settings._social_login });
                window.setTimeout(() => {
                  notification({
                    isOpen: true,
                    type: 'success',
                    messageText: 'Settings saved!',
                  })
                }, 1500);
              }}>Save Settings</Button>
          </> : <p>Loading settings...</p>}
        </div>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  settings: state.reducerSettings.item,
});

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
  },
)(SocialLoginSettings);
