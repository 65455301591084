import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Container,
    Grid,
    Typography,
} from "@material-ui/core";
import {actions as subscriptionsActions} from "../../../redux/reducers/subscriptions.resource";
import {actions as subscriptionActions} from "../../../redux/reducers/subscription.resource";
import notification from "../Components/notification";
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";
import {apiUrl} from "../../../config/config";
import {getAuthData} from "../../../redux/authStorage";
import {confirm} from "../Components/confirm";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
    },
    title: {
        fontSize: "1.75rem",
        lineHeight: "2rem",
        fontWeight: 600,
        marginTop: 3,
        marginBottom: 24,
    },
    subscription: {
        padding: 24,
        boxShadow: "0 0 0 1px rgba(35,38,59,.05),0 1px 3px 0 rgba(35,38,59,.15)",
        borderRadius: 3,
        marginBottom: 20,
        background: "white",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        transition: "height .2s ease-in-out",
        height: 270,
        position: "relative",
        "&.collapsed": {
            cursor: "pointer",
            height: 72,
            "& .subtitle": {
                display: "none",
            },
            "& .title": {
                color: "#a4a4a4",
            }
        }
    },
    subscriptionStatus: {
        "&.Pending": {
            color: "orange",
        },
        "&.Cancelled": {
            color: "red",
        }
    },
    isInTrial: {
        color: "red",
        "&.in-trial": {
            color: "green",
        }
    },
    appBadge: {
        padding: "2px 10px",
        borderBottomRightRadius: 3,
        borderBottomLeftRadius: 3,
        marginRight: 10,
        position: "absolute",
        right: 0,
        top: 0,
        userSelect: "none",
        cursor: "default",
        textTransform: "capitalize",
        "&.canceled": {
            background: "#f0f0f0",
        },
        "&.active": {
            background: "green",
            color: "#fff",
        },
        "&.Trial": {
            background: "#ffe300",
            color: "#000",
        },
        "&.past_due": {
            background: "#cc0000",
            color: "#fff"
        },
        "&.unpaid": {
            background: "#cc0000",
            color: "#fff"
        },
        "&.incomplete": {
            background: "#FFA500",
            color: "#000"
        },
        "&.incomplete_expired": {
            background: "#f0f0f0",
        }
    },
    appTitle: {
        color: "black",
        fontSize: "1rem",
        fontWeight: 600,
        lineHeight: "1.5rem",
        userSelect: "none",
    },
    appSubtitle: {
        color: "#5a5e9a",
        fontSize: ".75rem",
        fontWeight: 400,
        lineHeight: "1rem",
        userSelect: "none",
    },
    line: {
        width: "100%",
        borderBottom: "1px solid #ccc",
        marginTop: 20,
    },
    seePreviousInvoices: {
        position: "absolute",
        top: 45,
        right: 10,
        cursor: "pointer",
        textDecoration: "none",
        padding: "5px 10px",
        textTransform: "none",
    },
    cancelSubscription: {
        position: "absolute",
        bottom: 0,
        right: 0,
    },
    section: {
        marginTop: 20,
    },
    primaryColor: {
        color: theme.palette.primary.main,
    },
}));

function Subscriptions({
                           subscriptions,
                           getSubscriptions,
                           deleteSubscription,
                           isSubscriptionsFetching: isSubscriptionsFetchingReal,
                           isSubscriptionDeleting,
                       }) {
    const [isSubscriptionsFetching, setIsSubscriptionsFetching] = useState(false);

    // Force refresh of subscriptions on page load.
    useEffect(() => {
        setIsSubscriptionsFetching(true);
        getSubscriptions({}, {query: {nocache: 'true'}})
            .then(res => {
                setIsSubscriptionsFetching(false);
                if (!res.body) {
                    notification({
                        isOpen: true,
                        type: "error",
                        messageText: res.message || "Unknown error.",
                    });
                }
            })
            .catch(() => {
                setIsSubscriptionsFetching(false);
                notification({
                    isOpen: true,
                    type: "error",
                    messageText: "There was an error",
                });
            });
        // We don't put any dependencies, since we want to force the refresh of subscriptions on every page load.
        // eslint-disable-next-line
    }, []);

    const classes = useStyles();

    const cancelSubscription = (id) => {
        deleteSubscription(id)
            .then((res) => {
                if (res.body) {
                    if (res.body.status === "success") {
                        notification({
                            isOpen: true,
                            type: "success",
                            messageText: "Successfully Cancelled",
                        });
                        getSubscriptions();
                    } else {
                        notification({
                            isOpen: true,
                            type: "error",
                            messageText: "Error: " + res.body.message,
                        });
                    }
                } else {
                    notification({
                        isOpen: true,
                        type: "error",
                        messageText: "Unknown error.",
                    });
                }
            })
            .catch(() => {
                notification({
                    isOpen: true,
                    type: "error",
                    messageText: "Server error.",
                });
            });
    };

    const Subscription = ({data}) => {
        const [collapsed, setCollapsed] = useState(data.status === "canceled");

        let isInTrial = data.status === "trialing",
            start_date = new Date(data.current_period_start * 1000),
            end_date = new Date(data.current_period_end * 1000);

        let start_date_textual = start_date.toLocaleString("default", {month: "long"}) + " " + start_date.getDate() + ", " + start_date.getFullYear(),
            end_date_textual = end_date.toLocaleString("default", {month: "long"}) + " " + end_date.getDate() + ", " + end_date.getFullYear();

        return (
            <Grid
                item
                sx={12}
                className={clsx(classes.subscription, data.status, collapsed ? "collapsed" : null)}
                id={"subscription-" + data.id}
                key={data.id}
                onClick={(e) => {
                    if (!(e.target.classList.contains("button") || e.target.parentElement.classList.contains("button"))) {
                        setCollapsed(!collapsed);
                    }
                }}
            >
                <header>
                    <div className={clsx(classes.appTitle, "title")}>
            <span
                className={clsx(classes.appBadge, isInTrial ? "Trial" : data.status)}>{isInTrial ? "Trial" : (data.status === "canceled" ? "cancelled" : (data.status === 'past_due' ? 'past due' : (data.status === 'incomplete_expired' ? 'Expired' : data.status)))}</span>
                        {data.plan.product_name}
                    </div>
                    <div
                        className={clsx(classes.appSubtitle, "subtitle")}>From {start_date_textual} to {end_date_textual}</div>
                    {!collapsed && <Button color={"secondary"} variant={"contained"}
                                           className={clsx(classes.seePreviousInvoices, "button")}
                                           href={apiUrl + "/subscriptions_portal?token=" + (Object.keys(getAuthData()).length > 0 ? getAuthData().sid : "")}
                                           rel="noopener noreferrer"
                                           target="_blank"
                    >View subscription</Button>}
                </header>
                <section className={classes.section}>
                    {data.cancel_at_period_end === true && data.status === "active" &&
                        <span className={classes.primaryColor} style={{fontWeight: "bold"}}>Note: Will be cancelled once the current period ends.</span>}
                </section>
                <div className={classes.line}/>
                <section style={{position: "relative"}} className={classes.section}>
                    <header>
                        <div className={classes.appTitle}>Next Invoice</div>
                        <div
                            className={classes.appSubtitle}>{data.status === "canceled" ? "-" : end_date_textual}</div>
                    </header>
                    <section className={classes.section}>
                        <div className={classes.appTitle}>Total Current Balance</div>
                        <div className={classes.appSubtitle}>
                            {isInTrial ? "$0.00 (Trial)" : (data.status === "canceled" ? "-" : "$" + (((data.plan.amount) - (data.discount ? data.discount.coupon.amount_off ? data.discount.coupon.amount_off : data.discount.coupon.percent_off ? data.plan.amount * data.discount.coupon.percent_off / 100 : 0 : 0)) / 100).toFixed(2))}
                            {data.discount ? " (with a discount of " + (data.discount.coupon.amount_off ? "$" + (data.discount.coupon.amount_off / 100).toFixed(2) : data.discount.coupon.percent_off ? data.discount.coupon.percent_off + "%" : 0) + ")" : null}
                        </div>
                    </section>
                    {(data.status === "trialing") && <Button
                        color="secondary"
                        variant="contained"
                        aria-label="cancel-subscription"
                        disabled={isSubscriptionDeleting || isSubscriptionsFetchingReal}
                        className={clsx(classes.cancelSubscription, "button")}
                        onClick={() => {
                            confirm({
                                text: "Are you sure that you want to cancel your trial?",
                                confirmAction: () => {
                                    cancelSubscription(data.id);
                                },
                            });
                        }}>End Trial</Button>}
                </section>
            </Grid>
        );
    };

    return (
        <Container maxWidth="xl" spacing={2}>
            <Grid className={classes.root} item xs={12} sm={12}>
                <Grid container direction={"column"}>
                    <Typography component="h1" variant="h4" color="secondary" gutterBottom
                                className={classes.title}>
                        Subscriptions
                    </Typography>
                    {isSubscriptionsFetching &&
                        Array.apply(null, {length: 3})
                            .map((e, i) => (
                                <Grid item sx={12} className={classes.subscription} key={i}>
                                    <header>
                                        <div className={classes.appTitle}><Skeleton variant="text" width={150}/></div>
                                        <div className={classes.appSubtitle}><Skeleton variant="text" width={210}/>
                                        </div>
                                    </header>
                                    <section className={classes.section}>
                                        <Skeleton variant="rect" width={210} height={20}/>
                                    </section>
                                    <div className={classes.line}/>
                                    <section style={{position: "relative"}} className={classes.section}>
                                        <header>
                                            <div className={classes.appTitle}><Skeleton variant="text" width={150}/>
                                            </div>
                                            <div className={classes.appSubtitle}><Skeleton variant="text" width={80}/>
                                            </div>
                                        </header>
                                        <section className={classes.section}>
                                            <div className={classes.appTitle}><Skeleton variant="text" width={150}/>
                                            </div>
                                            <div className={classes.appSubtitle}><Skeleton variant="text" width={60}/>
                                            </div>
                                        </section>
                                        <Button className={classes.seePreviousInvoices}><Skeleton variant="text"
                                                                                                  width={150}/></Button>
                                    </section>
                                </Grid>
                            ))}
                    {!isSubscriptionsFetching &&
                        (Object.keys(subscriptions).length ?
                            Object.values(subscriptions)
                                .sort((a, b) => a.status === 'canceled' ? 1 : -1)
                                .map((subscription, key) => {
                                    return <Subscription key={key} data={subscriptions[key]}/>;
                                })
                            : <p>There are no subscriptions.</p>)
                    }
                </Grid>
            </Grid>
        </Container>
    );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
    subscriptions: state.reducerSubscriptions.item || false,
    isSubscriptionsFetching: state.reducerSubscriptions.isFetchingItem,
    isSubscriptionsUpdating: state.reducerSubscriptions.isUpdating,
    isSubscriptionDeleting: state.reducerSubscription.isDeleting,
});

export default connect(
    mapStateToProps,
    {
        ...subscriptionsActions,
        ...subscriptionActions,
    },
)(Subscriptions);
