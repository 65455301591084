import React, { useEffect, useState } from "react";
import RecommendationsContent from "../Components/Recommendations/RecommendationsContent";
import RecommendationsPopup from "../Components/Recommendations/RecommendationsPopup";
import { connect } from "react-redux";
import { actions as settingsActions } from "../../../redux/reducers/setting.resource";
import notification from "../Components/notification";
import syncInProgressImage from "../../../assets/images/ocu-recommendations-initial-loading.jpg";

let resizeTimeout   = false;
let defaultDateFrom = null;
let defaultDateTo   = null;

function Recommendations({
  settings,
  getSetting,
}) {
  const [popupOpen, setPopupOpen]               = useState(false);
  const [popupProductSku, setPopupProductSku]   = useState("");
  const [popupProductName, setPopupProductName] = useState("");
  const [isMobile, setIsMobile]                 = useState(window.innerWidth < 1024);
  const today                                   = new Date();

  // Reset date to zero hours/seconds, used for comparing the selected date later.
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const [selectedDateTo, setSelectedDateTo]     = React.useState(new Date(today));
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date(today.setDate(today.getDate() - 30)));

  if ( defaultDateFrom === null && defaultDateTo === null ) {
    defaultDateFrom = selectedDateFrom;
    defaultDateTo   = selectedDateTo;
  }

  const handleDateChange = (ranges) => {
    setSelectedDateFrom(ranges.selection.startDate);
    setSelectedDateTo(ranges.selection.endDate);
  };

  const openPopup = (sku, name) => {
    setPopupProductSku(sku);
    setPopupProductName(name);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  useEffect(
    () => {
      if ( !settings ) {
        getSetting()
          .then(res => {
            if ( !res.body ) {
              notification({
                isOpen: true,
                type: "error",
                messageText: res.message || "Unknown error.",
              });
            }
          });
      }
    }, [settings, getSetting],
  );

  return (
    <>
      {settings.is_initial_sync_complete === false  ?
        <div style={{
          backgroundColor: "#F7F7F7",
          textAlign: "center",
          height: "100%",
        }}>
          <div style={{
            display: "flex",
            height: "50%",
          }}>
            <img src={syncInProgressImage} alt="syn in progress" style={{ margin: "auto", height: "100%",}}/>
          </div>
          <div>
            <h1>Importing your Neto data...</h1>
            <h3>We'll have your winning offer combinations ready soon. You will receive an email once they are ready.</h3>
          </div>
        </div>
        :
        <>
          <RecommendationsContent
            openPopup={openPopup}
            isMobile={isMobile}
            onDateChange={handleDateChange}
            defaultDateFrom={defaultDateFrom}
            defaultDateTo={defaultDateTo}
          />
          <RecommendationsPopup
            open={popupOpen}
            sku={popupProductSku}
            name={popupProductName}
            popupClose={closePopup}
            isMobile={isMobile}
            dateFrom={selectedDateFrom}
            dateTo={selectedDateTo}
          />
        </>
      }
    </>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
  return {
    settings: state.reducerSettings.item || false,
    isSettingsFetching: state.reducerSettings.isFetchingItem,
  };
};

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
  },
)(Recommendations);
