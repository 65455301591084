import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button, CircularProgress
} from "@material-ui/core";
import SyncIcon from '@material-ui/icons/Sync';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import notification from "../../Components/notification";
import { makeStyles } from "@material-ui/core/styles";
import { actions as syncsActions } from "../../../../redux/reducers/sync.resource";
import { actions as eventActions } from "../../../../redux/reducers/event.resource";
import { setSetupData } from "../../../../redux/actions/setup.actions";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '6px',
    left: '10%',
  },
  progressAmount: {
    position: 'absolute',
    top: '3px',
    left: '40%',
  },
}));

let interval;

function SyncButton({
  getSync,
  syncItem,
  setSetupData,
  setupData,
  eventItem,
  fetcher,
  getEvent,
}) {
  const [ sync, setSync ]               = useState(false);
  const [ progress, setProgress ]       = useState(false);
  const [ syncError, setSyncError ]     = useState(false);
  const [ syncEventId, setSyncEventId ] = useState(0);
  const classes                         = useStyles();

  useEffect(() => {
    if ( sync && syncEventId ) {
      if ( interval ) {
        return;
      }

      interval = window.setInterval(() => {
        getEvent({ id: syncEventId });
      }, 5000);
    } else {
      if ( interval ) {
        window.clearInterval(interval);
      }
    }
    // eslint-disable-next-line
  }, [ sync ]);

  useEffect(() => {
    if ( eventItem
      && (eventItem.status === "pending"
        || eventItem.status === "in_progress")
      && ! sync ) {
      setSync(true);
    }
    if ( eventItem
      && (eventItem.status === "error")
      && sync ) {
      setSync(false);
      setSyncError(true);
      notification({
        isOpen: true,
        type: "error",
        messageText: "Sync error" + (typeof eventItem.message !== "undefined" && eventItem.message ? " (" + eventItem.message + ")" : ""),
        permanent: true,
      });
    }
    if ( sync && eventItem && eventItem.progress ) {
      if ( eventItem.progress === 100 ) {
        notification({
          isOpen: true,
          type: "success",
          messageText: "Sync completed successfully" + (typeof eventItem.message !== "undefined" && eventItem.message ? " - " + eventItem.message + ")" : ""),
          permanent: true,
        });
        setProgress(false);
        setSync(false);
        setSetupData({});
        if ( fetcher ) fetcher();
        return;
      }
      setProgress(eventItem.progress);
    }
  }, [ eventItem, setSetupData, setSync, setProgress, sync, fetcher ]);

  useEffect(() => {
    setSetupData(syncItem);
  }, [ setSetupData, syncItem ]);

  useEffect(() => {
    if ( setupData.res && setupData.res === "finish" ) {
      setSync(false);
      if ( fetcher ) fetcher();
    }
    if ( setupData.res && setupData.res === "error" ) {
      setSync(false);
      setSyncError(true);
    }
  }, [ setupData, fetcher ]);

  const startSync = () => {
    setSyncError(false);
    setSyncEventId(0);
    setProgress(false);
    setSync(false);
    setSetupData({});
    getSync()
      .then((res) => {
        if ( res.body.status === "error" ) {
          notification({
            isOpen: true,
            type: "error",
            messageText: "Sync initialization error" + (typeof res.body.message !== "undefined" && res.body.message ? " (" + res.body.message + ")" : ""),
            permanent: true,
          });
          setSync(false);
        } else {
          setSyncEventId(res.body.event_id);
          setSync(true);
        }
      })
      .catch((err) => {
        notification({
          isOpen: true,
          type: "error",
          messageText: "Sync initialization error: " + err,
          permanent: true,
        });
        setSync(false);
      });
  };

  return (
    <div className={classes.wrapper}>
      <Button
        color="secondary"
        variant="contained"
        onClick={startSync}
        disabled={sync}
        id={'sync_button'}
      >
        {syncError ? <SyncProblemIcon style={{ marginRight: "5px" }}/> : <SyncIcon style={{ marginRight: "5px" }}/>}
        Sync
      </Button>
      {(sync && ! progress) && <CircularProgress className={classes.progress} size={24}/>}
      {(sync && progress) && (
        <>
          <span className={classes.progressAmount}>{`${Math.round(progress)}%`}</span>
          <CircularProgress className={classes.progress} size={24}/>
        </>
      )}
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  syncItem: state.reducerSyncs.item,
  setupData: state.Setup.SetupData,
  eventItem: state.reducerEvents.item,
});

export default connect(
  mapStateToProps,
  {
    ...syncsActions,
    ...eventActions,
    setSetupData,
  },
)(SyncButton);
