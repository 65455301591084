import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "event",
  url: `${apiUrl}/event/:id`,
  actions: {
    get: {
      method: "GET",
      transformResponse: (res) => ({
        body: res.body.event,
      }),
    }
  },
});
