import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "subscriptions",
  url: `${apiUrl}/subscriptions`,

  actions: {
    get: {
      transformResponse: res => {
        const statusSortOrder = [
          "Active",
          "Pending",
          "Cancelled",
        ];

        let entries = Array.isArray(res.body) ? res.body : [];
        entries.sort((a, b) => statusSortOrder.indexOf(a.status) - statusSortOrder.indexOf(b.status));

        return ({
          body: entries,
          message: res.body.message ? res.body.message : null,
        });
      },
    },
    create: {
      method: "POST",
    }
  },
});
