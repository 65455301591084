import { handleActions } from "redux-actions";
import { combineReducers } from "redux";

import * as actions from "../actions/theme.actions";
const initialState = {
  theme: {
    //isSidebarOpen: (window.screen.availWidth > 1024),
    isSidebarOpen: true,
    //isMobile: (window.screen.availWidth < 1024),
    isMobile: false,
  },
};

const ThemeData = handleActions(
  {
    [actions.setTheme](state, { payload }) {
      return { ...payload };
    },
  },
  initialState.theme,
);

export default combineReducers({
  ThemeData,
});
