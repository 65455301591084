import React, { useState } from "react";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Footer from "../../Footer/Footer";
import { actions as userActions } from "../../../redux/reducers/users";
import logo from "../../../assets/images/os-logo.svg";
import { apiUrl, isLocal, isStaging } from "../../../config/config";
import GoogleLogin from "react-google-login";
import { Button, LinearProgress, TextField } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  centerContent: {
    display: "block",
    marginTop: 20,
    color: "#272727",
    textAlign: "center",
  },
}));

function SignIn() {
  const classes                                     = useStyles();
  const [ isCookiesCleared, setIsCookiesCleared ]   = useState(false);
  const [ isCheckingLogin, setIsCheckingLogin ]     = useState(false);
  const [ isTakingTooLong, setIsTakingTooLong ]     = useState(false);
  const [ clientList, setClientList ]               = useState(null);
  const [ googleToken, setGoogleToken ]             = useState(null);
  const [ isCreatingSession, setIsCreatingSession ] = useState(false);
  const [ developerClientId, setDeveloperClientId ] = useState(null);

  if ( ! isCookiesCleared ) {
    // Clear local storage.
    localStorage.clear();

    // Clear session storage.
    sessionStorage.clear();

    // Delete all cookies.
    const cookies = document.cookie.split("; ");
    for ( let c = 0; c < cookies.length; c ++ ) {
      const d = window.location.hostname.split(".");
      while ( d.length > 0 ) {
        const cookieBase = encodeURIComponent(cookies[ c ].split(";")[ 0 ].split("=")[ 0 ]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
        const p          = window.location.pathname.split("/");
        document.cookie  = cookieBase + "/";
        while ( p.length > 0 ) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }

    setIsCookiesCleared(true);
  }

  /**
   * Actions to execute when developer account is successfully authenticated.
   *
   * @param googleResponse
   */
  const onSuccessfulGoogleLogin = googleResponse => {
    if ( typeof googleResponse.tokenId === "undefined" ) {
      window.alert("Error in Google login.");
      return;
    }

    setGoogleToken(googleResponse.tokenId);
    setIsCheckingLogin(true);
    setClientList(null);
    let timeout = window.setTimeout(() => {
      setIsTakingTooLong(true);
    }, 7000);

    fetch(apiUrl + "/google/auth", {
      method: "POST",
      headers: {
        "Content-Type": "text/plain"
      },
      redirect: "follow",
      body: googleResponse.tokenId
    })
      .then(response => response.json())
      .then(data => {
        setIsCheckingLogin(false);
        window.clearTimeout(timeout);

        if ( typeof data.status === "undefined" ) {
          window.alert("There was an error with logging in to the app: Unknown error.");
          return;
        }

        if ( data.status === "error" ) {
          window.alert("There was an error with logging in to the app: " + (typeof data.message === "undefined" ? "Undefined." : data.message));
          return;
        }

        setClientList(typeof data.clients !== "undefined" ? data.clients : []);
        setDeveloperClientId(typeof data.clients !== "undefined" ? data.clients[ 0 ].uid : null);
      })
      .catch(() => {
        setIsCheckingLogin(false);
        window.clearTimeout(timeout);

        window.alert("There was an error with logging in to the app.");
      });
  };

  /**
   * Actions to execute when developer account has failed authentication.
   *
   * @param response
   */
  const onFailedGoogleLogin = response => {
    setGoogleToken(null);
    setIsTakingTooLong(false);
    window.alert("There was an error with Google login: " + JSON.stringify(response));
  };

  /**
   * Actions on selecting the development client and clicking on continue.
   */
  const doSelectDevelopmentClient = () => {
    setIsCreatingSession(true);

    fetch(apiUrl + "/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      body: JSON.stringify({
        token: googleToken,
        uid: developerClientId,
      })
    })
      .then(response => response.json())
      .then(data => {
        if ( typeof data.sid !== "undefined" ) {
          window.location = "/?s=" + data.sid;
          return;
        }

        setIsCreatingSession(false);
        setClientList(null);

        window.alert("Error creating session: " + (typeof data.message === "undefined" ? "Unknown error." : data.message));
      })
      .catch(() => {
        setIsCreatingSession(false);
        setClientList(null);

        window.alert("There was an error with creating the session.");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <img style={{
          width: "200px",
          margin: "16px 20px"
        }} src={logo} alt="logo"/>
        <Grid container>
          <Grid item xs>
            {(isLocal || isStaging) ? <>
                <b className={classes.centerContent}>Not a developer? Please go to
                  the <a href={"https://app.outsmart.digital"} target={"_self"}
                    rel={"noopener noreferrer"}>live app</a>.</b>
                {isCheckingLogin || isCreatingSession ? <Typography className={classes.centerContent}>Please
                  wait...</Typography> : clientList === null || (Array.isArray(clientList) && clientList.length === 0) ?
                  <Typography className={classes.centerContent}>
                    Welcome to the development login. Please authenticate with
                    your <b>@perceptiv.digital</b> account to continue.</Typography> :
                  <Typography className={classes.centerContent}>Please select the client you want to
                    login as<br/> and click on <b>Continue</b>.</Typography>}
                <div className={classes.centerContent}>
                  {Array.isArray(clientList) && clientList.length === 0 ?
                    <Typography className={classes.description} style={{
                      marginTop: 20,
                      marginBottom: 20,
                      color: "red",
                      fontWeight: "bold"
                    }}>No client
                      data returned. Please try logging in with another account.</Typography> : null}
                  {isCheckingLogin || isCreatingSession ? <LinearProgress style={{ marginBottom: 20 }}/> :
                    clientList === null || (Array.isArray(clientList) && clientList.length === 0) ? <>
                      <GoogleLogin
                        clientId={"819056865282-vochiaf0qagdtf61p3n58hojkl15g81s.apps.googleusercontent.com"}
                        onSuccess={onSuccessfulGoogleLogin}
                        onFailure={onFailedGoogleLogin}/>
                      {isTakingTooLong ?
                        <Typography className={classes.description} style={{ marginTop: 20 }}>This is
                          taking a bit longer than usual...<br/> Please check your network connection
                          if nothing happens soon</Typography> : null}
                    </> : <>
                      <Autocomplete
                        disablePortal
                        options={clientList.map(client => {
                          return { label: client.uid + ' - ' +client.name, id: client.uid };
                        })}
                        getOptionLabel={(option) => option.label}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Select Client"/>}
                        onChange={(event, value) => setDeveloperClientId(parseInt(value.id))}
                      />
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        style={{
                          marginTop: 20,
                        }}
                        onClick={doSelectDevelopmentClient}>
                        Continue
                        <ArrowForward style={{
                          marginLeft: 10,
                        }}/>
                      </Button>
                    </>}
                </div>
              </> :
              <Typography className={classes.description}
                style={{ marginLeft: - 10, marginRight: - 10, textAlign: "center" }}>
                Please log in to your Neto control panel and go to Addons to find Outsmart Apps and
                get started or configure the existing app.
              </Typography>}
          </Grid>
        </Grid>
      </div>
      <Box mt={8}>
        <Footer/>
      </Box>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
  {
    ...userActions,
  },
)(SignIn);
