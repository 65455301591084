import React from 'react';
import SiteRouter from "./SiteRouter"

export default function App({ isAuth }) {
  return (
    <>
      <SiteRouter isAuth={isAuth} />
    </>
  );
}
