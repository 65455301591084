import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { actions as upsellsActions } from "../../../../redux/reducers/upsell.resource";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  TextField, Button,
  FormControl, Checkbox,
  FormGroup, InputLabel,
  FormControlLabel,
  Typography, Select, MenuItem, IconButton, OutlinedInput,
} from "@material-ui/core";
import "./form.scss";
import OffersList from "./OffersList";
import Product from "./Product";
import Entity from "./Entity";
import { display_on_prefill } from "../../helpers/displayOnData";
import LightTooltip from "../../Components/LightTooltip";
import OffersModal from "./Modal/OffersModal";
import UpsellTargetModal from "./Modal/UpsellTargetModal";
import { actions as productsActions } from "../../../../redux/reducers/product.resource";
import CategorySearch from "./CategorySearch";
import BrandSearch from "./BrandSearch";
import DisplayForPopup from "./DisplayForPopup";
import ContentSearch from "./ContentSearch";
import AddIcon from "@material-ui/icons/Add";
import Flatpickr from "react-flatpickr";
// noinspection NpmUsedModulesInstalled
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import "flatpickr/dist/themes/light.css";
import DeleteIcon from "@material-ui/icons/Delete";
import InputAdornment from "@material-ui/core/InputAdornment";

let resizeTimeout = false;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    padding: "10px",
    [ theme.breakpoints.up("1024") ]: {
      padding: "30px",
      paddingTop: 10,
    },
    paddingTop: 10,
    backgroundColor: "#fff",
    position: 'relative',
    flexGrow: 1,
  },
  formControl: {
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  formControlNoMargin: {
    width: "100%",
  },
  formButton: {
    // marginTop: theme.spacing(3),
  },
  formLabel: {
    fontSize: "20px",
  },
  accordionDetails: {
    display: "flex",
    flexFlow: "column",
    paddingTop: 0,
    paddingBottom: 10,
  },
  heading: {
    fontSize: 20,
    // marginTop: "20px",
    marginBottom: "10px",
  },
  heading1: {
    fontSize: 20,
    marginBottom: "10px",
    marginTop: "10px",
  },
  text: {
    fontSize: 15,
    paddingBottom: 20,
  },
  blockWrapper: {
    border: "1px solid #0000001f",
    borderRadius: "5px",
    padding: "15px",
    marginTop: "20px",
  },
  shadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0,0,0,0.5)',
    zIndex: 10,
  }
}));

function UpsellForm({
  dispatchData,
  settings,
  getSetting,
  upsellItem,
  getUpsell,
  save,
  getEmptyValues,
  clearUpsell,
  getProduct,
  isUpsellsFetching,
  isUpsellsFetchingItem,
  isUpsellsGetting,
}) {
  const classes = useStyles();
  const { id }  = useParams();

  const [ isOpenedPopupUpsell, setIsOpenedPopupUpsell ]         = useState( false );
  const [ isOpenedPopupDisplayFor, setIsOpenedPopupDisplayFor ] = useState( false );
  const [ isOpenPopupProduct, setIsOpenPopupProduct ]           = useState( false );
  const [ fData, setFdata ]                                     = useState( {
    name: "",
    is_custom_heading: false,
    custom_heading: settings ? settings.upsells_global_popup_title_has_offers : "",
    default_heading: settings ? settings.upsells_global_popup_title_has_offers : "",
    display_for: "all",
    display_on: [ display_on_prefill[ 0 ] ],
    product_target: false,
    category_target: false,
    brand_target: false,
    upsell_start: 0,
    upsell_end: 0,
    upsell_products: [],
  } );
  const [ isMobile, setIsMobile ]                               = useState( window.innerWidth < 1024 );
  const [ calendarOpen, setCalendarOpen ]                       = useState( false );
  const [ isUpsellLoading, setIsUpsellLoading ]                 = useState( false );

  // Show shadow if we are loading the upsell data.
  useEffect( () => {
    setIsUpsellLoading( isUpsellsFetching || isUpsellsFetchingItem || isUpsellsGetting );
  }, [ isUpsellsFetching, isUpsellsFetchingItem, isUpsellsGetting ] );

  useEffect(() => {
    if ( upsellItem && id !== "new" ) {
      const u_products = upsellItem.upsell_products.map((item, key) => {
        if ( item.hasOwnProperty("priority") ) return { ...item };
        return {
          ...item,
          priority: key
        };
      })
        .sort((a, b) => a.priority > b.priority ? 1 : -1);
      setFdata({
        ...upsellItem,
        upsell_products: u_products,
        display_on: display_on_prefill
          .filter((item) => upsellItem.display_on
            .findIndex((uItem) => uItem === item.val) !== -1
          ),
      });
    }
    return clearUpsell();
  }, [upsellItem, settings, id, clearUpsell]);

  useEffect(() => {
    if ( id !== "new" && parseInt(id) > 0 ) {
      getUpsell({ id: id });
    }
  }, [id, getUpsell]);

  useEffect(
    () => dispatchData(fData),
    [fData, dispatchData]
  );

  useEffect(
    () => {
      if ( settings ) {
        if ( fData.custom_heading === "" ) {
          setFdata({
            ...fData,
            custom_heading: settings.upsells_global_popup_title_has_offers,
          });
        }
        if ( !fData.default_heading ) {
          setFdata({
            ...fData,
            default_heading: settings.upsells_global_popup_title_has_offers,
          });
        }
      }
    },
    [settings, getSetting, fData],
  );

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  const handleOpenClose = () => {
    setIsOpenedPopupUpsell((old) => !old);
  };

  const handleOpenCloseDisplayFor = () => {
    setIsOpenedPopupDisplayFor((old) => !old);
  };

  const handleOpenCloseTargetModal = () => {
    setIsOpenPopupProduct((old) => !old);
  };

  const dispatchSelected = (prod) => {
    const addProducts = prod.map((item, key) => ({
      ...item,
      offer_intent: "cross_sell",
      discount_type: "percent",
      priority: fData.upsell_products.length + key,
    }));
    const newItems    = [...fData.upsell_products, ...addProducts];
    handleChangeValue("upsell_products", newItems);
  };

  const handleChangeValue = (name, value) => {
    setFdata({
      ...fData,
      [ name ]: value
    });
  };

  const handleChange = (e) => {
    if ( e.target.name === "display_for" ) {
      setFdata({
        ...fData,
        [ e.target.name ]: e.target.value,
        ...getEmptyValues(e.target.value),
      });
    } else {
      setFdata({
        ...fData,
        [ e.target.name ]: e.target.value
      });
    }

    if ( e.target.value === "product_target" ) {
      handleOpenCloseTargetModal();
    }
  };

  const handleChangeDO = (e, val) => {
    setFdata({
      ...fData,
      display_on: val
    });
  };

  const handleChangeOffer = (id, value) => {
    const newItems = fData.upsell_products.map((item) => item.sku === id ? value : item);
    handleChangeValue("upsell_products", newItems);
  };

  const handleBooleanChange = (e) => {
    setFdata({
      ...fData,
      [ e.target.name ]: !fData[ e.target.name ]
    });
  };

  const {
          name,
          is_custom_heading,
          custom_heading,
          display_for,
          display_on,
          upsell_start,
          upsell_end,
          product_target,
          brand_target,
          custom_target,
          category_target,
          upsell_products,
        } = fData;

  const removeProduct = (productId) => {
    const newItems = fData.upsell_products
      .filter(item => item.sku !== productId)
      .map((item, key) => ({
        ...item,
        priority: key
      }));
    handleChangeValue("upsell_products", newItems);
  };

  const sortHandler = (product, direction = 1) => {
    const newOrder = product.priority + direction;
    const newItems = fData.upsell_products.map((item) => {
      if ( item.sku === product.sku ) {
        return {
          ...item,
          priority: newOrder
        };
      }
      if ( item.priority === newOrder && item.sku !== product.sku ) {
        return {
          ...item,
          priority: product.priority
        };
      }
      return item;
    })
      .sort((a, b) => a.priority > b.priority ? 1 : -1);
    handleChangeValue("upsell_products", newItems);
  };

  return (
    <>
      <form noValidate autoComplete="off" onSubmit={save} className={classes.root}>
        {calendarOpen && <div className={classes.shadow}/>}
        {isUpsellLoading && <div className={classes.shadow}/>}
        <FormControl component="fieldset">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={name}
            id="name"
            label="Upsell Title"
            name="name"
            onChange={handleChange}
            autoComplete="name"
            autoFocus
            helperText="For your own internal reference. Only you can see it."
          />
        </FormControl>
        <FormControlLabel
          // className={classes.formControl}
          align="left"
          control={
            <Checkbox
              checked={is_custom_heading}
              onChange={handleBooleanChange}
              name="is_custom_heading"
              color="primary"
            />
          }
          label="Custom popup heading"
        />
        {is_custom_heading && (
          <FormControl component="fieldset" className={classes.formControl}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              value={custom_heading}
              id="custom_heading"
              label="Custom popup heading"
              name="custom_heading"
              onChange={handleChange}
            />
          </FormControl>
        )}

        <FormControl className={classes.formButton} style={{
          display: "none" // not finished yet
        }}>
          <Button size="large" variant="contained" color="primary" onClick={handleOpenCloseDisplayFor}>Display For</Button>
        </FormControl>

        <Typography className={classes.heading1}>Display options</Typography>
        <Typography className={classes.text}/>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-label">Display For</InputLabel>
          <Select label="Display For" id="select" labelId="select-label" name="display_for"
            value={display_for} onChange={handleChange}>
            <MenuItem value="all">All Products</MenuItem>
            <MenuItem value="product_target">Specific Product</MenuItem>
            <MenuItem value="category_target">Specific Category</MenuItem>
            <MenuItem value="brand_target">Specific Brand</MenuItem>
            <MenuItem value="custom_target">Specific Custom Content</MenuItem>
          </Select>
        </FormControl>

        <UpsellTargetModal
          isOpen={isOpenPopupProduct}
          isMobile={isMobile}
          onClose={handleOpenCloseTargetModal}
          onCancel={() => {
            handleChangeValue("display_for", "all");
            handleOpenCloseTargetModal();
          }}
          onSelect={(value) => getProduct({ id: value[ 0 ].sku })
            .then((res) => handleChangeValue("product_target", res.body))}
          canSelectMultiple={false}
        />

        {display_for === "product_target" && product_target && <Product product={product_target}
          removeHander={() => {
            handleChangeValue("product_target", false);
            handleChangeValue("display_for", "all");
          }}/>}

        {display_for === "category_target" && (category_target ? <Entity product={typeof category_target.content_id === 'undefined' ? category_target : {
                name: category_target.name,
                id: category_target.content_id,
            }}
            removeHander={() => handleChangeValue("category_target", false)}/> :
          <FormControl required component="fieldset" className={classes.formControl}>
            <CategorySearch
              dispatchSelected={(value) => handleChangeValue("category_target", value)}/>
          </FormControl>)}

        {display_for === "brand_target" && (brand_target ? <Entity product={brand_target}
            removeHander={() => handleChangeValue("brand_target", false)}/> :
          <FormControl component="fieldset" className={classes.formControl}>
            <BrandSearch dispatchSelected={(value) => handleChangeValue("brand_target", value)}/>
          </FormControl>)}

        {display_for === "custom_target" && (custom_target ? <Entity product={typeof custom_target.content_id === 'undefined' ? custom_target : {
              name: custom_target.name,
              id: custom_target.content_id,
            }}
            removeHander={() => handleChangeValue("custom_target", false)}/> :
          <FormControl component="fieldset" className={classes.formControl}>
            <ContentSearch dispatchSelected={(value) => handleChangeValue("custom_target", value)}/>
          </FormControl>)}

        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Autocomplete
              multiple
              id="multiple-limit-tags"
              onChange={handleChangeDO}
              name="display_on"
              options={display_on_prefill}
              getOptionDisabled={(option) => !option.enabled}
              value={display_on}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <LightTooltip title={""}>
                  <TextField {...params} variant="outlined" label="Display On"/>
                </LightTooltip>
              )}
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <Flatpickr
              value={upsell_start && upsell_start !== '0' ? parseInt(upsell_start.toString() + '000') : null}
              options={{dateFormat: 'd/m/Y G:i K', enableTime: true, plugins: [confirmDatePlugin({})], disableMobile: true}}
              onOpen={()=>{
                setCalendarOpen(true);
              }}
              onClose={()=>{
                setCalendarOpen(false);
              }}
              onChange={([date]) => {
                setFdata({ ...fData, upsell_start: Math.floor(date.valueOf() / 1000) });
              }}
              render={
                ({defaultValue, value, ...props}, ref) => {
                  let dateFormatted = '';
                  if (value !== null) {
                    // The value of "value" is either Date object or the Unix timestamp we processed above in onChange. We'll convert it into Date always. In some cases it can be in ms, sometimes s, so we cover both by checking the year.
                    let date = value instanceof Date ? value : (new Date(parseInt(value.toString() + '000')).getFullYear() > 2500 ? new Date(parseInt(value)) : new Date(parseInt(value.toString() + '000')));

                    // Outputs a string such as "27/09/2023 09:00 AM".
                    dateFormatted = new Intl.DateTimeFormat('en-AU', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: 'numeric',
                      hour12: true,
                    }).format(date).replace(',', '').toUpperCase();
                  }

                  return <FormControl style={{ marginBottom: 15 }}>
                    <InputLabel htmlFor="upsell_start" style={{ top: - 6, left: 15 }}>Start displaying from</InputLabel>
                    <OutlinedInput
                        value={dateFormatted}
                        id="upsell_start"
                        label="Start displaying from"
                        name="upsell_start"
                        inputRef={ref}
                        endAdornment={dateFormatted ? <InputAdornment position="end">
                          <IconButton
                              aria-label="clear field"
                              onClick={() => {
                                setFdata( { ...fData, upsell_start: 0 } );
                              }}
                              edge="end"
                              style={{
                                marginRight: 1,
                              }}
                          >
                            <DeleteIcon style={{ cursor: "pointer" }} htmlColor="#002937"/>
                          </IconButton>
                        </InputAdornment> : null
                        }
                    />
                  </FormControl>
                }
              }
          />
          <Flatpickr
              value={upsell_end && upsell_end !== '0' ? parseInt(upsell_end.toString() + '000') : null}
              options={{dateFormat: 'd/m/Y G:i K', enableTime: true, plugins: [confirmDatePlugin({})], disableMobile: true}}
              onOpen={()=>{
                setCalendarOpen(true);
              }}
              onClose={()=>{
                setCalendarOpen(false);
              }}
              onChange={([date]) => {
                setFdata({ ...fData, upsell_end: Math.floor(date.valueOf() / 1000) });
              }}
              render={
                ({defaultValue, value, ...props}, ref) => {
                  let dateFormatted = '';
                  if (value !== null) {
                    // The value of "value" is either Date object or the Unix timestamp we processed above in onChange. We'll convert it into Date always. In some cases it can be in ms, sometimes s, so we cover both by checking the year.
                    let date = value instanceof Date ? value : (new Date(parseInt(value.toString() + '000')).getFullYear() > 2500 ? new Date(parseInt(value)) : new Date(parseInt(value.toString() + '000')));

                    // Outputs a string such as "27/09/2023 09:00 AM".
                    dateFormatted = new Intl.DateTimeFormat('en-AU', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: 'numeric',
                      hour12: true,
                    }).format(date).replace(',', '').toUpperCase();
                  }

                  return <FormControl>
                    <InputLabel htmlFor="upsell_end" style={{ top: - 6, left: 15 }}>Stop displaying by</InputLabel>
                    <OutlinedInput
                        value={dateFormatted}
                        id="upsell_end"
                        label="Stop displaying by"
                        name="upsell_end"
                        inputRef={ref}
                        endAdornment={dateFormatted ? <InputAdornment position="end">
                          <IconButton
                              aria-label="clear field"
                              onClick={() => {
                                setFdata( { ...fData, upsell_end: 0 } );
                              }}
                              edge="end"
                              style={{
                                marginRight: 1,
                              }}
                          >
                            <DeleteIcon style={{ cursor: "pointer" }} htmlColor="#002937"/>
                          </IconButton>
                        </InputAdornment> : null}
                    />
                  </FormControl>
                }
              }
          />
        </FormControl>

        <Typography className={classes.heading}>Upsell Offers</Typography>
        <FormControl className={classes.formButton}>
          <Button size="large" variant="contained" color="primary" onClick={handleOpenClose} startIcon={<AddIcon/>}>
            Add Offer
          </Button>
        </FormControl>

        <DisplayForPopup
            isOpened={isOpenedPopupDisplayFor}
            isMobile={isMobile}
            setIsOpened={handleOpenCloseDisplayFor}
          />

        <OffersModal
          isOpened={isOpenedPopupUpsell}
          isMobile={isMobile}
          setIsOpened={handleOpenClose}
          product_target={display_for === "product_target" ? product_target : false}
          dispatchSelected={dispatchSelected}
          upsell_products={upsell_products}
          removeHander={removeProduct}
        />
        {settings ? (
          <>
            {fData.upsell_products.length ? (<OffersList
              product_target={display_for === "product_target" ? product_target : false}
              handleChangeOffer={handleChangeOffer}
              items={fData.upsell_products}
              removeHander={removeProduct}
              sortHandler={sortHandler}
              store_url={settings.store_url}
              settings={settings}
              setCalendarOpen={setCalendarOpen}
            />) : null}
          </>
        ) : null}
      </form>
    </>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  upsellItem: state.reducerUpsells.item || false,
  isUpsellsFetching: state.reducerUpsells.isFetching,
  isUpsellsFetchingItem: state.reducerUpsells.isFetchingItem,
  isUpsellsGetting: state.reducerUpsells.isGetting,
});

export default connect(
  mapStateToProps,
  {
    ...upsellsActions,
    ...productsActions,
  },
)(UpsellForm);
