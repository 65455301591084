import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import { actions as recommendationsActions } from "../../../../redux/reducers/recommendations.resource";
import RecommendationsTable from "./RecommendationsTable";
import {
  Button, Container, FormControl,
  Grid, OutlinedInput, Select,
  TableContainer,
  Typography
} from "@material-ui/core";
import RecommendationsDateRangePicker from "./RecommendationsDateRangePicker";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import RecommendationsCards from "./RecommendationsCards";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { history } from "../../../../redux/store";
import RecommendationsLoaderPopup from "./RecommendationsLoaderPopup";
import { format } from "date-fns";
import CloseIcon from "@material-ui/icons/Close";
import notification from "../notification";

const useStyles = makeStyles((theme) => ({
  form: {
    float: "left",
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginLeft: 128,
  },
  loaderMobile: {
    marginLeft: 0,
    marginTop: 100,
  },
  limit: {
    height: 22,
    padding: 2,
    width: 50,
    marginLeft: 5,
    border: "1px solid #c4c4c4",
    background: "#fff",
    appearance: "none",
    borderRadius: "0.25rem",
    boxShadow: "inset 0 1px 2px rgba(28,29,34,.075)",
  },
  pgTop: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  filterBubble: {
    background: "#ccc",
    color: "#848484",
    borderRadius: 6,
    padding: "4px 8px",
    marginRight: 5,
  },
  filterBubbleClose: {
    marginBottom: -6,
    width: "unset",
    height: 22,
    cursor: "pointer",
  },
  filterField: {
    marginRight: 10,
  },
  filterFieldMobile: {
    marginBottom: 10,
  },
}));

function RecommendationsContent({
  realRecommendations,
  realIsRecommendationsFetching,
  fetchRecommendations,
  convertRecommendations,
  realPgData,
  openPopup,
  isMobile,
  onDateChange,
  defaultDateFrom,
  defaultDateTo,
}) {

  const classes                                                   = useStyles();
  const [cookiesDateFrom, setCookieDateFrom]                      = useCookies(["ocu_start_date_recommendations"]);
  const [cookiesDateTo, setCookieDateTo]                          = useCookies(["ocu_end_date_recommendations"]);
  const [cookiesSort, setCookieSort]                              = useCookies(["ocu_sort_recommendations"]);
  const [cookiesLimit, setCookieLimit]                            = useCookies(["ocu_limit_recommendations"]);
  const [cookiesSortDirection, setCookieSortDirection]            = useCookies(["ocu_sort_recommendations_direction"]);
  const [sortData, setSortData]                                   = useState({
    order: cookiesSortDirection.ocu_sort_recommendations_direction || "desc",
    orderby: cookiesSort.ocu_sort_recommendations || "bought",
  });
  const [page, setPage]                                           = useState(1);
  const [limit, setLimit]                                         = useState(cookiesLimit.ocu_limit_recommendations || 10);
  const [search, setSearch]                                       = useState("");
  const [checkedProducts, setCheckedProducts]                     = useState({});
  const [fetchError, setFetchError]                               = useState("");
  const [requestId, setRequestId]                                 = useState("");
  const [recommendations, setRecommendations]                     = useState(false);
  const [pgData, setPgData]                                       = useState(false);
  const [isRecommendationsFetching, setIsRecommendationsFetching] = useState(realIsRecommendationsFetching);
  const [isCreatingAllRecommendations, setIsConvertAllFetching]   = useState(false);
  const [realPage, setRealPage]                                   = useState(1);
  const [dateFrom, setDateFrom]                                   = useState(isNaN(parseInt(cookiesDateFrom.ocu_start_date_recommendations)) ? defaultDateFrom : new Date(cookiesDateFrom.ocu_start_date_recommendations * 1000));
  const [dateTo, setDateTo]                                       = useState(isNaN(parseInt(cookiesDateTo.ocu_end_date_recommendations))  ? defaultDateTo : new Date(cookiesDateTo.ocu_end_date_recommendations * 1000));

  // Override recommendations loading state.
  useEffect(() => {
    setIsRecommendationsFetching(realIsRecommendationsFetching);
  }, [realIsRecommendationsFetching]);

  // Since we can't cancel network requests, this is a workaround, we ignore requests that do not
  // match the current request ID.
  useEffect(() => {
    setIsRecommendationsFetching(false);
    if ( typeof realRecommendations === "object" && realRecommendations.request_id && realRecommendations.request_id === requestId ) {
      setRecommendations(realRecommendations);
      setPgData(realPgData);
      setRealPage(parseInt(realPgData.currentPageNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realRecommendations, realPgData]);

  // Make the actual request for the data.
  useEffect(() => {
      // Load all of the parameters.
      const query = {};
      if ( page ) {
        query.page = page;
      }
      if ( sortData.orderby ) {
        query.order   = sortData.order;
        query.orderby = sortData.orderby;
      }
      if ( search && search.trim() !== "" ) {
        query.search = search;
      }

      if ( dateFrom ) {
        query.from = Math.round((+dateFrom) / 1000);
        setCookieDateFrom("ocu_start_date_recommendations", dateFrom.getTime() / 1000, {
          expires: new Date("December 31, 9999 23:59:59 GMT"),
        });
      }

      if ( dateTo ) {
        query.to = Math.round((+dateTo) / 1000);
        setCookieDateTo("ocu_end_date_recommendations", dateTo.getTime() / 1000, {
          expires: new Date("December 31, 9999 23:59:59 GMT"),
        });
      }

      onDateChange({
        selection: {
          startDate: dateFrom,
          endDate: dateTo
        }
      });

      if ( limit ) {
        query.limit = limit;
        setCookieLimit("ocu_limit_recommendations", limit, {
          expires: new Date("December 31, 9999 23:59:59 GMT"),
        })
      }

      // Save the request ID, for cancelling.
      let newRequestId = (+new Date()).toString();
      setRequestId(newRequestId);
      query.request_id = newRequestId;

      // Do the request!
      fetchRecommendations({}, {
        query: {
          ...query,
        }
      })
        .catch(e => setFetchError(e.toString()));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchRecommendations, sortData, page, search, dateFrom, dateTo, limit]
  );

  /**
   * Handles the sort change.
   * @param {string} orderby The new "order by" parameter value.
   */
  const sort = (orderby) => {
    // Set order to requested column, switch order if same column otherwise use "asc".
    let order = sortData.orderby !== orderby ? "desc" : sortData.order === "asc" ? "desc" : "asc";

    // Cache the sort data.
    setCookieSort("ocu_sort_recommendations", orderby, {
      expires: new Date("December 31, 9999 23:59:59 GMT"),
    });
    setCookieSortDirection("ocu_sort_recommendations_direction", order, {
      expires: new Date("December 31, 9999 23:59:59 GMT"),
    });

    // Save the sort data.
    setSortData({
      order: order,
      orderby: orderby,
    });

    // Reset the page, good UX.
    setPage(1);
  };

  /**
   * Executed on the page button click.
   *
   * @param {*} e The element.
   * @param {number} p The page number.
   */
  const handleChangePage = (e, p) => {
    setPage(p);
  };

  /**
   * Executed once the "per page" limit dropdown is clicked.
   *
   * @param {Event} e The click event.
   */
  const handleChangeLimit = (e) => {
    setLimit(e.currentTarget.value);
  };

  const [showCreateAllButton, setShowCreateAllButton] = useState(false);
  useEffect(() => {
    let hasChecked = false;
    Object.keys(checkedProducts)
      .forEach((index) => {
        if ( checkedProducts[ index ] === true ) {
          hasChecked = true;
        }
      });
    if ( showCreateAllButton !== hasChecked ) {
      setShowCreateAllButton(hasChecked);
    }
  }, [checkedProducts, showCreateAllButton]);

  /**
   * Executed upon clicking on the button for converting multiple recommendations into upsells.
   */
  const handleCreateAllUpsellsClick = () => {
    setIsConvertAllFetching(true);
    let skus = {};
    Object.keys(checkedProducts)
      .forEach((index) => {
        if ( checkedProducts[ index ] === true && !skus[ recommendations.recommendations[ index ].main ] ) {
          skus[ recommendations.recommendations[ index ].main ] = recommendations.skus_data[ recommendations.recommendations[ index ].main ].name;
        }
      });
    convertRecommendations({ sku: "_multi" }, {
      body: {
        skus
      },
      query: {
        from: Math.round((+dateFrom) / 1000),
        to: Math.round((+dateTo) / 1000),
      }
    })
      .then((res) => {
        if ( res.body.upsell ) {
          history.push("/upsells/" + res.body.upsell);
        } else {
          notification({
            isOpen: true,
            type: res.body.status,
            messageText: res.body.status === "success" ? "Upsells activated" : res.body.message,
          });
        }
        setIsConvertAllFetching(false);
      });
  };

  /**
   * Executed on selecting the sort on phone.
   *
   * @param {Event} e The click/tap event.
   */
  const handleSortByChangeMobile = (e) => {
    sort(e.target.value);
  };

  const handleCloseBubble = (resetState, defaultValue) => {
    if ( Array.isArray(resetState) ) {
      resetState.forEach((fn, i) => {
        fn(defaultValue[ i ]);
      });
    } else {
      resetState(defaultValue);
    }
  };

  /**
   * Shows what filters are currently selected.
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const RecommendationsFilterBubbles = () => {
    const FilterBubble = ({
      name,
      value,
      resetState,
      defaultValue,
    }) => {
      return (<div className={classes.filterBubble}>{name}: {value} <CloseIcon
        className={classes.filterBubbleClose}
        onClick={() => handleCloseBubble(resetState, defaultValue)}/></div>);
    };

    return (<div style={{ display: "flex" }}>
      {search &&
      <FilterBubble name={"Search"} value={search} resetState={[setSearch]}
        defaultValue={[""]}/>}
      {dateFrom && dateFrom.getTime() !== defaultDateFrom.getTime() &&
      <FilterBubble name={"Date From"} value={format(dateFrom, "LLL d, y")} resetState={setDateFrom}
        defaultValue={defaultDateFrom}/>}
      {dateTo && dateTo.getTime() !== defaultDateTo.getTime() &&
      <FilterBubble name={"Date To"} value={format(dateTo, "LLL d, y")} resetState={setDateTo}
        defaultValue={defaultDateTo}/>}
    </div>);
  };

  /**
   * The pagination component.
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const RecommendationsPagination = () => {
    return (<>{
      pgData && pgData.totalPages > 1
      && <div className={classes.pgTop}><Pagination
        count={parseInt(pgData.totalPages)}
        page={parseInt(pgData.currentPageNumber)}
        onChange={handleChangePage}
      /></div>
    }</>);
  };

  /**
   * The per page dropdown component.
   *
   * @returns {boolean|JSX.Element}
   * @constructor
   */
  const RecommendationsLimit = () => {
    return (
      !isRecommendationsFetching && <div style={{
        display: "flex",
        color: "#b3b3b3",
        padding: "21px 16px"
      }}>
        <span>Show</span>
        <select className={classes.limit} value={limit} onChange={handleChangeLimit}>
          <option value={10}>10</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    );
  };

  /**
   * Recommendations filters.
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const RecommendationsFilters = () => {
    const [searchLocal, setSearchLocal]     = useState(search);
    const [dateFromLocal, setDateFromLocal] = useState(dateFrom);
    const [dateToLocal, setDateToLocal]     = useState(dateTo);

    const handleSearchChange = (e) => {
      setSearchLocal(e.target.value);
    };

    function handleDateChange(ranges) {
      setDateFromLocal(ranges.selection.startDate);
      setDateToLocal(ranges.selection.endDate);
    }

    /**
     * Executed on Filter button click.
     */
    const handleFilterButtonClick = () => {
      setSearch(searchLocal);
      setDateFrom(dateFromLocal);
      setDateTo(dateToLocal);

      // Cache the date data.
      setCookieDateFrom("ocu_start_date_recommendations", dateFromLocal, {
        expires: new Date("December 31, 9999 23:59:59 GMT"),
      });
      setCookieDateTo("ocu_end_date_recommendations", dateToLocal, {
        expires: new Date("December 31, 9999 23:59:59 GMT"),
      });
      // document.cookie = "ocu_start_date_recommendations=" + dateFromLocal + "; Expires=Fri, 31
      // Dec 9999 23:59:59 GMT;"; document.cookie = "ocu_end_date_recommendations=" + dateToLocal +
      // "; Expires=Fri, 31 Dec 9999 23:59:59 GMT;";
    };

    return (<>
      <div style={{
        display: "flex",
        marginBottom: 15,
        flexDirection: isMobile ? "column" : null,
      }}>
        <div className={isMobile ? classes.filterFieldMobile : classes.filterField}>
          <OutlinedInput
            placeholder={"Search by SKU only"}
            id="outlined-adornment-amount"
            value={searchLocal}
            onChange={handleSearchChange}
            className={classes.margin}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon style={{ opacity: 0.5 }}/>
              </InputAdornment>
            }
            style={{
              height: 40,
              margin: 0,
              background: "white",
              minWidth: "240px",
              width: isMobile ? "100%" : null,
              marginTop: isMobile ? 10 : 0,
            }}
          /></div>
        <div className={isMobile ? classes.filterFieldMobile : classes.filterField}>
          <RecommendationsDateRangePicker
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            onChange={handleDateChange}
            ranges={[{
              startDate: dateFromLocal,
              endDate: dateToLocal,
              key: "selection",
            }]}
            rangeColors={["#002937"]}
            maxDate={new Date()}
            isMobile={isMobile}
            noCssPosition={true}
          /></div>
        <Button color={"primary"} variant={"contained"} type="submit"
          onClick={handleFilterButtonClick}
          style={{ marginBottom: isMobile ? 10 : null }}>Filter</Button>
        <span style={{
          color: "#b3b3b3",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          marginLeft: "auto",
        }}>
                  {isRecommendationsFetching ? "Loading..." : pgData && (pgData.totalItems > 0 ? (pgData.currentPageNumber - 1) * limit + 1 + "-" + Math.min(pgData.currentPageNumber * limit, pgData.totalItems) + " of " + pgData.totalItems + " entries" : "0 entries")}
                </span>
      </div>
    </>);
  };

  /**
   * The mobile pagination component.
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const RecommendationsPaginationMobile = () => {
    return (<>{
      pgData && pgData.totalPages > 1
      && <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 60,
        }}
      >
        <Pagination
          count={parseInt(pgData.totalPages)}
          page={parseInt(pgData.currentPageNumber)}
          siblingCount={0}
          onChange={handleChangePage}
        /></div>
    }</>);
  };

  /**
   * Component for mobile sorting.
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const RecommendationsSortMobile = () => {
    const sortOptions = [
      {
        value: "revenue",
        label: "Total Sales"
      },
      {
        value: "bought",
        label: "Ordered Together"
      },
    ];

    return (<div style={{
      display: "flex",
      justifyContent: "flex-start",
    }}>
      <Typography style={{
        alignSelf: "center"
      }} component="h4" variant="h7" color="secondary" gutterBottom>
        <p>Sort by: </p>
      </Typography>
      <form className={classes.form} style={{
        float: isMobile ? "none" : "",
        padding: isMobile ? 0 : "",
      }} noValidate autoComplete="off">
        <FormControl variant="standard" size="small">
          <Select native id="grouped-native-select"
            IconComponent={() => null}
            onChange={handleSortByChangeMobile}
            style={{
              paddingLeft: 5,
            }}
          >
            {sortOptions.map((option) => (
              <option
                value={option.value}
                selected={sortData.orderby === option.value}
              >
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </form>
      <div style={{
        padding: 5,
        alignSelf: "center"
      }}>
        {
          sortData.order === "asc" ?
            <ArrowUpward
              onClick={() => sort(sortData.orderby)}
              cursor="pointer"
            />
            :
            <ArrowDownward
              onClick={() => sort(sortData.orderby)}
              cursor="pointer"
            />
        }
      </div>
    </div>);
  };

  return (
    <Container maxWidth="xl" spacing={2}>
      <RecommendationsLoaderPopup
        isFetching={isRecommendationsFetching}
        displayFrom={dateFrom}
        displayTo={dateTo}
        displaySearch={search}
        fetchError={fetchError}
        setFetchError={setFetchError}
        cancelRequest={() => {
          setPage(realPage);
          setRequestId("");
          setIsRecommendationsFetching(false);
        }}
      />
      <Grid className={classes.root} item xs={12} sm={12}>
        <Grid container style={{
          justifyContent: "space-between",
          margin: "24px 0 0",
        }}>
          <Typography component="h1" variant="h4" color="secondary" gutterBottom
            className={classes.title}>
            <div>
              Recommendations AI {!isMobile && <span role="img" aria-label={"Robot"}>🤖</span>}
            </div>
          </Typography>
        </Grid>
        <p style={{ marginTop: 0 }}>We've crunched the data and these are the best offer
          combinations to boost your sales and
          delight your buyers</p>

        {isMobile && <RecommendationsFilters/>}

        <TableContainer className={"recommendations-table-container"} style={{
          marginBottom: 30,
          background: "none",
          border: "none",
          boxShadow: "none"
        }}>
          <RecommendationsLoaderPopup isConvertPopup={true}
            isFetching={isCreatingAllRecommendations}/>
          {isMobile ? <RecommendationsSortMobile/> :
            <div style={{
              display: "flex",
              flexDirection: "column"
            }}>
              <RecommendationsFilters/>
              <RecommendationsFilterBubbles/>
            </div>
          }
          {isMobile ? <RecommendationsCards
              sortOrder={sortData}
              sortHandler={sort}
              recommendations={recommendations}
              isFetching={isRecommendationsFetching}
              popupCallback={openPopup}
              showCheckbox={false}
              showMainProduct={true}
              scrollable={false}
              checkedProductsForwarder={setCheckedProducts}
              handleCreateAllUpsellsClick={handleCreateAllUpsellsClick}
              showCreateAllButton={showCreateAllButton}
            /> :
            <RecommendationsTable
              sortOrder={sortData}
              sortHandler={sort}
              recommendations={recommendations}
              isFetching={isRecommendationsFetching}
              popupCallback={openPopup}
              showCheckbox={false}
              showMainProduct={true}
              scrollable={false}
              checkedProductsForwarder={setCheckedProducts}
              handleCreateAllUpsellsClick={handleCreateAllUpsellsClick}
              showCreateAllButton={showCreateAllButton}
            />}
          <div style={{
            display: isMobile ? "inline" : "flex",
            marginRight: 35,
            justifyContent: "space-between",
          }}>
            <RecommendationsLimit/>
            {!isRecommendationsFetching &&
            (isMobile ? <RecommendationsPaginationMobile/> : <RecommendationsPagination/>)}
          </div>
        </TableContainer>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
  return {
    realIsRecommendationsFetching: state.reducerRecommendations.isFetching,
    realPgData: state.pagination.recommendations,
    realRecommendations: state.reducerRecommendations.items || false,
  };
};

export default connect(
  mapStateToProps,
  {
    ...recommendationsActions,
  },
)(RecommendationsContent);
