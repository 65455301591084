import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  crpopup: {
    position: "absolute !important",
    paddingTop: 0,
    paddingBottom: 0,
    background: "rgba(33, 42, 47, 0.35) !important",
    zIndex: "0 !important",
  },
  crpopupBody: {
    "&.slideout": {
      position: "absolute",
      transformOrigin: "top right",
      minWidth: "inherit",
    },
  },
  svgCheckmark: {
    marginRight: ".7rem",
  },
  button: {
    paddingLeft: "1rem",
  },
  buttonSvg: {
    width: "1.5rem",
    marginRight: ".8rem",
  },
  footer: {
    display: "block",
    width: "1.8rem",
    marginLeft: ".4rem",
  },
  slickFixSlidesWidth: {
    "& .slick-slide > div": {
      width: "100%",
    }
  },
  showHiddenOffers: {
    "& .offer-is-hidden": {
      opacity: 0.4,
    }
  },
  hideHiddenOffers: {
    "& .offer-is-hidden": {
      display: "none",
    }
  },
  offerAnalyticsStyle1: {
    display: "inline-flex",
    justifyContent: "flex-start",
    background: "#f3f4f6",
    padding: "5px 15px",
    borderRadius: 4,
    fontSize: 12,
    marginTop: 10,
    border: "1px solid #ccc",
  },
  offerAnalyticsStyle2: {
    display: "grid",
    padding: "5px 5px",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    marginTop: 0,
  },
  offerAnalyticsItem: {
    marginRight: 10,
  }
}));

/**
 * Replace all occurrences in a string.
 *
 * @param {string} originalString Raw string.
 * @param {string} find           Target key word or regex that need to be replaced.
 * @param {string} replace        Replacement key word.
 *
 * @return {string} Output string.
 */
function replaceAll(originalString, find, replace) {
  return originalString.replace(new RegExp(find, "g"), replace);
}

export default function PopupPreview({
  settings = {},
  product = {},
  offers = [],
  isLoading = true,
  heading = "",
  popupCloseCallback = null,
  showHiddenOffers = true,
  hasHiddenOffersCallback = null,
  showAnalytics = false,
}) {
  const classes               = useStyles();
  const { siteUrl }           = settings;
  const {
          name: productName   = "",
          price: productPrice = "",
          image: productImage = "",
        }                     = product;
  const [ layout, setLayout ] = useState(settings.upsells_global_popup_layout);
  const SlickArrowLeft        = ({
    currentSlide,
    slideCount,
    ...props
  }) => {
    props.className = clsx("ocu-offer-nav ocu-offer-nav__prev", props.className);

    return (
      <div {...props}>
        <svg className="ocu-offer-nav__icon" xmlns="http://www.w3.org/2000/svg" width="24"
          height="24" fill="currentColor" viewBox="0 0 16 16">
          <path fillRule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>
      </div>
    );
  };
  const SlickArrowRight       = ({
    currentSlide,
    slideCount,
    ...props
  }) => {
    props.className = clsx("ocu-offer-nav ocu-offer-nav__next", props.className);

    return (
      <div {...props}>
        <svg className="ocu-offer-nav__icon" xmlns="http://www.w3.org/2000/svg" width="24"
          height="24" fill="currentColor" viewBox="0 0 16 16">
          <path fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </div>
    );
  };
  const slickSettings         = {
    dots: false,
    arrows: true,
    prevArrow: <SlickArrowLeft/>,
    nextArrow: <SlickArrowRight/>,
    accessibility: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  offers = offers ? offers : [];

  useEffect(() => {
    if ( layout !== settings.upsells_global_popup_layout ) {
      // Use delay so that there is no flash because of HTML change during exit transition.
      window.setTimeout(() => {
        setLayout(settings.upsells_global_popup_layout);
      }, 150);
    }
    // eslint-disable-next-line
  }, [ settings ]);

  // Used so we can be sure that there are both actions in offers.
  let hasUpsells    = false,
      hasCrossSells = false;

  let cssVariables = [];
  Object.keys(settings)
    .forEach((key) => {
      if ( key.indexOf("color_") !== - 1 ) {
        cssVariables.push("--" + (replaceAll(key, "_", "-")
          .replace("upsells-color-", "ocu-")) + ":" + settings[ key ]);
      }
    });

  let tax_rate = 0.1;

  let offerList = [];
  offers.forEach((offer, i) => {
    let {
          name: offerName                      = "",
          price: offerPrice                    = "",
          image: offerImage                    = "",
          description: offerDescription        = "",
          price_promo: offerPricePromo         = "",
          apply_discount: offerApplyDiscount   = "",
          discount_type: offerDiscountType     = "",
          discount_amount: offerDiscountAmount = "",
          offer_intent: offerIntent            = "",
          revenue: offerRevenue                = "",
          conversion_rate: offerConversionRate = "",
          impressions: offerImpressions        = "",
          add_to_carts: offerAddToCarts        = "",
        }                 = offer;
    let offerIsUpsell     = offerIntent === "upsell";
    let offerIsHidden     = false;
    let offerHiddenReason = "unknown";
    let offerIsPreorder   = offer.is_preorder === true;

    if ( offer.is_preorder ? parseFloat(offer.preorder_quantity) <= 0 : parseFloat(offer.qty) <= 0 ) {
      offerIsHidden     = true;
      offerHiddenReason = (offer.is_preorder ? 'preorder' : 'available') + " quantity is 0 or lower";
    } else if ( parseInt(offer.active) === 0 ) {
      offerIsHidden     = true;
      offerHiddenReason = "product is not active";
    } else if ( parseInt(offer.approved) === 0 ) {
      offerIsHidden     = true;
      offerHiddenReason = "product is not approved";
    }

    let reducedPrice = false;

    if ( offerPricePromo ) {
      reducedPrice = offerPricePromo;
    }

    if ( settings.upsells_global_popup_show_price_with_tax === true ) {
      offerPrice = (- Math.round(parseFloat((- (offerPrice * ( 1 + tax_rate ) * 100)).toFixed(0))) / 100).toFixed(2);
      if ( reducedPrice ) {
        reducedPrice = (- Math.round(parseFloat((- (reducedPrice * ( 1 + tax_rate ) * 100)).toFixed(0))) / 100).toFixed(2);
      }
    }

    if ( offerApplyDiscount && offerDiscountAmount ) {
      let mainPrice = parseFloat(offerPricePromo ? offerPricePromo : offerPrice);

      if ( offerDiscountType === "percent" ) {
        reducedPrice = mainPrice - ((parseFloat(offerDiscountAmount) / 100) * mainPrice);
      } else {
        reducedPrice = mainPrice - parseFloat(offerDiscountAmount);
      }

      reducedPrice = Math.round(reducedPrice * 100) / 100;
    }

    let offer_quantity_dropdown = "";
    if ( (offer.show_quantity_dropdown === 1 || (offer.show_quantity_dropdown === 2 && settings.upsells_global_popup_show_qty_dropdown)) && offer.offer_intent !== "upsell" ) {
      let options_elements_count = [];
      for ( let i = 1; i <= 20; i ++ ) {
        options_elements_count.push(i);
      }

      offer_quantity_dropdown = <select className={"ocu-form-select ocu-product-card__quantity"}
        aria-label={"Offer quantity"}>
        {options_elements_count.map(i => {
          return (<option>{i}</option>);
        })}
      </select>;
    }

    if ( offerIsHidden && hasHiddenOffersCallback !== null ) {
      hasHiddenOffersCallback(true);
    }

    //language=HTML
    let style1 = <div className={(offerIsHidden ? "offer-is-hidden" : "")}>
      <article key={offerName}
        className={"ocu-product-card"}>
        <div className="ocu-product-card__main">
          <div className="ocu-product-card__image">
            <img className="ocu-product-card__img"
              src={offerImage ? offerImage : siteUrl + "/assets/na.gif"}
              alt={offerName}
              width="60"
              height="60"/>
            <svg className="ocu-check ocu-product-card__check"
              xmlns="http://www.w3.org/2000/svg"
              width="18" height="18" fill="currentColor"
              viewBox="0 0 16 16"
              aria-hidden="true">
              <path
                d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z">
              </path>
            </svg>
          </div>
          <div className="ocu-product-card__info">
            <h4 className="ocu-product-card__title">
              {/* eslint-disable-next-line */}
              <a className="ocu-product-card__title-link">
                {offerName}
              </a>
              {offerIsPreorder && <span className="ocu-product-card__title_preorder_badge">Preorder</span>}
            </h4>
            <div className="ocu-product-card__price">
              {reducedPrice && <s className="ocu-product-card__old-price">${offerPrice}</s>}
              <div
                className="ocu-product-card__new-price">${reducedPrice ? reducedPrice : offerPrice}</div>
            </div>
            {offerDescription &&
            <div className="ocu-product-card__description">{offerDescription}</div>}
          </div>
        </div>
        <div className="ocu-product-card__aside">
          {offer_quantity_dropdown}
          <button
            className="ocu-button ocu-button_style_outline-secondary ocu-product-card-button ocu-product-card-button_type_add"
            aria-label="Add to cart">
            {offerIsUpsell ?
              <svg className="ocu-product-card-button__icon" xmlns="http://www.w3.org/2000/svg"
                width="28" height="28" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"/>
              </svg> : <svg className="ocu-product-card-button__icon"
                xmlns="http://www.w3.org/2000/svg" width="28"
                height="28" fill="currentColor" viewBox="0 0 16 16"
                aria-hidden="true">
                <path
                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z">
                </path>
              </svg>}
            <span
              className="ocu-product-card-button__text">{offerIsUpsell ? "Upgrade" : "Add"}</span>
            <svg className="ocu-product-card-button__loader"
              xmlns="http://www.w3.org/2000/svg" width="16px"
              height="16px"
              viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
              aria-hidden="true">
              <circle cx="50" cy="50" fill="none" stroke="currentColor"
                strokeWidth="8px" r="35"
                strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate"
                  repeatCount="indefinite" dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"/>
              </circle>
            </svg>
          </button>
          <button
            className="ocu-button ocu-button_style_outline-secondary ocu-product-card-button ocu-product-card-button_type_remove has-removed"
            aria-label="Remove from cart">
            <svg className="ocu-product-card-button__icon"
              xmlns="http://www.w3.org/2000/svg" width="28"
              height="28" fill="currentColor" viewBox="0 0 16 16"
              aria-hidden="true">
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z">
              </path>
            </svg>
            <svg className="ocu-product-card-button__loader"
              xmlns="http://www.w3.org/2000/svg" width="16px"
              height="16px"
              viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
              aria-hidden="true">
              <circle cx="50" cy="50" fill="none" stroke="currentColor"
                strokeWidth="8px" r="35"
                strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate"
                  repeatCount="indefinite" dur="1s"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"/>
              </circle>
            </svg>
          </button>
        </div>
      </article>
      {showAnalytics &&
      <div className={classes.offerAnalyticsStyle1}>
        <div className={classes.offerAnalyticsItem}>
          Revenue: ${offerRevenue}
        </div>
        <div className={classes.offerAnalyticsItem}>
          Add to carts: {offerAddToCarts}
        </div>
        <div className={classes.offerAnalyticsItem}>
          Impressions: {offerImpressions}
        </div>
        <div className={classes.offerAnalyticsItem}>
          Conv. rate: {offerConversionRate}%
        </div>
      </div>
      }
    </div>;

    //language=HTML
    let style2 = <article className={"ocu-product-card " + (offerIsHidden ? "offer-is-hidden" : "")} key={offerName}
      style={{
        height: layout === "popup-2" ? "100%" : "initial",
      }}>
      {showAnalytics &&
      <div className={clsx(classes.offerAnalyticsStyle1, classes.offerAnalyticsStyle2)}>
        <div className={classes.offerAnalyticsItem}>
          Revenue: ${offerRevenue}
        </div>
        <div className={classes.offerAnalyticsItem}>
          Add to carts: {offerAddToCarts}
        </div>
        <div className={classes.offerAnalyticsItem}>
          Impressions: {offerImpressions}
        </div>
        <div className={classes.offerAnalyticsItem}>
          Conv. rate: {offerConversionRate}%
        </div>
      </div>
      }
      <img className="ocu-product-card__img" width="60" height="60"
        src={offerImage ? offerImage : siteUrl + "/assets/na.gif"}
        alt={offerName}/>
      <h4 className="ocu-product-card__title">
        {/* eslint-disable-next-line */}
        <a className="ocu-product-card__title-link">{offerName}</a>
        {offerIsPreorder && <span className="ocu-product-card__title_preorder_badge">Preorder</span>}
      </h4>
      <div className="ocu-product-card__price">
        {reducedPrice && <s className="ocu-product-card__old-price">${offerPrice}</s>}
        <div
          className="ocu-product-card__new-price">${reducedPrice ? reducedPrice : offerPrice}</div>
      </div>
      {offerDescription && <div className="ocu-product-card__description">{offerDescription}</div>}
      {offer_quantity_dropdown}
      <div className="ocu-product-card__actions">
        <button aria-label="Add to cart"
          className="ocu-button ocu-button_style_outline-secondary ocu-product-card-button ocu-product-card-button_type_add">
          <svg className="ocu-product-card-button__icon"
            xmlns="http://www.w3.org/2000/svg" width="28" height="28"
            fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z">
            </path>
          </svg>
          <span className="ocu-product-card-button__text">{offerIsUpsell ? "Upgrade" : "Add"}</span>
          <svg className="ocu-product-card-button__loader" width="16px" height="16px"
            viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" aria-hidden="true">
            <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="8px"
              r="35" strokeDasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate"
                repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50"
                keyTimes="0;1"/>
            </circle>
          </svg>
        </button>
        <button aria-label="Remove from cart"
          className="ocu-button ocu-button_style_outline-secondary ocu-product-card-button ocu-product-card-button_type_remove has-removed">
          <svg className="ocu-product-card-button__icon"
            xmlns="http://www.w3.org/2000/svg" width="28" height="28"
            fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z">
            </path>
          </svg>
          <svg className="ocu-product-card-button__loader" width="16px" height="16px"
            viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" aria-hidden="true">
            <circle cx="50" cy="50" fill="none" stroke="currentColor" strokeWidth="8px"
              r="35" strokeDasharray="164.93361431346415 56.97787143782138">
              <animateTransform attributeName="transform" type="rotate"
                repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50"
                keyTimes="0;1"/>
            </circle>
          </svg>
        </button>
      </div>
    </article>;

    let offerElement = layout === "popup-2" ? style2 : style1;

    if ( offerIsHidden ) {
      offerElement =
        <Tooltip title={"This offer will be hidden on site because " + offerHiddenReason + "."} placement={"top"}
          arrow>{offerElement}</Tooltip>;
    }

    offerList.push(offerElement);
  }, [ hasCrossSells, hasUpsells ]);

  const SlickItem = (props) => {
    return props.item;
  };

  return (<>
    <style>
      {":root{" +
      cssVariables.join(";\n") + ";\n" +
      "--ocu-gray-50: #F9FAFB;\n" +
      "--ocu-gray-100: #F3F4F6;\n" +
      "--ocu-gray-200: #E5E7EB;\n" +
      "--ocu-gray-300: #D1D5DB;\n" +
      "--ocu-gray-400: #9CA3AF;\n" +
      "--ocu-gray-500: #6B7280;\n" +
      "--ocu-gray-600: #4B5563;\n" +
      "--ocu-gray-700: #374151;\n" +
      "--ocu-gray-800: #1F2937;\n" +
      "--ocu-border-radius: 4px;\n" +
      "--ocu-gray-900: #111827;}" +
      ".ocu-popup {opacity: 0;visibility:hidden;}" +
      ".ocu-popup_show{transition-delay: 0s !important;}"}
    </style>
    <aside
      className={clsx(classes.crpopup, isLoading ? "ocu-popup ocu-popup_hide" : "ocu-popup ocu-popup_show")}>
      <div
        className={clsx(classes.crpopupBody, "ocu-popup__inner", layout, isLoading ? "loading" : null)}
        style={{
          height: layout === "slideout" ? "100%" : "initial",
        }}>
        <header className="ocu-popup__header"><h2
          className="ocu-popup__title">{heading ? heading : settings.upsells_global_popup_title_has_offers}</h2>
          <button className="ocu-button ocu-popup-close" aria-label="Close"
            onClick={popupCloseCallback}>
            <svg className="ocu-popup-close__icon" xmlns="http://www.w3.org/2000/svg" width="28"
              height="28"
              fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </button>
        </header>
        <div className="ocu-popup__body" style={{
          maxHeight: "calc(100% - 175px)"
        }}>
          <div className="ocu-product is-incart">
            <div className="ocu-product__main">
              <div className="ocu-product__image">
                <img className="ocu-product__img"
                  src={productImage ? productImage : siteUrl + "/assets/na.gif"}
                  alt={productName} width="60" height="60"/>
                <svg className="ocu-check ocu-product__check" xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                  <path
                    d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
              </div>
              <div className="ocu-product__info">
                <h3 className="ocu-product__title">{productName}</h3>
                <div className="ocu-product__price">
                  <span className="ocu-product__new-price">
                    {settings.upsells_global_popup_show_price_with_tax === true ? (productPrice ? "$" + (- Math.round(parseFloat((- (productPrice * ( 1 + tax_rate) * 100)).toFixed(0))) / 100).toFixed(2) : null) : (productPrice ? "$" + productPrice : null)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={clsx(layout === "popup-2" ? "ocu-offer ocu-offer_layout_slider" : "ocu-offer ocu-offer_layout_grid", hasHiddenOffersCallback !== null ? (showHiddenOffers ? classes.showHiddenOffers : classes.hideHiddenOffers) : null)}>
            <h3 className="ocu-offer__title">{settings.upsells_global_popup_heading_offers}</h3>
            {slickSettings.infinite = offerList.length > 3}
            {layout === "popup-2" ?
              <Slider {...slickSettings}
                className={clsx("ocu-offer__list", classes.slickFixSlidesWidth)}>
                {offerList.map((offer, i) => (<SlickItem item={offer} key={i}/>))}
              </Slider>
              :
              <div className="ocu-offer__list">
                {offerList.map(offer => (offer))}
              </div>
            }
          </div>
        </div>
        <footer className="ocu-popup__footer">
          <div className="ocu-popup__actions" style={{
            display: settings.upsells_global_popup_checkout_button_enabled ? "grid" : "flex",
          }}>
            <button className="ocu-button ocu-popup-button ocu-popup-button_type_continue">Continue
              Shopping
            </button>

            {/* eslint-disable-next-line */}
            <a className="ocu-button ocu-popup-button ocu-popup-button_type_cart" style={{
              textDecoration: "none",
              display: settings.upsells_global_popup_checkout_button_enabled ? "" : "none",
            }}>
              View Cart
            </a>
            <button
              className="ocu-button ocu-button_style_primary ocu-popup-button ocu-popup-button_type_checkout">
              {settings.upsells_global_popup_checkout_button_enabled ? "Checkout" : "View Cart"}
              {settings.upsells_global_popup_checkout_button_enabled &&
              <svg className="ocu-popup-button__icon" xmlns="http://www.w3.org/2000/svg" width="16"
                height="16"
                fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                <path fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
              }

            </button>
          </div>
          {settings.upsells_global_branding_enabled &&
          <div className="ocu-byline ocu-popup__byline">
            <div className="ocu-byline__icon">ⓘ</div>
            <a className="ocu-byline__tooltip" href="https://outsmart.digital/"
              rel="noopener noreferrer" target="_blank" aria-label="Powered by an Outsmart app">Powered
              by an Outsmart app →</a></div>}
        </footer>
      </div>
    </aside>
  </>);
}
