import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "../../Footer/Footer";
import notification from "../Components/notification";
import { CircularProgress } from "@material-ui/core";
import logo from "../../../assets/images/os-logo.svg";
import 'whatwg-fetch';
import { apiUrl } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  wrapper: {
    margin: 0,
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "15px",
    left: "47%",
  },
}));

export default function ResetPassword() {
  const classes               = useStyles();
  const [fData, setFdata]     = useState({ email: "" });
  const [isFetch, setIsFetch] = useState(false);
  const handleChange          = (e) => {
    setFdata({
      ...fData,
      [ e.target.name ]: e.target.value
    });
  };
  const { email = "" }        = fData;

  const sendLinkOnEmail = (e) => {
    setIsFetch(true);
    e.preventDefault();
    const recipeUrl       = apiUrl;
    const postBody        = {
      email: email,
    };
    const requestMetadata = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "*/*"
      },
      body: JSON.stringify(postBody)
    };

    fetch(recipeUrl, requestMetadata)
      .then(res => {
        setIsFetch(false);
        return res.json();
      })
      .then(res => {
        if ( res && res.status === "success" ) {
          notification({
            isOpen: true,
            type: "success",
            messageText: "Email sent. Please check your email.",
            permanent: true,
          });
        } else {
          notification({
            isOpen: true,
            type: "error",
            messageText: res.message,
            permanent: true,
          });
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <a href="/signin">
        <img style={{ width: "200px", margin: "16px 20px" }} src={logo} alt="logo" />
        </a>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} onSubmit={sendLinkOnEmail} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isFetch}
            >
              Reset Password
            </Button>
            {(isFetch) && <CircularProgress className={classes.progress} size={24}/>}
          </div>
        </form>
      </div>
      <Box mt={8}>
        <Footer/>
      </Box>
    </Container>
  );
};
