import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container, IconButton,
} from "@material-ui/core";
import DashboardRouter from "./DashboardRouter";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import { actions as subscriptionsActions } from "../../redux/reducers/subscriptions.resource";
import { actions as availableSubscriptionsActions } from "../../redux/reducers/availableSubscriptions.resource";
import { actions as settingsActions } from "../../redux/reducers/setting.resource";
import { actions as meActions } from "../../redux/reducers/me.resource";
import { actions as eventsActions } from "../../redux/reducers/event.resource";
import { actions as internalPluginActions } from "../../redux/reducers/internalPlugin.resource";
import "./app.scss";
import { pendo } from "./pendo";
import { actions as userActions } from "../../redux/reducers/users";
import LoaderProgress from "./LoaderProgress";
import InitialSyncIndicator from "../MainApp/Components/InitialSyncIndicator";
import loadDashly from "../../assets/js/loadDashly";
import loadEncharge from "../../assets/js/loadEncharge";
import clsx from "clsx";
import logo from "../../assets/images/os-logo-dark.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import "../MainApp/helpers/cache";
import { loadCachedRequestData, saveCachedRequestData } from "../MainApp/helpers/cache";
import { useTrackingScripts } from "../../config/config";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fcfcfd",
    display: "flex",
    "& > .MuiPaper-root": {
      boxShadow: "none",
    },
    "& > .MuiListItemIcon-root": {
      minWidth: "39px",
    }
  },
  rootColumn: {
    flexDirection: "column",
  },
  main: {
    flexGrow: 1,
    overflow: "auto",
  },
  mainContainer: {
    overflowY: "auto",
    height: "100vh",
    maxWidth: "none",
    padding: 0,
    backgroundColor: "#fcfcfd",
  },
  mainContainerMobile: {
    height: "calc(100vh - 80px)",
  },
  title: {
    flexGrow: 1,
  },
  menuStyle: {
    margin: "24px 0",
    padding: 0,
  },
  menuStyleCollapsed: {
    padding: "10px 6px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  sidebarHeader: {
    background: "#002937",
    display: "flex",
    position: "relative",
    zIndex: 1200,
  },
  menuButton: {
    marginLeft: theme.spacing(1),
    color: "#fff",
  },
  sidebarHeaderLogo: {
    cursor: "pointer",
    padding: 32,
  },
  sidebarHeaderLogoMobile: {
    cursor: "pointer",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

/**
 * Replace all occurrences in a string.
 *
 * @param {string} originalString Raw string.
 * @param {string} find           Target key word or regex that need to be replaced.
 * @param {string} replace        Replacement key word.
 *
 * @return {string} Output string.
 */
function replaceAll(originalString, find, replace) {
  return originalString.replace(new RegExp(find, "g"), replace);
}

function Dashboard({
  isAuth,
  getMe,
  getSubscriptions,
  logoutUser,
  realSubscriptions,
  internalPlugins,
  stateInternalPlugin,
  fetchInternalPlugins,
  realMe,
}) {
  const history                                               = useHistory();
  const classes                                               = useStyles();
  const [ didTrackingScriptsInit, setDidTrackingScriptsInit ] = useState(false);
  const [ sidebarOpen, setSidebarOpen ]                       = useState(false);
  const [ isMobile, setIsMobile ]                             = useState(window.innerWidth < 1024);
  const [ me, setMe ]                                         = useState(null);
  const [ subscriptions, setSubscriptions ]                   = useState(false);

  // Save updated subscriptions to the cache and load it.
  useEffect(() => {
    if ( realSubscriptions !== false ) {
      saveCachedRequestData(realSubscriptions, "subscriptions");
      setSubscriptions(realSubscriptions);
    }
  }, [ realSubscriptions ]);

  // Get internal plugins data.
  useEffect(() => {
    if ( internalPlugins === false ) {
      fetchInternalPlugins()
    }
  }, [ internalPlugins, fetchInternalPlugins ]);

  // Load cache and request fresh subscriptions, if not already set.
  useEffect(() => {
    if ( subscriptions === false ) {
      setSubscriptions(loadCachedRequestData("subscriptions", {}));
      getSubscriptions();
    }
  }, [ getSubscriptions, subscriptions ]);

  // Save updated me to the cache and load it.
  useEffect(() => {
    if ( realMe !== false ) {
      saveCachedRequestData(realMe, "me");
      setMe(realMe);
    }
  }, [ realMe ]);

  // Load cache and request fresh me, if not already set.
  useEffect(() => {
    if ( ! me ) {
      setMe(loadCachedRequestData("me", false));
      getMe();
    }
  }, [ getMe, me ]);

  useEffect(() => {
    if ( me && useTrackingScripts && ! didTrackingScriptsInit ) {
      // Klaviyo identification.
      const _learnq = window._learnq || [];
      _learnq.push([ "identify", {
        "$id": me.track_id,
        "$email": me.email,
        "$first_name": me.first_name,
        "$last_name": me.last_name
      } ]);

      // Dashly identification.
      loadDashly(() => {
        typeof window.dashly !== "undefined" && window.dashly && typeof window.dashly.auth !== "undefined" && window.dashly.auth(me.track_id, me.dashly_hash);
        typeof window.dashly !== "undefined" && window.dashly && typeof window.dashly.identify !== "undefined" && window.dashly.identify({
          "$name": me.first_name + " " + me.last_name,
          "$email": me.email,
          "$first_name": me.first_name,
          "$last_name": me.last_name,
        });
      });

      // EnCharge identification.
      loadEncharge(() => {
        window.EncTracking.identify({
          email: me.email,
          userId: me.track_id,
          name: me.first_name + " " + me.last_name
        });
      });

      // Pendo script init.
      const elem = document.getElementById("pendo-script");
      if ( ! elem ) {
        const script = document.createElement("script");
        script.setAttribute("id", "pendo-script");
        let scriptText   = pendo;
        scriptText       = replaceAll(scriptText, "{id}", me.id);
        scriptText       = replaceAll(scriptText, "{email}", me.email);
        scriptText       = replaceAll(scriptText, "{full_name}", me.full_name);
        script.innerHTML = scriptText;
        document.body.appendChild(script);
      }

      setDidTrackingScriptsInit(true);
    }
  }, [ didTrackingScriptsInit, me ]);

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  // Used to not show initial sync event if OCU is not active.
  let isOcuActive = false;
  Object.values(subscriptions).forEach(subscription => {
    if ( subscription.plan.product === 'prod_Ji6g9qtr2IPDqX' ) {
      switch ( subscription.status ) {
        case "active":
        case "trialing":
        case "incomplete":
        case "past_due":
        case "unpaid":
          isOcuActive = true;
          break;
        default:
          break;
      }
    }
  });

  return (
    <div className={clsx(classes.root, isMobile ? classes.rootColumn : null)}>
      <div>
        <div className={clsx(classes.sidebarHeader)} style={{
          padding: isMobile ? "16px 0" : 0,
        }}>
          {isMobile &&
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setSidebarOpen(! sidebarOpen)}
            edge="start"
            className={clsx(classes.menuButton)}
          >
            {sidebarOpen ? <CloseIcon/> : <MenuIcon/>}
          </IconButton>
          }
          <div onClick={() => history.push("/")}
            className={clsx(isMobile ? classes.sidebarHeaderLogoMobile : classes.sidebarHeaderLogo)}>
            <img style={{
              maxWidth: "100%",
              height: "40px",
            }} src={logo} alt="logo"/>
          </div>
        </div>
        <Sidebar
          subscriptions={subscriptions}
          internalPlugins={internalPlugins}
          me={me}
          logoutUser={logoutUser}
          isMobile={isMobile}
          open={sidebarOpen}
          setOpen={setSidebarOpen}
        />
      </div>
      <main className={classes.main}>
        <Container
          className={clsx(classes.mainContainer, isMobile ? classes.mainContainerMobile : null)}>
          <LoaderProgress/>
          {me && me.is_initial_sync_complete === false && isOcuActive &&
          <InitialSyncIndicator getMe={getMe} events={me.events}/>}
          <DashboardRouter isAuth={isAuth}/>
        </Container>
      </main>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  internalPlugins: state.reducerInternalPlugin.lastUpdated === 0 ? false : state.reducerInternalPlugin.items,
  stateInternalPlugin: state.reducerInternalPlugin,
  realSubscriptions: state.reducerSubscriptions.item || false,
  realMe: state.reducerMe.item || false,
});

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
    ...subscriptionsActions,
    ...availableSubscriptionsActions,
    ...meActions,
    ...eventsActions,
    ...userActions,
    ...internalPluginActions,
  },
)(Dashboard);
