import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "product",
  url: `${apiUrl}/products/:id`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: (res) => ({
        body: res.body ? res.body.products : [],
        resourceName: "products",
        pagination: {
          currentPageNumber: res.body.currentPage,
          totalPages: res.body.totalPages || false,
          numItemsPerPage: res.body.itemsPerPage || false,
          productsCount: res.body.productsCount || false,
        },
      }),
    },
    get: {
      method: "GET",
      transformResponse: res => ({
        body: (res.body && res.body.products)
          ? res.body.products
          : false
      }),
    },
    update: {
      method: "PUT",
      transformResponse: (res) => ({
        data: res.body.data,
        message: res.body.message,
      }),
    },
  },
});
