import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "contentUpsell",
  url: `${apiUrl}/content`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: (res) => ({
        body: res.body ? res.body.items : [],
      }),
    },
  },
});
