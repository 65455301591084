import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container, Grid, Typography,
} from "@material-ui/core";
import "whatwg-fetch";
import { makeStyles } from "@material-ui/core/styles";
import SocialLoginNetworkCard from "../Components/SocialLogin/SocialLoginNetworkCard";
import notification from "../Components/notification";
import { actions as settingsActions } from "../../../redux/reducers/setting.resource";
import { actions as internalPluginActions } from "../../../redux/reducers/internalPlugin.resource";

let resizeTimeout = false;
const useStyles   = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
    flexFlow: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
  },
  networkCards: {
    display: 'flex',
    margin: '2rem -20px',
  }
}));

function SocialLoginNetworks({
  settings,
  getSetting,
  updateSetting,
  createInternalPlugin,
}) {
  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 1024);
  const classes                   = useStyles();

  // Recheck if it's phone or desktop on window resize.
  useEffect(() => {
    const resListener = () => {
      if ( resizeTimeout ) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 80);
    };
    window.addEventListener("resize", resListener);
  });

  useEffect(
    () => {
      if ( ! settings ) {
        getSetting()
          .then(res => {
            if ( ! res.body ) {
              notification({
                isOpen: true,
                type: "error",
                messageText: res.message || "Unknown error.",
              });
            }
          });
      }
    }, [ settings, getSetting ],
  );

  const availableNetworks = [
    {
      'id': 'google',
      'name': 'Google',
      'icon': '',
      'setupGuideUrl': 'https://docs.outsmart.digital/article/2488',
      'requiredParameters': [
        {
          'id': 'client-id',
          'title': 'Client ID',
          'type': 'input',
        },
        {
          'id': 'client-secret',
          'title': 'Client Secret',
          'type': 'input',
        },
        {
          'id': 'show-login-popup',
          'title': 'Show login popup',
          'type': 'toggle',
          'default': true,
        },
        {
          'id': 'show-login-popup-all-pages',
          'title': 'Show login popup on all pages + Allow login button to be shown on all pages',
          'type': 'toggle',
          'default': false,
        }
      ]
    },
    {
      'id': 'facebook',
      'name': 'Facebook',
      'icon': '',
      'setupGuideUrl': 'https://docs.outsmart.digital/article/2760',
      'requiredParameters': [
        {
          'id': 'app-id',
          'title': 'App ID',
          'type': 'input',
        },
        {
          'id': 'show-login-popup-all-pages',
          'title': 'Allow login button to be shown on all pages',
          'type': 'toggle',
          'default': false,
        }
      ]
    }
  ];

  return (
    <Container maxWidth="xl">
      <Grid className={classes.root} item xs={12} sm={12}>
        <Typography component="h2" variant="h6" color="secondary" gutterBottom
          className={classes.title} style={{
          display: isMobile ? "inherit" : "flex",
          textAlign: "center",
        }}>
          Social Login | Networks
        </Typography>
        <p>You can edit the credentials of existing networks or set up new social login networks here.</p>
        <div className={classes.networkCards}>
          {availableNetworks.map(network => <SocialLoginNetworkCard
            network={network}
            settings={settings}
            updateSetting={updateSetting}
            key={network.id}
            createInternalPlugin={createInternalPlugin}
          />)}
        </div>
      </Grid>
    </Container>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  settings: state.reducerSettings.item,
});

export default connect(
  mapStateToProps,
  {
    ...settingsActions,
    ...internalPluginActions,
  },
)(SocialLoginNetworks);
