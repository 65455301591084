/**
 * Shows a confirmation dialog and executes the actions depending on user answer.
 *
 * @param properties
 */
export function confirm(properties) {
  // Merge with defaults.
  properties = {
    ...{
      text: "Are you sure?",
      confirmAction: () => {
      },
      cancelAction: () => {
      },
    }, ...properties
  };

  window.confirm(properties.text) ? properties.confirmAction() : properties.cancelAction();
}
