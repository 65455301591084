import React, { useEffect, useState } from "react";
import {
  Button, Checkbox, Table, TableBody, TableCell,
  TableHead, TableRow, TableSortLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CurrencyFormat from "react-currency-format";
import Truncate from "react-truncate";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: "1.75rem",
    lineHeight: "2rem",
    fontWeight: 600,
    marginTop: 3,
    marginBottom: 24,
  },
  margin: {
    minWidth: "200px",
    marginRight: theme.spacing(3),
  },
  packages: {
    minWidth: "20%",
  },
  productName: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "calc(50px + 1rem)",
    fontSize: "12px",
  },
  productSku: {
    display: "block",
    color: "#888",
    fontSize: 10,
  },
  productAddIcon: {
    fontSize: 24,
    color: "#b3b3b3",
    margin: ".5rem -24px .5rem auto",
    float: "right",
    userSelect: "none",
  },
  actions: {
    textAlign: "center",
    padding: "5px",
    minWidth: "105px"
  },
  table: {
    minWidth: 650,
    background: "white",
  },
  tableHead: {
    fontSize: "13px",
    color: "#999",
    fontWeight: 500,
  },
  tableCellChromeBorderStickyFix: {
    transform: "scale(0.999)",
  },
  tableCellImage: {
    width: "50px",
  },
  scrollableTableBody: {
    overflowX: "scroll",
    maxHeight: 400,
  },
}));

export default function RecommendationsTable({
  sortOrder,
  sortHandler,
  recommendations,
  isFetching,
  popupCallback,
  showMainProduct: showMainProductAndSecondOffer,
  scrollable,
  checkedProductsForwarder,
  handleCreateAllUpsellsClick,
  showCreateAllButton,
}) {
  const classes                                             = useStyles();
  const [checkedProductsTracker, setCheckedProductsTracker] = useState({});
  const [checkedAllProducts, setCheckedAllProducts]         = useState(false);

  useEffect(() => {
    if ( checkedProductsForwarder ) {
      checkedProductsForwarder(checkedProductsTracker);
    }
  }, [checkedProductsTracker, checkedProductsForwarder]);

  /**
   * Returns the product data based on the SKU.
   *
   * @param {string} sku The SKu to look for.
   *
   * @return {Object} The product data.
   */
  const getProductInfo = (sku) => {
    return typeof recommendations.skus_data[ sku ] !== "undefined" ? recommendations.skus_data[ sku ] : {
      "name": "",
      "image": "",
    };
  };

  const checkProduct = (id) => {
    let checkedProducts = checkedProductsTracker;
    if ( Object.keys(checkedProductsTracker)
      .indexOf(id.toString()) === -1 ) {
      checkedProducts[ id ] = false;
    }

    checkedProducts = { ...checkedProducts, ...{ [ id ]: !checkedProducts[ id ] } };

    setCheckedProductsTracker(checkedProducts);
  };

  const checkAllProductsOnPage = () => {

    if ( Object.keys(checkedProductsTracker).length < recommendations.recommendations.length ) {
      let checkedProducts = {};
      recommendations.recommendations.forEach((recommendation) => {
        checkedProducts[ recommendation.id ] = true;
      });
      setCheckedProductsTracker(checkedProducts);
      setCheckedAllProducts(true);
    } else {
      setCheckedProductsTracker({});
      setCheckedAllProducts(false);
    }
  };

  const Recommendation = ({
    recommendation,
    checkData,
  }) => {
    return (
      <TableRow key={getProductInfo(recommendation.main).name}>
        <TableCell component="th" scope="row" width={"30px"} padding="checkbox">
          <Checkbox
            checked={Object.keys(checkData)
              .indexOf(recommendation.id.toString()) === -1 ? false : checkData[ recommendation.id ]}
            onChange={() => checkProduct(recommendation.id)}
          />
        </TableCell>
        {showMainProductAndSecondOffer && <TableCell component="th" scope="row" width="22%"
          className={clsx(classes.mainProduct, classes.packages)}>
          <div style={{
            display: "flex",
            position: "relative"
          }}>
            <div>
              <img src={getProductInfo(recommendation.main).image}
                alt={getProductInfo(recommendation.main).name}
                className={clsx(classes.tableCellImage)}/>
            </div>
            <div className={classes.productName}>
              <Truncate lines={3}>{getProductInfo(recommendation.main).name}</Truncate>
              {recommendation.main && <span className={classes.productSku}>SKU: {recommendation.main}</span>}
            </div>
            <div className={classes.productAddIcon}>
              +
            </div>
          </div>
        </TableCell>}
        <TableCell component="th" scope="row" className={clsx(classes.offer1, classes.packages)}
          width={showMainProductAndSecondOffer ? "22%" : "67%"}>
          <div style={{
            display: "flex",
            position: "relative"
          }}>
            <div>
              <img src={getProductInfo(recommendation.offer1).image}
                alt={getProductInfo(recommendation.offer1).name}
                className={clsx(classes.tableCellImage)}/>
            </div>
            <div className={classes.productName}>
              <Truncate lines={3}>{getProductInfo(recommendation.offer1).name}</Truncate>
              {recommendation.offer1 && <span className={classes.productSku}>SKU: {recommendation.offer1}</span>}
            </div>
            {showMainProductAndSecondOffer && recommendation.offer2 &&
            <div className={classes.productAddIcon}>
              +
            </div>}
          </div>
        </TableCell>
        {showMainProductAndSecondOffer &&
        <TableCell component="th" scope="row" className={clsx(classes.offer2, classes.packages)}
          width="22%">
          <div style={{
            display: "flex",
            position: "relative"
          }}>
            <div>
              <img src={getProductInfo(recommendation.offer2).image}
                alt={getProductInfo(recommendation.offer2).name}
                className={clsx(classes.tableCellImage)}/>
            </div>
            <div className={classes.productName}>
              <Truncate lines={3}>{getProductInfo(recommendation.offer2).name}</Truncate>
              {recommendation.offer2 && <span className={classes.productSku}>SKU: {recommendation.offer2}</span>}
            </div>
          </div>
        </TableCell>}
        <TableCell align="right" component="th" scope="row"
          width={showMainProductAndSecondOffer ? "10%" : "15%"}>
          <b><CurrencyFormat value={recommendation.revenue.toFixed(2)} displayType={"text"}
            thousandSeparator={true} prefix={"$"}/></b>
        </TableCell>
        <TableCell align="right" component="th" scope="row"
          width={showMainProductAndSecondOffer ? "14%" : "15%"}>
          {recommendation.bought}
        </TableCell>
        {popupCallback && <TableCell component="th" scope="row" align="center" width="10%">
          <section className={classes.actions}>
            <Button size="small" color="primary" variant="contained" style={{
              padding: "3px 5px",
              fontSize: "12px",
            }} onClick={() => {
              popupCallback(recommendation.main, getProductInfo(recommendation.main).name);
            }}>Create Upsell</Button>
          </section>
        </TableCell>}
      </TableRow>
    );
  };

  return (
    <Table className={classes.table} aria-label="simple table" stickyHeader={true}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell padding="checkbox" width={"48px"}
            className={classes.tableCellChromeBorderStickyFix}>
            <Checkbox
              checked={checkedAllProducts}
              onChange={checkAllProductsOnPage}
            />
          </TableCell>
          <TableCell width={showMainProductAndSecondOffer ? "22%" : "67%"}
            className={classes.tableCellChromeBorderStickyFix}>
            {showMainProductAndSecondOffer ? "POTENTIAL PACKAGES" : "OFFERS"}
          </TableCell>
          {showMainProductAndSecondOffer &&
          <TableCell width="22%">
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleCreateAllUpsellsClick}
              style={{
                fontSize: "12px",
                display: showCreateAllButton ? "revert" : "none",
              }}
            >Create All Upsells</Button>
          </TableCell>}
          {showMainProductAndSecondOffer && <TableCell width="22%"/>}
          <TableCell width={showMainProductAndSecondOffer ? "10%" : "15%"} align="left"
            className={classes.tableCellChromeBorderStickyFix}
            style={{
              textAlign: "end",
            }}
          >
            <TableSortLabel
              align="right"
              active={sortOrder.orderby === "revenue"}
              direction={sortOrder.orderby === "revenue" ? sortOrder.order : "desc"}
              onClick={() => sortHandler("revenue")}
            >
              TOTAL SALES
            </TableSortLabel>
          </TableCell>
          <TableCell width={showMainProductAndSecondOffer ? "14%" : "15%"} align="left"
            className={classes.tableCellChromeBorderStickyFix}
            style={{
              textAlign: "end",
            }}
          >
            <TableSortLabel
              align="right"
              active={sortOrder.orderby === "bought"}
              direction={sortOrder.orderby === "bought" ? sortOrder.order : "desc"}
              onClick={() => sortHandler("bought")}
            >
              ORDERED TOGETHER
            </TableSortLabel>
          </TableCell>
          {popupCallback && <TableCell align="right" width="10%"/>}
        </TableRow>
      </TableHead>
      <TableBody className={scrollable ? classes.scrollableTableBody : null}>
        {!isFetching && recommendations !== false && Array.isArray(recommendations.recommendations) &&
        (recommendations.recommendations.length ? recommendations.recommendations.map((recommendation, i) => {
            return <Recommendation
              recommendation={recommendation}
              checkData={checkedProductsTracker}
              key={recommendation.main + i}
            />;
          })
          : <tr>
            <td colSpan={3} style={{ padding: 17 }}>There are no recommendations at this moment. Try
              expanding the date range.
            </td>
          </tr>)}
      </TableBody>
    </Table>
  );
}
