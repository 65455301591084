import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Home from "@material-ui/icons/Home";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import MenuTooltip from "./MenuTooltip";
import {ContactMail, FiberNew, KeyboardArrowDown, Launch, Mail} from "@material-ui/icons";
import { Avatar, Button, Divider, Drawer } from "@material-ui/core";
import Truncate from "react-truncate";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Popper from "@material-ui/core/Popper";

function TooltipWrapper({
  isSidebarOpen,
  content,
  title
}) {
  return isSidebarOpen
    ? content
    : (
      <MenuTooltip title={title} arrow placement="right">
        {content}
      </MenuTooltip>
    );
}

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paper: {
    borderRadius: "unset",
  },
  subheader: {
    paddingLeft: "16px",
  },
  sidebar: {
    minHeight: "calc(100vh - 109px)",
    height: "calc(100vh - 109px)",
    position: "relative",
    whiteSpace: "nowrap",
    boxShadow: "none !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "visible",
    background: "#002937",
    color: "#ffffff",
  },
  sidebarMarginTop: {
    minHeight: "calc(100vh - 80px)",
    height: "calc(100vh - 80px)",
  },
  menuScroll: {
    overflow: "auto",
    "&.overflow-shadow": { // Remove this class to enable overflow shadow. Not finished, need to increase the shadow depth.
      background: "" +
        "linear-gradient( rgb(0, 41, 55), rgba(0, 41, 55,0) ), " + // Shadow mask top.
        "linear-gradient( rgba(255,255,255,0), rgb(0, 41, 55) ) 0 100%, " + // Shadow mask bottom.
        "linear-gradient( rgba(0,0,0,.2), rgba(0,0,0,0.0) ), " + // Shadow top.
        "linear-gradient( rgba(0,0,0,0), rgba(0,0,0,0.2) ) 0 100%", // Shadow bottom.
      backgroundRepeat: "no-repeat",
      backgroundColor: "transparent",
      backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
      backgroundAttachment: "local, local, scroll, scroll",
    }
  },
  divider: {
    margin: "0 20px 0 57px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  link: {
    textDecoration: "none",
    color: "#eeeef0",
    transition: "background-color .1s ease-in-out",
    margin: 0,
    padding: 0,
    "&.is-subitem": {
      width: 230,
      display: "block",
      float: "right",
    }
  },
  linkItem: {
    margin: 0,
    padding: ".25rem 1.5rem .25rem 2rem",
    fontSize: ".945rem",
    lineHeight: "1.25rem",
    fontWeight: 400,
    "&:hover, &.Mui-Selected": {
      background: "rgba(0,0,0,0.3)",
      color: "#ffffff",
    },
    "&:hover svg": {
      color: "#ffffff",
    },
  },
  linkIcon: {
    color: "#eeeef0",
    width: 16,
    height: 16,
  },
  linkSelectedIndicator: {
    background: theme.palette.primary.main,
    height: "100%",
    display: "block",
    position: "absolute",
    left: 0,
    width: 3,
  },
  footerLinks: {
    display: "flex",
    padding: 24,
    height: 48,
    alignItems: "center",
    justifyContent: "space-between",
    "& a": {
      color: "white",
      textDecoration: "none",
      fontSize: 12,
      "&:hover": {
        textDecoration: "underline",
      }
    },
    "& svg": {
      height: 13,
      marginBottom: - 2,
      marginLeft: - 2,
    }
  },
  beamerButton: {
    color: "white",
    padding: 0,
    textTransform: "none",
    fontSize: 12,
    fontWeight: 400,
    "& .beamer_icon": {
      marginRight: - 22,
    }
  },
  profile: {
    padding: "0 24px",
    display: "flex",
    height: 80,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  profileImageWrapper: {
    marginRight: "1rem",
  },
  profileImage: {
    width: 32,
    height: 32,
    fontSize: 15,
  },
  profileInfoWrapper: {
    maxWidth: 128,
    overflow: "hidden",
  },
  profileName: {
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  profileEmail: {
    fontWeight: 400,
    fontSize: ".875rem",
    lineHeight: "1.25rem",
    color: "rgb(214, 214, 231)",
  },
  profileArrow: {
    marginLeft: ".5rem",
    color: "rgb(182,183,213)",
    width: 24,
    height: 24,
    "& svg": {
      width: 28,
      height: 28,
    }
  },
  profileMenuWrapper: {
    position: "fixed",
    bottom: 100,
    width: 256,
    padding: 20,
  },
  profileMenu: {
    padding: 0,
    "& > div": {
      padding: "10px 20px",
      cursor: "pointer",
      borderRadius: 6,
      "&:hover": {
        background: "#efefef",
      }
    }
  },
  trialWrapper: {
    display: "flex",
    padding: "0 24px",
    height: 60,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  trialDays: {
    border: "1px solid #efefef",
    padding: "1px 5px",
    background: "#fff",
    color: "#002937",
    fontSize: "large",
    borderRadius: "3px",
    marginRight: "1rem",
  },
  trialText: {
    width: 155,
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
}));

function Sidebar({
  subscriptions,
  internalPlugins,
  me,
  logoutUser,
  open,
  setOpen,
  isMobile,
}) {
  const history                         = useHistory();
  const location                        = useLocation();
  const classes                         = useStyles();
  const [ profileOpen, setProfileOpen ] = useState(false);
  const profileRef                      = React.useRef(null);

  /* Check if in trial, and calculate remaining days in trial. */
  let isInTrial        = false;
  let daysLeftInTrial  = 0;
  let showOcu          = false;
  let showSocialLogin  = false;
  let showPrettyEmails = true; // Show it to all as CTA.
  let showSmartAddress = false;
  Object.keys(subscriptions)
    .forEach((key) => {
      let subscription = subscriptions[ key ];
      if ( isInTrial === false ) {
        isInTrial       = subscription.status === "trialing";
        // 86400000 = day in ms
        daysLeftInTrial = Math.round((subscription.trial_end * 1000 - Date.now()) / 86400000);
        daysLeftInTrial = daysLeftInTrial === 0 ? "< 1" : daysLeftInTrial;
      }
      if ( showOcu === false ) {
        showOcu = subscription.plan.product === "prod_Ji6g9qtr2IPDqX" && (subscription.status === "active" || isInTrial);
      }
      if ( showSocialLogin === false ) {
        showSocialLogin = subscription.plan.product === "prod_KpVoGKwxadTIxl" && subscription.status === "active";
      }
      if (showSmartAddress === false){
        showSmartAddress = subscription.plan.product === "prod_ORdwE9RueQG4sc" && subscription.status === "active";
      }
    });

  const mainItems             = [
    {
      link: "/",
      icon: Home,
      title: "Applications",
    },
  ];
  const applicationsMenuItems = [
    {
      link: "/upsells",
      icon: AddShoppingCartIcon,
      title: "One Click Upsells",
      visible: showOcu,
      items: [
        {
          link: "/upsells",
          title: "All Upsells",
        },
        {
          link: "/upsells/new",
          title: "Add Upsell",
        },
        {
          link: "/upsells/recommendations",
          title: "Recommendations AI 🤖",
        },
        {
          link: "/upsells/settings",
          title: "Settings",
        },
        {
          link: "/upsells/analytics",
          title: "Analytics",
          isActive: false,
        },
      ]
    },
    {
      link: "/social-login",
      icon: AddShoppingCartIcon,
      title: "Social Login",
      visible: showSocialLogin,
      items: [
        {
          link: "/social-login",
          title: "Get started",
        },
        {
          link: "/social-login/analytics",
          title: "Analytics",
        },
        {
          link: "/social-login/networks",
          title: "Networks",
        },
        {
          link: "/social-login/settings",
          title: "Settings",
        },
      ]
    },
    {
      link: "/pretty-emails",
      icon: Mail,
      title: "PrettyEmails",
      visible: showPrettyEmails,
      isNew: true,
      items: [
        {
          link: "/pretty-emails",
          title: "Templates",
        }
      ]
    },
    {
      link: "/smart-address",
      icon: ContactMail,
      title: "SmartAddress",
      visible: showSmartAddress,
      isNew: true,
      items: [
        {
          link: "/smart-address",
          title: "Get Started",
        }
      ]
    }
  ];

  const [ fakePath, setFakePath ] = useState(location.pathname);
  useEffect(() => {
    if ( fakePath !== location.pathname ) {
      setFakePath(location.pathname);
    }
    // eslint-disable-next-line
  }, [ location.pathname ]);

  const MenuItem = function ({
    path,
    Icon,
    title,
    items,
    isActive,
    classes,
    location,
    isSubitem,
    visible,
    isNew,
  }) {
    const selected = items ? new RegExp(path).test(fakePath) : path === fakePath;
    return isActive ? (
      visible ? <>
        <RouterLink to={isMobile && items ? location.pathname : path}
          className={clsx(classes.link, isSubitem ? "is-subitem" : "")}>
          <ListItem button selected={selected}
            className={clsx(classes.root, classes.linkItem)}
            onClick={() => {
              setOpen(!! (isMobile && items));
              setFakePath(path);
            }}
          >
            {selected && ! isSubitem && <div className={classes.linkSelectedIndicator}/>}
            {Icon && <TooltipWrapper
              title={title}
              content={
                <ListItemIcon>
                  <Icon className={classes.linkIcon}/>
                </ListItemIcon>
              }
            />}
            <ListItemText primary={title}/>
            {isNew ? <FiberNew/> : null}
          </ListItem>
        </RouterLink>
        {selected && items && items.map((item) => <MenuItem
          key={item.title}
          path={item.link}
          title={item.title}
          Icon={item.icon ?? ""}
          isActive={item.isActive ?? true}
          classes={classes}
          location={location}
          isSubitem={true}
          visible={visible}
        />)}
      </> : null
    ) : (
      visible ? <div className={clsx(classes.link, isSubitem ? "is-subitem" : "")}>
        <TooltipWrapper
          isSidebarOpen={false}
          content={
            <ListItem button className={clsx("disabled-item", classes.linkItem)}>
              {Icon && <ListItemIcon><Icon className={classes.linkIcon}/></ListItemIcon>}
              <ListItemText primary={title}/>
              {isNew ? <FiberNew/> : null}
            </ListItem>}
          title="Coming soon"
        />
      </div> : null
    );
  };

  /**
   * Creates a RGB color based on the provided seed.
   * @param {string} str The seed.
   * @return {string} The hexadecimal color, including hash (e.g. #d7240d).
   */
  const stringToColour = function (str) {
    let hash = 0;
    for ( let i = 0; i < str.length; i ++ ) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = "#";
    for ( let i = 0; i < 3; i ++ ) {
      let value = (hash >> (i * 8)) & 0xFF;
      colour += ("00" + value.toString(16)).substr(- 2);
    }
    return colour;
  };

  const logout = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if ( urlParams.get("s") ) {
      window.history.pushState({}, "", "/");
    }
    logoutUser();
  };

  const handleProfileClose = (e) => {
    if ( profileRef.current && profileRef.current.contains(e.target) ) {
      return;
    }

    setProfileOpen(false);
  };

  const handleProfileOpen = () => {
    setProfileOpen(true);
  };

  return (
    <Drawer
      variant={isMobile ? "temporary" : "permanent"}
      classes={{
        paper: clsx(classes.sidebar, isMobile ? classes.sidebarMarginTop : null),
      }}
      style={{
        width: 256,
        top: 80,
      }}
      BackdropProps={{ style: { top: isMobile ? 80 : 0 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className={classes.menuScroll} style={{
        maxHeight: "calc(100vh - " + (isInTrial ? 299 : 239) + "px)"
      }}>
        <List className={classes.menuStyle}>
          {mainItems.map(item => (
            <MenuItem
              key={item.title}
              path={item.link}
              title={item.title}
              Icon={item.icon ?? ""}
              isActive={item.isActive ?? true}
              classes={classes}
              location={location}
              visible={item.visible ?? true}
              isNew={item.isNew ?? false}
            />
          ))}
        </List>
        <Divider className={classes.divider}/>
        <List className={classes.menuStyle}>
          {applicationsMenuItems.map(item => (
            <MenuItem
              key={item.title}
              path={item.link}
              title={item.title}
              Icon={item.icon ?? ""}
              items={item.items ?? []}
              isActive={item.isActive ?? true}
              classes={classes}
              location={location}
              visible={item.visible ?? true}
              isNew={item.isNew ?? false}
            />
          ))}
        </List>
      </div>
      <Divider className={classes.divider} style={{
        margin: "auto 0 0",
        backgroundColor: "rgba(0,0,0,0.25)"
      }}/>

      {isInTrial && <>
        <div
          className={classes.trialWrapper}
          id="trialIndicator"
          onClick={() => history.push("/subscriptions")}
        >
          <div className={classes.trialDays}>{daysLeftInTrial}</div>
          <span
            className={classes.trialText}>Day{typeof daysLeftInTrial === "number" && daysLeftInTrial > 1 ? "s" : ""} left in trial</span>
        </div>
        <Divider className={classes.divider} style={{
          margin: 0,
          backgroundColor: "rgba(0,0,0,0.25)"
        }}/>
      </>}

      {me && <>
        <div className={classes.profile} onClick={handleProfileOpen}>
          <div className={classes.profileImageWrapper}>
            <Avatar
              className={classes.profileImage}
              alt={me.full_name.charAt(0)}
              src={me.image + "?d=404"}
              style={{
                background: stringToColour(me.email)
              }}
            />
          </div>
          <div className={classes.profileInfoWrapper}>
            <div className={classes.profileName}><Truncate width={128}
              title={me.full_name}>{me.full_name}</Truncate>
            </div>
            <div className={classes.profileEmail}><Truncate width={128}
              title={me.email}>{me.email}</Truncate></div>
          </div>
          <div className={classes.profileArrow}>
            <KeyboardArrowDown/>
          </div>
        </div>
        <Divider className={classes.divider} style={{
          margin: 0,
          backgroundColor: "rgba(0,0,0,0.25)"
        }}/>
      </>}
      <Popper open={profileOpen} anchorEl={profileRef.current} role={undefined}
        style={{
          zIndex: 9999,
          top: "initial"
        }}
        className={classes.profileMenuWrapper}
        transition disablePortal>
        {({
          TransitionProps
        }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleProfileClose}>
                <MenuList autoFocusItem={false} id="menu-list-grow" className={classes.profileMenu}>
                  <div onClick={(e) => {
                    setOpen(false);
                    handleProfileClose(e);
                    history.push("/subscriptions");
                  }}>Subscriptions
                  </div>
                  <Divider/>
                  <div onClick={logout}>Logout</div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <div className={classes.footerLinks}>
        <Button className={classes.beamerButton} id="beamerButton">What's New</Button>
        <a href="https://docs.outsmart.digital/" rel="noopener noreferrer" target="_blank">
          Resources <Launch className={classes.linkIcon}/>
        </a>
      </div>
    </Drawer>
  );
}

export default Sidebar;
