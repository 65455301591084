import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "upsellSetting",
  url: `${apiUrl}/upsells/settings`,

  actions: {
    get: {
      transformResponse: res => ({ body: res.body ? res.body.settings : [] }),
    },
    update: {
      method: "PUT",
      transformResponse: (res) => ({
        body: res.body,
      }),
    },
  },
});
