import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions as productsActions } from "../../../../../redux/reducers/product.resource";
import notification from "../../notification";
import clsx from "clsx";
import { Button, Checkbox, FormControl, IconButton, OutlinedInput, Radio } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import "./products-search.scss";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImagePlaceholder from "../../ImagePlaceholder";

let timeout = false;

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: theme.spacing(1),
    width: "100%",
  },
  formControlButtons: {
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    display: "flex",
    flexFlow: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: "5px",
  },
  formControlButtonsMobile: {
    paddingTop: "5px",
    paddingBottom: "5px",
    width: "100%",
    display: "flex",
    flexFlow: "row",
    flexWrap: "nowrap",
    position: "absolute",
    bottom: "5px",
  },
  formControlButtonsFirst: {
    marginRight: "10px",
  },
  searchProductSKU: {
    color: "#808080",
    fontSize: "0.75rem",
    display: "inline-block",
    marginRight: 10,
  },
  searchProductParentSKU: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  searchProductVariation: {
    background: "#e3e3e3",
    padding: "3px 6px",
    marginLeft: 10,
    borderRadius: 3,
    fontSize: 12,
  },
}));

function ProductSearchModal({
  fetchProducts,
  products,
  dispatchSelected,
  product_target,
  upsell_products,
  removeHander,
  setIsOpened,
  isMobile,
  isProductFetching,
  canSelectMultiple = true,
  onCancel,
  calledFromDisplayFor,
}) {
  const classes                         = useStyles();
  const [checked, setChecked]           = useState([]);
  const [searchString, setSearchString] = useState("");
  const [search, setSearch]             = useState("");

  useEffect(() => {
    if ( timeout ) clearTimeout(timeout);
    if ( searchString && searchString.trim().length > 2 && searchString !== search ) {
      timeout = setTimeout(() => setSearch(searchString), 500);
    }
  }, [searchString, search]);

  useEffect(
    () => {
      const query = {};
      if ( search && search.trim !== "" ) {
        query.search = search;
        fetchProducts({}, {
          query: {
            ...query,
            limit: 999
          }
        });
      }
    }, [fetchProducts, search],
  );

  useEffect(() => {
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => setSearch(searchString), 500);
  }, [searchString]);

  const handleToggle = (value) => {
    if ( canSelectMultiple ) {
      const currentIndex = checked.findIndex(({ sku }) => sku === value.sku);
      const newChecked   = [...checked];
      if ( currentIndex === -1 ) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    } else {
      setChecked([value]);
    }
  };

  const handleChange = (e) => {
    if ( typeof e === "string" ) {
      setSearch(e);
      setSearchString(e);
    } else {
      setSearchString(e.target.value);
    }
  };

  const handleAdd = () => {
    if ( checked.length === 0 ) {
      notification({
        isOpen: true,
        type: "error",
        messageText: "Need to select products",
      });
    }
    dispatchSelected(checked);
    setChecked([]);
    setIsOpened();
  };

  const handleCancel = () => {
    setChecked([]);
    setIsOpened();

    if (typeof onCancel === "function"){
      onCancel();
    }
  };

  const isCurrentProduct = (sku) => product_target && product_target.sku === sku;

  const isUpsellProduct = (sku) => upsell_products.findIndex((item) => item.sku === sku) !== -1;

  function replaceJSX(str, find, replace) {
    let parts  = str.split(find);
    let result = [];
    for ( let i = 0; i < parts.length; i++ ) {
      result.push(parts[ i ]);
      if ( i !== parts.length - 1 ) {
        result.push(replace);
      }
    }
    return result;
  }

  const product = (data) => (
    <div
      className={clsx(
        "search-product",
        isCurrentProduct(data.sku) && "current-product",
        isUpsellProduct(data.sku) && "upsell-product",
      )}
      key={data.sku}
    >
      <div>
        {canSelectMultiple ?
          <Checkbox
            disabled={isUpsellProduct(data.sku)}
            color="primary"
            onClick={() => handleToggle(data)}
            checked={checked.findIndex(({ sku }) => sku === data.sku) !== -1}
            tabIndex={-1}
            disableRipple
          />
          : <Radio
            disabled={isUpsellProduct(data.sku)}
            color="primary"
            onClick={() => handleToggle(data)}
            checked={checked.findIndex(({ sku }) => sku === data.sku) !== -1}
            tabIndex={-1}
            disableRipple
            name="selectProduct"
          />}
      </div>
      <div className="search-product-img">
        <ImagePlaceholder imgUrl={data.image} styles={{ width: "50px" }}/>
      </div>
      <div>
        <div className="search-product-title">
          {replaceJSX(data.name, search, <span className="highlight"><b>{search}</b></span>)}
          {data.parent_sku !== "" &&
          <span className={classes.searchProductVariation}>Variation</span>}
        </div>
        <div className={classes.searchProductSKU}>SKU: {data.sku}</div>
        {data.parent_sku !== "" && <div
          className={classes.searchProductSKU}>Parent: <span
          className={classes.searchProductParentSKU}
          onClick={() => handleChange(data.parent_sku)}>{data.parent_sku}</span></div>}
      </div>
      <div className="actions-block">
        <span className="current-product-label">Upsell product</span>
        <IconButton className="remove-offer" aria-label="delete"
          onClick={() => removeHander(data.sku)}>
          <DeleteIcon style={{ cursor: "pointer" }} htmlColor="#002937"/>
        </IconButton>
      </div>
    </div>
  );

  return (
    <div className="products-search-wrapper">
      <form noValidate autoComplete="off">
        <FormControl fullWidth variant="outlined" size="small" className={classes.formControl}>
          <OutlinedInput
            placeholder={"Search Products"}
            id="outlined-adornment-amount"
            value={searchString}
            onChange={handleChange}
            // className={classes.margin}
            startAdornment={(
              <InputAdornment position="start">
                {isProductFetching ?
                  <CircularProgress size={24}/>
                  : <SearchIcon style={{ opacity: 0.5 }}/>
                }
              </InputAdornment>
            )}
          />
        </FormControl>
      </form>
      <div className="offers-list-wrapper">
        {/*{selectedProducts.map()}*/}
        {search.length > 0 && products.map(item => product(item))}
      </div>
      <FormControl variant="outlined" size="small"
        className={clsx(isMobile ? classes.formControlButtonsMobile : classes.formControlButtons, "products-actions")}>
        <Button className={classes.formControlButtonsFirst} variant="contained"
          onClick={calledFromDisplayFor ? onCancel : handleCancel}>Cancel</Button>
        <Button disabled={checked.length === 0} color="primary" variant="contained"
          onClick={handleAdd}>Add</Button>
      </FormControl>
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  products: state.reducerProducts.items || false,
  isProductFetching: state.reducerProducts.isFetching,
});

export default connect(
  mapStateToProps,
  {
    ...productsActions,
  },
)(ProductSearchModal);
