import { rootReducer as reducerMe } from "./reducers/me.resource";
import { rootReducer as reducerUsers } from "./reducers/users";
import { rootReducer as reducerUpsells } from "./reducers/upsell.resource";
import { rootReducer as reducerSettings } from "./reducers/setting.resource";
import { rootReducer as reducerSubscriptions } from "./reducers/subscriptions.resource";
import { rootReducer as reducerInvoices } from "./reducers/invoices.resource";
import { rootReducer as reducerInternalPlugin } from "./reducers/internalPlugin.resource";
import { rootReducer as reducerSubscription } from "./reducers/subscription.resource";
import { rootReducer as reducerAvailableSubscriptions } from "./reducers/availableSubscriptions.resource";
import { rootReducer as reducerUpsellSettings } from "./reducers/upsellSetting.resource";
import { rootReducer as reducerStats } from "./reducers/stat.resource";
import { rootReducer as reducerProducts } from "./reducers/product.resource";
import { rootReducer as reducerProductUpsells } from "./reducers/productUpsell.resource";
import { rootReducer as reducerBrandUpsells } from "./reducers/brandUpsell.resource";
import { rootReducer as reducerCategoryUpsells } from "./reducers/categoryUpsell.resource";
import { rootReducer as reducerBrands } from "./reducers/brand.resource";
import { rootReducer as reducerCategorys } from "./reducers/category.resource";
import { rootReducer as reducerSyncs } from "./reducers/sync.resource";
import { rootReducer as reducerEvents } from "./reducers/event.resource";
import { rootReducer as reducerRecommendations } from "./reducers/recommendations.resource";
import { rootReducer as reducerRecommendationsPopup } from "./reducers/recommendationsPopup.resource";
import { rootReducer as reducerContent } from "./reducers/content.resource";
import { rootReducer as reducerContentUpsells } from "./reducers/contentUpsell.resource";
import Setup from "./reducers/setup.reducer";
import Theme from "./reducers/theme.reducer";
import pagination from "./reducers/pagination.reducer";
import stats from "./reducers/stats.reducer";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  reducerBrands,
  reducerCategorys,
  reducerMe,
  reducerUsers,
  reducerUpsells,
  reducerSettings,
  reducerSubscriptions,
  reducerInvoices,
  reducerInternalPlugin,
  reducerSubscription,
  reducerAvailableSubscriptions,
  reducerUpsellSettings,
  reducerStats,
  reducerProducts,
  reducerSyncs,
  reducerEvents,
  reducerProductUpsells,
  reducerBrandUpsells,
  reducerCategoryUpsells,
  reducerRecommendations,
  reducerRecommendationsPopup,
  reducerContent,
  reducerContentUpsells,
  Setup,
  Theme,
  pagination,
  stats,
};
