// import React from "react";
// import Link from "@material-ui/core/Link";
// import Typography from "@material-ui/core/Typography";

export default function Footer() {
  return null;
  /* return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ height: "64px", display: "flex", alignItems: "center", justifyContent: "center" }}>
      {"Copyright © "}
      <Link color="inherit" href="/">
        OneCLickUpsells
      </Link>
      {" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  ); */
}
