import { createResource } from "redux-rest-resource";
import { apiUrl } from "../../config/config";

export const { types, actions, rootReducer } = createResource({
  name: "me",
  url: `${apiUrl}/user`,
  actions: {
    get: {
      transformResponse: res => ({ body: res.body.user }),
    },
    update: {
      method: "PUT",
      transformResponse: (res) => ({
        data: res.body.data,
        message: res.body.message,
      }),
    },
    clear: {
      isPure: true,
      reduce: state => ({ ...state, item: null }),
    },
  },
});
